import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Divider, Button, Input, Modal, Tabs, Select, Drawer, message, Checkbox, Form, Spin, Table } from "antd";
import { SearchOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CloseOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../../lib/printer/htmlPrinter";
import Close from "../../../../assets/images/close-x.svg";
import User from "../../../../assets/images/User.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import db from "../../../../database";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import userTieIcon from "../../../../assets/images/userTieIcon.svg";
import Keyboard from "react-simple-keyboard";

const PointOfSuperMarketModals = (props) => {
  const {
    cart,
    tillData,
    setCart,
    openSalesRep,
    setOpenSalesRep,
    selectedProductInCart,
    isStockModal,
    setIsStockModal,
    addProduct,
    handleCartTotalClick,
    tempCart,
    setTempCart,
    deleteCart,
    deleteProduct,
    // open,
    // setOpen,
    keyboardType,
    setKeyboardType,
    // salesRepRef,
    salesRepValue,
    setSalesRepValue,
  } = props;
  const [salesRepData, setSalesRepData] = useState([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState({});
  const [itemWeights, setItemWeights] = useState(() => {
    const initialWeights = {};
    cart?.items.forEach((item) => {
      initialWeights[item.lineId] = item.availableQty;
    });
    return initialWeights;
  });
  const inputRefs = useRef([]);
  const salesRepRef = useRef(null);
  const keyboardRef = useRef(null);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const [open, setOpen] = useState(false);
  const [layoutName, setLayoutName] = useState("default");
  const [focusedInput, setFocusedInput] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);
  const showVirtualKeyboard = posConfig.showKeyboard === "Y" ? true : false;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};

  useEffect(async () => {
    await db.salesRep.toArray((res) => {
      setSalesRepData(res);
    });
  }, []);

  useEffect(() => {
    if (openSalesRep?.flag && salesRepRef.current) {
      setTimeout(() => {
        salesRepRef.current.focus();
      }, 10);
    }
  }, [openSalesRep?.flag]);

  useEffect(() => {
    if (isStockModal) {
      const timeout = setTimeout(() => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      }, 0);
      return () => clearTimeout(timeout);
    }
  }, [isStockModal]);

  useEffect(() => {
    const initialWeights = {};
    cart?.items.forEach((item) => {
      initialWeights[item.lineId] = item.weight <= item?.availableQty ? item.weight : item?.availableQty || 0; // Store as string to allow flexible input
    });
    setItemWeights(initialWeights);
  }, [cart]);

  const renderThumbHorizontalScroll = ({ style, ...props }) => {
    const thumbStyle = {
      width: "0px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const fetchSalesRepData = async (searchTerm) => {
    console.log("searchTerm", searchTerm);
    const results = await db.salesRep.filter((res) => res.name.toLowerCase().includes(searchTerm.toLowerCase()) || res.code.includes(searchTerm)).toArray();
    console.log("Fetched Sales Reps:", results);
    return results;
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSalesRepValue(value);
    const data = await fetchSalesRepData(value);
    setSalesRepData(data);
  };

  const handleQtyChange = (value, recordIndex, productId, isDecimal) => {
    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    if (!isDecimal) {
      sanitizedValue = sanitizedValue.replaceAll(".", "");
    }

    // Handle multiple dots if decimal is allowed
    if (isDecimal) {
      const parts = sanitizedValue.split(".");
      if (parts.length > 2) {
        // Keep only the first dot and merge the rest
        sanitizedValue = parts[0] + "." + parts.slice(1).join("");
      }

      // Restrict precision to 3 digits after the decimal
      if (parts.length === 2 && parts[1].length > 3) {
        sanitizedValue = parts[0] + "." + parts[1].substring(0, 3);
      }
    }

    // Handle leading zeros for non-decimal values
    let displayValue = sanitizedValue;
    if (sanitizedValue.startsWith("0") && sanitizedValue.length > 1 && !sanitizedValue.includes(".")) {
      displayValue = sanitizedValue.substring(1);
    }

    // Allow raw decimal input without immediate formatting
    displayValue = displayValue === "" ? "0" : displayValue;

    const updatedTempCart = { ...tempCart };
    let tempData = { ...itemWeights };
    tempData[productId] = displayValue;
    setItemWeights(tempData);

    const parsedWeight = parseFloat(displayValue);
    if (!isNaN(parsedWeight)) {
      updatedTempCart[productId] = parsedWeight;
      setTempCart(updatedTempCart);
    }
  };

  const handleKeyDown = (e, isDecimal, index) => {
    let totalItems = (parseFloat(localStorage.getItem("cartObj"))?.items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items || []).filter(
      (item) => item.weight !== 0
    );
    if (!isDecimal && e.key === ".") {
      e.preventDefault();
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (index < totalItems.length - 1) {
        // Move to the next input
        inputRefs.current[index + 1]?.focus();
      } else {
        // If it's the last input, perform confirm action
        handleSubmit();
        setIsStockModal(false);
      }
    }
  };

  const handleKeyboardInput = (key) => {
    // Handle layout changes
    if (key === "{shift}") {
      setLayoutName(layoutName === "shift" ? "default" : "shift");
      return;
    }
    if (key === "{number}") {
      setLayoutName("number");
      return;
    }
    if (key === "{numbers}") {
      setLayoutName("numbers");
      return;
    }
    if (key === "{small}") {
      setLayoutName("shift");
      return;
    }
    if (key === "{caps}") {
      setLayoutName("default");
      return;
    }

    if (focusedInput !== null && currentRecord) {
      let currentValue = itemWeights[focusedInput] || "";
      currentValue = currentValue.toString();

      if (key === "{bksp}") {
        currentValue = currentValue.slice(0, -1);
        if (currentValue === "") {
          currentValue = "0";
        }
      } else if (key === "{enter}") {
        setOpen(false);
      } else if (key === ".") {
        if (currentRecord.isDecimal && !currentValue.includes(".")) {
          currentValue += ".";
        } else if (!currentRecord.isDecimal) {
          currentValue = currentValue.replace(".", "");
        }
      } else if (!/^\d*\.?\d*$/.test(currentValue + key)) {
        return; // Prevent invalid characters
      } else {
        if (currentValue === "0") {
          currentValue = key;
        } else {
          currentValue += key;
        }

        // Precision restriction for decimals
        if (currentRecord.isDecimal) {
          const parts = currentValue.split(".");
          if (parts.length === 2 && parts[1].length > 3) {
            currentValue = parts[0] + "." + parts[1].substring(0, 3);
          }
        }
      }

      // Update item weights and cart
      const updatedItemWeights = { ...itemWeights };
      updatedItemWeights[focusedInput] = currentValue;
      setItemWeights(updatedItemWeights);
      console.log("itemWeights", itemWeights);

      const updatedTempCart = { ...tempCart };
      updatedTempCart[focusedInput] = parseFloat(currentValue) || 0;
      setTempCart(updatedTempCart);
    }
  };

  const handleSubmit = async () => {
    let showPaymentsFlag = false;
    let deleteItems = [];
    await Promise.all(
      tempCart.items.map((item) => {
        if (itemWeights[item.lineId] > 0) {
          showPaymentsFlag = true;
          if (item.weight !== itemWeights[item.lineId]) {
            item.modified = true;
            return addProduct(item, itemWeights[item.lineId], true);
          }
        } else {
          deleteItems.push(item);
        }
      })
    );
    if (tempCart.items.length !== deleteItems.length) {
      await Promise.all(
        deleteItems.map((item) => {
          deleteProduct(item);
        })
      );
    } else {
      deleteCart();
    }
    if (showPaymentsFlag) {
      handleCartTotalClick();
    }
  };

  return (
    <Fragment>
      {/* sales rep modal start */}
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Select a Sales Representative</p>
            <img
              src={Close}
              onClick={() => {
                setSelectedSalesRep({});
                setOpenSalesRep({ flag: false, level: "" });
                setOpen(false);
                setLayoutName("default");
                setSalesRepValue("");
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={openSalesRep?.flag}
        className="filter"
        bodyStyle={{ paddingBottom: 0, paddingTop: 0 }}
        onClose={() => {
          setSelectedSalesRep({});
          setOpenSalesRep({ flag: false, level: "" });
          setOpen(false);
        }}
        height="90vh"
      >
        <Row style={{ paddingLeft: "0.7vw", fontSize: "1vw", paddingBottom: "2vh" }}>
          <Col span={9}>
            <Input
              type="text"
              ref={salesRepRef}
              value={salesRepValue}
              className="productSearch"
              placeholder={`Search`}
              // id="giftCardSearchInput"
              id="sm-product-search"
              onChange={handleSearchChange}
              onFocus={() => {
                setKeyboardType({
                  searchCus: false,
                  parkedBill: false,
                  salesHistory: false,
                  salesHistoryCus: false,
                  product: false,
                  giftCardSearch: false,
                  salesRep: true,
                  addCustomer: false,
                });
                setOpen(true);
              }}
              onPressEnter={(e) => e.preventDefault()}
              style={{
                width: "35vw",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "1vw",
                color: "#0F0718",
                borderColor: "rgba(0, 0, 0, 0.25)",
              }}
              prefix={
                <img
                  src={SearchIcon}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                    paddingLeft: "0.2vw",
                  }}
                />
              }
            />
          </Col>
        </Row>
        <Scrollbars style={{ height: "55vh", width: "100%" }} renderThumbHorizontal={renderThumbHorizontalScroll}>
          <Row gutter={[16, 16]}>
            {salesRepData?.map((res) => (
              <Col
                span={6}
                style={{
                  background: selectedSalesRep.code === res.code ? "#cae5bd" : "#fff",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 1rem",
                  padding: "0rem 0.5rem",
                }}
                onClick={() => {
                  setSelectedSalesRep(res);
                }}
              >
                <img src={User} alt="" style={{ marginRight: "10px", height: "2.5rem", margin: "0.7rem 0 " }} />
                <span style={{ marginLeft: "10px", margin: "0.7rem 0px 0.7rem 10px", fontSize: "1vw" }}>
                  <p style={{ margin: 0, fontWeight: 500, fontSize: "1em", color: "#0F0718" }}>{res.name}</p>
                  <span style={{ fontSize: "0.8em", fontWeight: 500, color: "#0F0718" }}>{res.code}</span>
                </span>
              </Col>
            ))}
          </Row>
        </Scrollbars>
        <Row>
          <Col span={24} style={{ textAlign: "right", paddingTop: "1rem" }}>
            <Button
              style={{ backgroundColor: "#0F0718", color: "#fff", height: "7vh", width: "15vw", borderRadius: "7px" }}
              disabled={!selectedSalesRep?.code}
              onClick={() => {
                let cartObj = cart;
                if (openSalesRep.level === "order") {
                  cartObj.salesRepId = selectedSalesRep.s_salesrep_id;
                  cartObj.salesRepName = selectedSalesRep.name;

                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                } else if (openSalesRep.level === "line") {
                  cartObj?.items?.map((lineItem) => {
                    if (lineItem.productId === selectedProductInCart.productId && lineItem.lineId === selectedProductInCart.lineId) {
                      lineItem.salesRepId = selectedSalesRep.s_salesrep_id;
                      lineItem.salesRepName = selectedSalesRep.name;
                    }
                  });
                  setCart(cartObj);
                  localStorage.setItem("cartObj", JSON.stringify(cartObj));
                  setSelectedSalesRep({});
                  setOpenSalesRep({ flag: false, level: "" });
                }
              }}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </Drawer>
      {/* sales rep modal end */}

      {/* Stock Confirmation Start */}
      <Drawer
        closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
            <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Stock Confirmation</p>
            <img
              src={Close}
              onClick={() => {
                setIsStockModal(false);
                setOpen(false);
                setLayoutName("default");
              }}
              style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
            />
          </div>
        }
        closeIcon={null}
        visible={isStockModal}
        className="filter"
        bodyStyle={{ padding: "0 4vw" }}
        onClose={() => {
          setIsStockModal(false);
          setOpen(false);
          setLayoutName("default");
        }}
        height="90vh"
      >
        <Table
          className="sm-cart-table-pos-stock"
          style={{
            width: "100%",
            overflowY: "auto",
            border: "none",
            borderRadius: "7px",
            padding: "0px 7px",
            height: "67vh",
            background: themeJSON.productTableBGColor || "#fff",
          }}
          pagination={false}
          dataSource={(parseFloat(localStorage.getItem("cartObj"))?.items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items || []).filter(
            (item) => item.availableQty < item.weight
          )}
          sticky
          id="sm-cart-table"
          columns={[
            {
              title: "Item Name",
              dataIndex: "name",
              key: "name",
              width: "32%",
              render: (text, record, index) => {
                return (
                  <>
                    <div onClick={() => {}} id="sm-product-name" className="tableRow">
                      {record.name}
                    </div>
                    <div className="offerName">
                      {record.availableQty >= record.weight ? (
                        <p style={{ margin: 0, color: "#009000" }}>Available</p>
                      ) : record.availableQty > 0 && record.weight > 0 ? (
                        <p style={{ margin: 0, color: "#FF5E0E" }}>Only {record.availableQty} Available</p>
                      ) : (
                        <p style={{ margin: 0, color: "red" }}>Out Of Stock</p>
                      )}
                    </div>
                  </>
                );
              },
            },
            {
              title: "UOM",
              dataIndex: "uom_name",
              key: "uom_name",
              width: "12%",
              render: (text, record, index) => {
                return <div style={{ textAlign: "left" }}>{text}</div>;
              },
              align: "center",
            },
            {
              title: "Order Qty",
              dataIndex: "weight",
              key: "weight",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return <div style={{ textAlign: "right" }}>{parseFloat(text).toFixed(2)}</div>;
              },
            },
            {
              title: "Available Qty",
              dataIndex: "availableQty",
              key: "availableQty",
              width: "15%",
              align: "right",
              render: (text, record) => {
                return (
                  <div style={{ textAlign: "right", color: text > 0 ? "#0F0718" : "red" }}>
                    {text?.toFixed(2).length < 8 ? parseFloat(text)?.toFixed(2) : `${text?.toString().substring(0, 8)}`}
                  </div>
                );
              },
            },
            {
              title: "Update Order Qty",
              dataIndex: "availableQty",
              key: "availableQty",
              width: "22%",
              align: "center",
              paddingLeft: "3vw",
              textAlign: "right",
              render: (text, record, index) => {
                return (
                  <Input
                    key={record.lineId}
                    style={{ width: "10vw", textAlign: "right" }}
                    ref={(el) => (inputRefs.current[index] = el)}
                    value={itemWeights[record.lineId]}
                    onChange={(e) => handleQtyChange(e.target.value, index, record.lineId, record.isDecimal)}
                    onPressEnter={(e) => e.preventDefault()}
                    onKeyDown={(e) => handleKeyDown(e, record.isDecimal, index)}
                    onFocus={() => {
                      setFocusedInput(record.lineId);
                      setOpen(true);
                      setCurrentRecord(record);
                    }}
                  />
                );
              },
            },
          ]}
        />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#2F3856",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
            }}
            onClick={() => {
              setIsStockModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#2F3856",
              color: "#fff",
              height: "7vh",
              width: "15vw",
              borderRadius: "7px",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
            onClick={() => {
              handleSubmit();
              setIsStockModal(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Drawer>
      {/* Stock Confirmation End */}

      {open && showVirtualKeyboard && (
        <Drawer placement="bottom" closable={false} bodyStyle={{ paddingBottom: 0 }} height="47vh" visible={open && showVirtualKeyboard} mask={false}>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1vh" }}>
            <img
              src={Close}
              onClick={() => {
                setOpen(false);
              }}
              style={{
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
          <div style={{ padding: "0 4vw 0" }}>
            <Keyboard
              keyboardRef={(r) => {
                keyboardRef.current = r;
                // if (keyboardType.salesRep) {
                //   salesRepRef.current = r;
                // }
              }}
              onChange={(val) => {
                if (keyboardType.salesRep) {
                  setSalesRepValue(val);
                  handleSearchChange({ target: { value: val } });
                  if (salesRepRef && salesRepRef.current) {
                    setTimeout(() => {
                      salesRepRef.current.focus();
                    }, 10);
                  }
                }
              }}
              layoutName={layoutName}
              display={{
                "{bksp}": "⌫",
                "{numbers}": "123",
                "{shift}": "⇧",
                "{enter}": "Enter",
                "{space}": "Space",
                "{number}": "123",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} @ {space} . {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , _ ! ` {bksp}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , _ ! ` {bksp}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} @ {space} . {enter}"],
              }}
              onKeyPress={handleKeyboardInput}
            />
          </div>
        </Drawer>
      )}
    </Fragment>
  );
};

export default PointOfSuperMarketModals;
