import { LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/sync.json";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { v4 as uuidv4 } from "uuid";
// import { socket } from "../../socket";
import moment from "moment";

let cleanToken;
let syncId;
const serverUrl = process.env.REACT_APP_serverUrl;
const CWCoreServicesUrl = process.env.REACT_APP_genericUrl;
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let tillId;
const authHeaders = getOAuthHeaders();
if (authHeaders) {
  cleanToken = authHeaders.access_token;
}

// if (process.env.REACT_APP_Websocket === "false") {
//   socket.close();
// }
export const processSync = async (data, setGlobalStore, type) => {
  const tillData = data ? data : JSON.parse(localStorage.getItem("tillData"));
  let syncType = "Incremental Sync";
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  if (lastUpdatedTime === undefined || lastUpdatedTime === null) {
    syncType = "Full Sync";
    await deletePreviousData();
  }
  try {
    let startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    tillId = JSON.parse(localStorage.getItem("tillValue")).cwr_till_id;
    syncId = uuidv4().replace(/-/g, "").toUpperCase();
    await db.dataSyncSummary.add({ syncId: syncId, syncType: syncType, syncStartTime: startTime });
    setGlobalStore({ percent: "15%" });
    setGlobalStore({ productCategoryPercentage: "38" });
    await syncAllProductCategories(tillData);
    setGlobalStore({ percent: "30%" });
    setGlobalStore({ productCategoryPercentage: "55" });
    setGlobalStore({ productPercentage: "15" });
    await syncProductCategories(tillData);
    setGlobalStore({ percent: "45%" });
    setGlobalStore({ productCategoryPercentage: "70" });
    setGlobalStore({ productPercentage: "30" });
    await syncPosSaleTypes(tillData);
    setGlobalStore({ percent: "53%" });
    setGlobalStore({ productCategoryPercentage: "100" });
    setGlobalStore({ productPercentage: "47" });
    await syncProductsUom(tillData, authHeaders, tillId, syncId);
    setGlobalStore({ percent: "64%" });
    setGlobalStore({ productPercentage: "56" });
    setGlobalStore({ percent: "75%" });
    setGlobalStore({ productPercentage: "69" });
    await syncProducts(tillData);
    setGlobalStore({ percent: "84%" });
    setGlobalStore({ promotionPercentage: "25" });
    setGlobalStore({ productPercentage: "77" });
    await syncPricingRules(tillData);
    await syncOfflineDta(tillData);
    setGlobalStore({ percent: "90%" });
    setGlobalStore({ promotionPercentage: "55" });
    setGlobalStore({ productPercentage: "85" });
    await syncPosConfigData(tillData);
    setGlobalStore({ percent: "95%" });
    setGlobalStore({ productPercentage: "100" });
    setGlobalStore({ promotionPercentage: "79" });
    await syncLoyalityData(tillData);
    await syncGiftCardData(tillData);
    await getApprovals();
    await syncDocTypes();
    syncRestaurantTables();
    await syncRFIDData(tillData);
    await syncRFIDScanConfig(tillData);
    await syncSalesRepData(tillData);
    await filterBrandAndCategoty();
    setGlobalStore({ percent: "99%" });
    setGlobalStore({ promotionPercentage: "100" });
    await db.dataSyncSummary
      .where("syncId")
      .equals(syncId)
      .modify((event) => {
        event.syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      });
    localStorage.setItem("lastUpdatedTime", startTime);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deletePreviousData = (tillData) => {
  return new Promise(async (deletionSuccess, deletionFailure) => {
    let lastSyncTime = localStorage.getItem("lastUpdatdeTime");
    try {
      await db.productCategories.clear();
      await db.AllProductCategories.clear();
      //   await db.restaurantTables.clear();
      // if (lastSyncTime !== null) {
      await db.products.clear();
      // }
      await db.pricingRules.clear();
      await db.posSaletypes.clear();
      await db.productUom.clear();
      await db.rfidData.clear();
      await db.loyalityData.clear();
      await db.POSWorkFlowRules.clear();
      await db.approvers.clear();
      await db.productBrands.clear();
      await db.giftCardData.clear();
      await db.docTypesData.clear();
      // await db.tableData.clear();
      // await db.fbOrderData.clear();
      // await db.sectionTables.clear();
      deletionSuccess();
    } catch (error) {
      deletionFailure(error);
    }
  });
};

export const syncTillData = async (userData, authHeaders) => {
  try {
    const resData = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `query {
          tillData(user: "${userData}") {
            posScanConfigs {
              cwrPosScanConfigId
              scanTrxType
              dataType
              barcodeLength
              startsWith
              endsWith
              customFormula
              calculateTax
              formula
            }
            loyaltyApply {
              cwrLoyaltyLevelId
              name
              applicableFor
              prodCategories {
                mProductCategoryId
                include
              }
            }
            tillAccess {
              cwrTillaccessId
              csClientId
              csUserId
              userAccessController
              cashManagement
              productSync
              salesHistory
              parkedBills
              giftCard
              manualDiscount
              layAway
              dataSyncMonitor
              unlinkTill
              salesReport
              holdBill
              couponCode
              tillAccessMeta {
                key
                value
              }
              cwrTill {
                cwrTillID
                searchKey
                till
                description
                nextAssignedNumber
                prefix
                suffix
                loyaltyProgram
                accessController
                posType
                manageCash
                showopeningamount
                showsalesamount
                showexpectedamount
                showdifferenceamount
                shiftclose
                shiftopen
                eReceipt
                printPreview
                cashin
                cashout
                layAway
                payNow
                calculateTax
                cashToKeep
                hardwareController {
                  imageUrl
                  printReceipt
                  weighingScale
                  payment
                  printBarcode
                }
                pHWController {
                  imageUrl
                  printReceipt
                  weighingScale
                  payment
                  printBarcode
                }
                printTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                  xmlcode
                  xmlcode2
                  obController
                }
                tillCloseTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                  xmlcode
                  xmlcode2
                  obController
                }
                kotPrintTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                  xmlcode
                  xmlcode2
                }
                cancelKotPrintTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                  xmlcode
                  xmlcode2
                }
                salesReportTemplate {
                  cwrPrinttemplateId
                  name
                  htmlcode
                  htmlcode2
                  xmlcode
                  xmlcode2
                  obController
                }
              }
              csBunit {
                csBunitId
                name
                value
                cwrSCustomerId
                cwrCsDoctypeId
                cwrPcatalogueId
                isTaxIncluded
                allowNegativeStock
                cwrSpricelistId
                pCatalogueSaleType {
                  cwrPcatalogueSaletypeId
                  isPromoApplicable
                  cwrSaletypeId
                }
                currencies {
                  csCurrencyId
                  currSymbol
                  isoCode
                  prcPrecision
                  stdPrecision
                  cstgPrecision
                  symbolRightSide
                  denominations {
                    value
                    seqNo
                  }
                  conversions {
                    csCurrencyIDTo
                    currencyFromRate
                    currencyToRate
                    validfromDate
                    validtoDate
                    isoCode
                  }
                }
                b2cCustomer {
                  cwrCustomerId
                  code
                  name
                  email
                  mobileNo
                  pincode
                  retlLoyaltyBalance
                  b2cRegisteredstoreId
                  iscredit
                  balancePoints
                  loyaltyLevel {
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                  }
                  sCustomer {
                    sCustomerID
                    customerCategory {
                      sCustomerCateforyId
                      value
                      name
                      description
                    }
                  }
                }
                paymentMethodList {
                  cWRPaymentMethodID
                  sequenceNo
                  finPaymentmethodId
                  finFinancialAccountId
                  finDayCloseAccountId
                  name
                  integratedPayment
                  isloyalty
                  paymentProvider
                  iscredit
                  isGiftCard
                  isDefault
                  csCurrencyId
                  isoCode
                  isactive
                  salesType {
                    cwrSaletypeId
                    name
                  }
                }
                mWarehouse {
                  mWarehouseID
                  name
                }
                customerAddress {
                  sCustomerAddressID
                  line1
                  line2
                  line3
                  fulladdress
                  phone
                  city
                  postalcode
                  csCountry {
                    csCountryID
                    name
                  }
                  csRegion {
                    csRegionID
                    name
                  }
                }
                locations {
                  csBunitLocationId
                  fulladdress
                  phone
                  contactPerson
                }
                salesRep {
                  code
                  name
                  salesRepresentId
                }
              }
            }
            returnReasons {
              name
              value
              returnReasonId
            }
            status
            message
          }
        }`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${authHeaders.access_token}`,
      },
    });

    let tillData = resData?.data?.data?.tillData;

    // Check for successful status
    if (tillData?.status === "200") {
      tillData.time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      localStorage.setItem("tillData", JSON.stringify(tillData));
      return true;
    } else {
      throw new Error(tillData?.message || "Failed to fetch Till data");
    }
  } catch (error) {
    console.error("Error syncing till data:", error.message);
    return false;
  }
};

export const syncOfflineDta = async (tillData) => {
  try {
    const events = await db.tillEvents
      .where("tillStatus")
      .equals("closed")
      .filter((event) => event.isSynced === 0)
      .toArray();

    if (events.length > 0) {
      for (let i = 0; i < events.length; i += 1) {
        const event = events[i];
        const tillSession = JSON.parse(localStorage.getItem("tillSession"));
        const tillSessionId = tillSession?.tillSessionId;

        await db.tillEvents
          .where("tillSessionId")
          .equals(event?.tillSessionId)
          .modify((event) => {
            if (tillSessionId !== event?.tillSessionId) {
              event.tillStatus = "closed";
              event.isSynced = 1;
            }
          });

        const paramsInput = {
          query: `mutation{
            upsertTill(tillInfo:${events[i].tillInfo})
            {    
              status
              message
              cwrTillID
              tillCash{
                cwrTillCashId
                date
                finPaymentmethodId
                opening
                sales
                netsales
                cashin
                cashout
                retainAmt
                closing
                returns
                iscash
                notes
                isclose
                storeDailyOpsTillid
                cashEvents{
                  cwrCashEventsID
                  amount
                  expected
                  diff
                  transactionCount
                  type
                  description
                  cashEventDetails{
                    cwrCashEventdetailsID
                    count
                    amount
                    denomination
                  }
                }
              }
            }
          }`,
        };

        try {
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: paramsInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          });

          const result = response.data.data?.upsertTill;
          const status = result?.status;
          if (status === "200") {
            localStorage.removeItem("storeDailyOpsTillid");

            let tillData = event.tillRegistration;
            await Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
                  upsertPOSActivity(tillActivity: [
                    {
                      csBunitId: "${tillData[0]?.tillAccess[0]?.csBunit?.csBunitId}"
                      csUserId: "${tillData[0]?.tillAccess[0]?.csUser?.csUserId}"
                      tillRegistrationId: "${tillData[0]?.tillHistory[0]?.cwrTillRegHistoryId}"
                      type: "LO"
                      time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                    }
                  ]) {
                    status
                    message
                  }
              }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${authHeaders.access_token}`,
              },
            });
          }
        } catch (error) {
          console.error("Error syncing offline data:", error.message);
        }
      }
    }
  } catch (error) {
    console.error("Error fetching events from DB:", error.message);
  }
};

export const syncProductsUom = async (tillData, authHeaders, tillID, syncId) => {
  const lastUpdatedTime = null;
  await db.productUom.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (uomSyncSuccess, uomSyncFailure) => {
    try {
      const paramsInput = {
        query: `query {
          getUom(lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null},uniqueId: "${syncId}", tillId:"${tillId}", storeOpsTillId: null,timeZone:"${timeZone}") {
            csUomId
            costingprecision
            description
            ediCode
            name
            stdprecision
            symbol
            decimal
          }
        }`,
      };

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      const { getUom } = response.data.data;

      if (response.status === 200 && getUom.length > 0) {
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getUom.map(async (item) => {
              item.syncId = syncId;
              const existingUom = await db.productUom.where("csUomId").equals(item.csUomId).toArray();
              if (existingUom.length > 0) {
                recordsData = recordsData + 1;
                await db.productUom.update(item.csUomId, item);
              } else {
                recordsData = recordsData + 1;
                await db.productUom.add(item);
              }
            })
          );
          let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, UOM: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = { ...event.type, UOM: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.UOM } };
              });
          }
        } else {
          getUom.map((uom) => {
            uom.syncId = syncId;
          });
          const lastId = await db.productUom.bulkPut(getUom);
          let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, UOM: { syncStartTime: syncStartTime, syncEndTime, records: getUom.length } };
              event.records = (event.records ? event.records : 0) + getUom.length;
            });
          console.log("POS Uom: ", "Synced");
          uomSyncSuccess(lastId);
        }
        uomSyncSuccess();
      } else {
        console.log("POS Uom: ", getUom);
        uomSyncSuccess();
      }
    } catch (error) {
      console.log("POS Uom: Sync Failed", error);
      uomSyncFailure(error);
    }
  });
};

export const syncPosSaleTypes = async (tillData) => {
  const lastUpdatedTime = null;
  await db.posSaletypes.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
          getPosSaletype(tillId:"${tillId}",catalogueId:"${tillData.tillAccess.csBunit.cwrPcatalogueId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}"){
            cwrPcatalogueSaletypeId
            cSClientID
            cSBunitID
            created
            createdby
            updated
            updatedby
            isactive
            isPromoApplicable
            cwrPCatalogueId
            cwrSaletype{
              cwrSaletypeId
              value
              name
              isdefault
              colourCode
              enableLayaway
            }
            customer{
                  b2cCustomerId
                  code
                  name
                  email
                  mobileNo
                  pincode
                  retlLoyaltyBalance
                  b2cRegisteredstoreId
                  iscredit
                  balancePoints
                  sPriceListId
                  priceListName
                  cwrCustomerId
              }
          }
        }`,
      };

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const { getPosSaletype } = response.data.data;

      if (response.status === 200 && getPosSaletype.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getPosSaletype.map(async (item) => {
              item.syncId = syncId;
              await db.posSaletypes
                .where("cwrSaletypeId")
                .equals(item.cwrSaletypeId)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    recordsData = recordsData + 1;
                    await db.posSaletypes.update(item.cwrSaletypeId, item);
                  } else {
                    recordsData = recordsData + 1;
                    await db.posSaletypes.add(item);
                  }
                });
            })
          );

          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, posSaletypes: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  posSaletypes: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.posSaletypes },
                };
              });
          }
        } else {
          getPosSaletype.map((item) => {
            item.syncId = syncId;
          });
          const lastId = await db.posSaletypes.bulkPut(getPosSaletype);
          console.log("POS Sale Types: ", "Synced");
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, posSaletypes: { syncStartTime: syncStartTime, syncEndTime, records: getPosSaletype.length } };
              event.records = (event.records ? event.records : 0) + getPosSaletype.length;
            });
          posSaleTypeSyncSuccess(lastId);
        }
      } else {
        posSaleTypeSyncSuccess();
      }
    } catch (error) {
      console.log("POS Sale Types: ", "Sync Failed");
      posSaleTypeSyncFailure(error);
    }
  });
};

export const syncAllProductCategories = (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  return new Promise(async (posSaleTypeSyncSuccess, posSaleTypeSyncFailure) => {
    try {
      const paramsInput = {
        query: `query{
          getProductCategory(tillId:"${tillId}", lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}, timeZone:"${timeZone}"){
              mProductCategoryId
              name
              value
              description
              imageurl
              parentCategory{
                  mProductCategoryId
                  name
                  value
                  description
                  imageurl
                  parentCategory{
                      mProductCategoryId
                      name
                      value
                      description
                      imageurl
                      parentCategory{
                          mProductCategoryId
                          name
                          value
                          description
                          imageurl
                          parentCategory{
                              mProductCategoryId
                              name
                              value
                              description
                              imageurl
                          }
                      }
                  }
              }
          }
        }`,
      };

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      if (response.status === 200) {
        const { getProductCategory } = response.data.data;

        if (getProductCategory && getProductCategory.length > 0) {
          console.log("POS Sale Types: ", "Synced");

          const isArray = [];
          const finalArray = [];
          const seenNames = new Set();

          function getParentCategoryValue(obj) {
            if (!obj.parentCategory) {
              const id = obj.mProductCategoryId;
              if (!seenNames.has(id)) {
                isArray.push(obj);
                seenNames.add(id);
              }
              return id;
            }
            obj.id = obj.parentCategory.mProductCategoryId;
            finalArray.push({
              mProductCategoryId: obj.mProductCategoryId,
              name: obj.name,
              value: obj.value,
              description: obj.description,
              imageurl: obj.imageurl,
              id: obj.id,
              syncId: syncId,
            });

            return getParentCategoryValue(obj.parentCategory);
          }

          for (const obj of getProductCategory) {
            getParentCategoryValue(obj);
          }

          const seenId = new Set();
          isArray.forEach((item) => {
            const printArray = finalArray.reduce((result, current) => {
              const category = current.id;
              if (!result[category]) {
                result[category] = [];
              }
              const id = current.mProductCategoryId;
              if (!seenId.has(id)) {
                seenId.add(id);
                result[category].push(current);
              }
              return result;
            }, {});
            item.printArray = printArray;
          });

          const lastId = await db.AllProductCategories.bulkPut(isArray);
          posSaleTypeSyncSuccess(lastId);
        } else {
          posSaleTypeSyncSuccess();
        }
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      posSaleTypeSyncFailure(error);
    }
  });
};

export const syncProductCategories = (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  localStorage.setItem("sync1", true);
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  return new Promise(async (productCategorySyncSuccess, productCategorySyncFailure) => {
    try {
      const paramsInput = {
        query: `query {
            productCategory(bunit:"${tillData.tillAccess.csBunit.csBunitId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },uniqueId: "${syncId}",tillId:"${tillId}", storeOpsTillId: null, timeZone:"${timeZone}") {
            mProductCategoryId
            value
            name
            description
            imageurl
            }
          }`,
      };

      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const { productCategory } = response.data.data;

      if (response.status === 200 && productCategory.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");

          await Promise.all(
            productCategory.map(async (item) => {
              await db.productCategories
                .where("mProductCategoryId")
                .equals(item.mProductCategoryId)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    item.syncId = syncId;
                    await db.productCategories.update(item.mProductCategoryId, item);
                    recordsData = recordsData + 1;
                  } else {
                    item.syncId = syncId;
                    await db.productCategories.add(item);
                    recordsData = recordsData + 1;
                  }
                });
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, categories: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  categories: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.categories },
                };
              });
          }
          await syncProductBrand(tillData);
        } else {
          productCategory.map((item) => {
            item.syncId = syncId;
          });
          const lastId = await db.productCategories.bulkPut(productCategory);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, categories: { syncStartTime: syncStartTime, syncEndTime, records: productCategory.length } };
              event.records = (event.records ? event.records : 0) + productCategory.length;
            });
          console.log("Product Category: ", "Synced");
          productCategorySyncSuccess(lastId);
        }
        // Ensure syncProductBrand is called after successful product category sync
        await syncProductBrand(tillData);
        productCategorySyncSuccess();
      } else {
        productCategorySyncSuccess();
        await syncProductBrand(tillData);
      }
    } catch (error) {
      console.log("Product Category: ", "Sync Failed");
      productCategorySyncFailure(error);
    }
  });
};

export const syncProductBrand = async (tillData) => {
  localStorage.setItem("sync1", true);
  // await db.productBrands.clear();
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  const userData = JSON.parse(localStorage.getItem("userData"));
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  if (!userData || !userData.cs_client_id) {
    console.error("User data or client ID is missing.");
    return Promise.reject("User data or client ID is missing.");
  }

  const paramsInput = {
    query: `query {
      getBrand(clientId: "${userData.cs_client_id}", lastSyncTime: ${
      lastUpdatedTime ? `"${lastUpdatedTime}"` : null
    },uniqueId: "${syncId}", tillId:"${tillId}", storeOpsTillId: null, timeZone:"${timeZone}") {
        brandId
        name
        value
      }
    }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const { getBrand } = response.data.data;
      if (response.status === 200 && getBrand && getBrand.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
          await Promise.all(
            getBrand.map(async (item) => {
              const existingBrand = await db.productBrands.where("brandId").equals(item.brandId).first();
              // const existingBrand = await db.productBrands.where("brandId").equals(item.brandId).toArray();
              if (existingBrand) {
                // console.log(existingBrand, "-------->existing");
                recordsData = recordsData + 1;
                item.syncId = syncId;
                await db.productBrands.update(item.brandId, item);
                // console.log(`Brand with ID ${item.brandId} updated.`);
              } else {
                try {
                  item.syncId = syncId;
                  recordsData = recordsData + 1;
                  await db.productBrands.add(item);
                  // console.log(`New brand with ID ${item.brandId} added.`);
                } catch (error) {
                  console.error(`Failed to add new brand with ID ${item.brandId}: `, error);
                }
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, brands: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  brands: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.brands },
                };
              });
          }
        } else {
          getBrand.map((item) => {
            item.syncId = syncId;
          });
          await db.productBrands.bulkAdd(getBrand);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, brands: { syncStartTime: syncStartTime, syncEndTime, records: getBrand.length } };
              event.records = (event.records ? event.records : 0) + getBrand.length;
            });
        }
        console.log("Product Brand: ", "Synced");
        resolve(); // Resolve the Promise after successful execution
      } else {
        console.log("Product Brand: ", "No new brands to sync");
        resolve(); // Resolve the Promise if there are no brands to sync
      }
    } catch (error) {
      console.error("Product Brand Sync Failed: ", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncPricingRules = async (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  // await db.pricingRules.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  return new Promise(async (pricingRulesSyncSuccess) => {
    try {
      const paramsInput = {
        query: `query {
            getPricingRules(catalogueId: "${tillData.tillAccess.csBunit.cwrPcatalogueId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        },tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}"){
                mPricingrulesId
                csClientId
                csBunitId
                created
                createdBy
                updated
                updatedBy
                upc
                type
                discountType
                name
                printedName
                description
                startDate
                endDate
                nextRule
                percentageDiscount
                amountDiscount
                minimumQty
                maximumQty
                xQty
                yQty
                billAmount
                maxBillAmount
                cwrSaletypeId
                fixedUnitPrice
                timeSpecific
                starttime
                endtime
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
                status
                iscoupon
                nextRule
                priority
                giftVoucherType
                issueGiftVoucher
                manualDiscount
                excludeProductCategories
                excludeBusinessUnits
                excludeProducts
                excludeB2CCustomers
                excludeBrands
                excludeCustomers
                excludeCustomerCategory
                excludeB2CSegment
                enableApproval
                mPricingXProducts {
                    mPricingXProductId
                    line
                    mProductId
                    mBatchId
                    isFree
                    quantity
                }
                mPricingYProducts {
                    mPricingYProductId
                    line
                    mProductId
                }
                mPricingCcategories {
                    mPricingCcategoryId
                    line
                    sCustomerCategoryId
                }
                mPricingPcategories {
                    mPricingPcategoryId
                    line
                    mProductCategoryId
                }
                mPricingBrands {
                    mPricingBrandId
                    line
                    mBrandId
                }
                mPricingBUnits {
                    mPricingBUnitId
                    line
                    mBunitPricingId
                }
                mPricingQuantities {
                    mPricingQuantityId
                    line
                    quantity
                    discountValue
                }
                mPricingB2CCustomers {
                    mPricingB2cCustomerId
                    lineno
                    b2cCustomerId
                }
                mPricingB2CCustomerSegments {
                    mPricingB2CSegmentId
                    lineNo
                    cwrB2CCustomerSegmentId
                }
                mPricingExpiryDiscount {
                  rangeFrom
                  rangeTo
                  discountPercentage
                  mProductId
              }
              mPricingApprovals {
                  mPricingApprovalId
                  type
                  min
                  max
                  userId
                  roleId
                  userName
                  pin
                  role
              }
            }
        }`,
      };
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getPricingRules } = response.data.data;
      if (response.status === 200 && getPricingRules.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            getPricingRules.map(async (item) => {
              const existingRule = await db.pricingRules.where("mPricingrulesId").equals(item.mPricingrulesId).first();

              if (existingRule) {
                await db.pricingRules.delete(existingRule.id);
                // Update existing pricing rule
                item.syncId = syncId;
                await db.pricingRules.add(item);
                recordsData = recordsData + 1;
              } else {
                item.syncId = syncId;
                // Add new pricing rule
                recordsData = recordsData + 1;
                await db.pricingRules.add(item);
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, pricingRules: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  pricingRules: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.pricingRules },
                };
              });
          }
        } else {
          await db.pricingRules.clear();
          getPricingRules.map((item) => {
            item.syncId = syncId;
          });
          const lastId = await db.pricingRules.bulkPut(getPricingRules);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, pricingRules: { syncStartTime: syncStartTime, syncEndTime, records: getPricingRules.length } };
              event.records = (event.records ? event.records : 0) + getPricingRules.length;
            });
          console.log("Product Pricing Rules: ", " Synced");
          pricingRulesSyncSuccess(lastId);
        }
        pricingRulesSyncSuccess();
      } else {
        console.log("Product Pricing Rules: ", getPricingRules);
        pricingRulesSyncSuccess();
      }
    } catch (error) {
      console.log("Product Pricing Rules: ", " Sync Failed");
      pricingRulesSyncSuccess();
    }
  });
};

export const syncProducts = async (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  let recordsCount = 0;
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  try {
    let from = 0;
    let limit = 5000;
    let productsSynced = false;
    while (!productsSynced) {
      const paramsInput = {
        query: `query {
                getProducts1(bUnit:"${tillData.tillAccess.csBunit.csBunitId}", lastSyncTime: ${
          lastUpdatedTime ? `"${lastUpdatedTime}"` : null
        }, from:${from} limit:${limit},tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null,timeZone:"${timeZone}") {
                  mProductId
                  csClientId
                  csBunitId
                  created
                  createdby
                  updated
                  isactive
                  updatedby
                  sunitprice
                  slistprice
                  onhandQty
                  description
                  isVirtual
                  isBestSeller
                  cTaxId
                  taxRate
                  taxName
                  isPromoApplicable
                  isavailable
                  ismonday
                  istuesday
                  iswednesday
                  isthursday
                  isfriday
                  issaturday
                  issunday
                  colorcode
                  cwrMenuTimeslot {
                    cwrMenuTimeslotId
                    name
                    startTime
                    endTime
                  }
                  mProduct {
                    value
                    name
                    name2
                    type
                    csTaxcategoryId
                    mProductCategoryId
                    csUomId
                    uomName
                    upc
                    batchedProduct
                    isManualQty
                    isDecimal
                    imageurl
                    shortDescription
                    hsncode
                    returnable
                    returnDays
                    description
                    batchedForSale
                    batchedForStock
                    multiPrice
                    addNewLine
                    shelfLife
                    isBag
                    productSegment
                    taxCategory {
                      csTaxcategoryID
                      name
                      overRideTax
                      overRideCondition
                      contraTaxCategoryId
                      contraTaxCategory
                      {
                        contraTaxCategoryName
                        contraTaxId
                        contraTaxName
                        contraRate
                      }
                    }
                    mBatch {
                      mBatchId
                      batchno
                      upc
                      price
                      listPrice
                      startdate
                      enddate
                      life
                    }
                    productGroup {
                      mProductGroupId
                      name
                      value
                      description
                    }
                    productAddons {
                      mProductAddOnId
                      name
                      price
                      mAddonGroup {
                        mAddonGroupId
                        name
                        maxqty
                        minqty
                        type
                      }
                      mAddonProduct {
                        mProductId
                        name
                      }
                    }

                    productAttributes {
                      mProductAttributeId
                      value
                      mAttributeGroup {
                        mAttributeGroupId
                        name
                      }
                      mAttribute {
                        mAttributeId
                        name
                      }
                    }

                    productManufacturer {
                      mProductManufacturerId
                      name
                      value
                      description
                    }
                    productBrand {
                      brandId
                      name
                      value
                      description
                    }
                    priceLists {
                        pricelist
                       pricestd
                        sPricelistId
                       sPriceList
                    }
                  customAttributes{
                    name
                      fileds {
                          name
                          type
                          values
                          defaultValue
                          mandatory
                      }
                    }
                  }
                }
              }`,
      };
      from += 5000;
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const { getProducts1 } = response.data.data;
      const products = getProducts1;
      const productsLength = products.length;
      if (productsLength > 0) {
        recordsCount = recordsCount + productsLength;
        const productsData = [];
        const brands = new Set();
        const categories = new Set();
        for (let i = 0; i < productsLength; i += 1) {
          const upcIndex = [];
          const batchIndex = [];
          if (products[i].mProduct.mBatch !== null) {
            for (let j = 0; j < products[i].mProduct.mBatch.length; j += 1) {
              batchIndex.push(products[i].mProduct.mBatch[j].batchno);
              upcIndex.push(products[i].mProduct.mBatch[j].upc);
            }
          }
          if (products[i].mProduct.upc !== null) {
            upcIndex.push(products[i].mProduct.upc);
          }
          let uomData = [];
          await db.productUom
            .where("csUomId")
            .equals(products[i].mProduct.csUomId)
            .toArray()
            .then((uom) => {
              if (uom.length > 0) {
                uomData = uom;
              }
            });
          const productDataObj = {
            mProductId: products[i].mProductId || "",
            csClientId: products[i].csClientId || "",
            csBunitId: products[i].csBunitId || "",
            created: products[i].created || "",
            createdby: products[i].createdby || "",
            updated: products[i].updated || "",
            isactive: products[i].isactive || "",
            updatedby: products[i].updatedby || "",
            sunitprice: products[i].sunitprice || 0,
            addNewLine: products[i].mProduct.addNewLine || "N",
            slistprice: products[i].slistprice || 0,
            onhandQty: products[i].onhandQty || 0,
            description: products[i].description || "",
            shortDescription: products[i].mProduct.shortDescription || "",
            isVirtual: products[i].isVirtual || "",
            isBestSeller: products[i].isBestSeller || "",
            cTaxId: products[i].cTaxId || "",
            taxRate: products[i].taxRate || 0,
            isPromoApplicable: products[i].isPromoApplicable || "",
            value: products[i].mProduct.value || "",
            name: products[i].mProduct.name || "",
            name2: products[i].mProduct.name2 || "",
            brandId: products[i].mProduct.productBrand.brandId || "",
            csTaxcategoryId: products[i].mProduct.csTaxcategoryId || "",
            mProductCategoryId: products[i].mProduct.mProductCategoryId || "",
            csUomId: products[i].mProduct.csUomId || "",
            uomName: products[i].mProduct.uomName || "",
            uomData: uomData || "",
            upc: products[i].mProduct.upc || "",
            batchedProduct: products[i].mProduct.batchedProduct || "",
            batchedForSale: products[i].mProduct.batchedForSale || "",
            batchedForStock: products[i].mProduct.batchedForStock || "",
            isManualQty: products[i].mProduct.isManualQty || "",
            isDecimal: products[i].mProduct.isDecimal || "",
            imageurl: products[i].mProduct.imageurl || "",
            productSegment: products[i].mProduct?.productSegment ? products[i].mProduct.productSegment : "",
            taxCategory: products[i].mProduct.taxCategory.name || "",
            taxName: products[i].taxName || "",
            taxFlag: "N",
            mBatch: products[i].mProduct.mBatch || "",
            hsncode: products[i].mProduct.hsncode || "",
            batchIndex: batchIndex || "",
            upcIndex: upcIndex || "",
            customAttributes: products[i].mProduct?.customAttributes?.fileds || [],
            type: products[i].mProduct.type || "",
            priceList: products[i].mProduct.priceLists,
            productManufacturerName: products[i].mProduct.productManufacturer.name || "",
            productManufacturerId: products[i].mProduct.productManufacturer.mProductManufacturerId || "",
            productBrandName: products[i].mProduct.productBrand.name || "",
            productBrandId: products[i].mProduct.productBrand.brandId || "",
            iscustomizable: products[i].mProduct?.iscustomizable || "",
            mProductGroupId: products[i]?.mProduct?.productGroup?.mProductGroupId || "",
            productCategoryName: products[i]?.mProduct?.productGroup?.name || "",
            productAddons: products[i]?.mProduct?.productAddons || [],
            multiPrice: products[i].mProduct.multiPrice || "",
            shelfLife: products[i].mProduct.shelfLife || "",
            overRideTax: products[i].mProduct.taxCategory.overRideTax || "",
            overRideCondition: parseFloat(products[i].mProduct.taxCategory.overRideCondition) || "",
            contraRate: parseFloat(products[i].mProduct.taxCategory.contraTaxCategory?.contraRate) || "",
            contraTaxId: products[i].mProduct.taxCategory.contraTaxCategory?.contraTaxId || "",
            syncId: syncId,
          };
          brands.add(productDataObj.brandId);
          categories.add(productDataObj.mProductCategoryId);
          productsData.push(productDataObj);
        }
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime !== null) {
          let recordsData = 0;
          await Promise.all(
            productsData.map(async (item) => {
              await db.products
                .where("value")
                .equals(item.value)
                .toArray()
                .then(async (response) => {
                  if (response.length > 0) {
                    item.syncId = syncId;
                    recordsData = recordsData + 1;
                    await db.products.where("value").equals(item.value).modify(item);
                  } else {
                    item.syncId = syncId;
                    await db.products.add(item);
                    recordsData = recordsData + 1;
                  }
                });
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = {
                ...event.type,
                products: { syncStartTime: syncStartTime, syncEndTime, records: event?.products?.records ? event?.products?.records + recordsData : recordsData },
              };
              event.records = (event?.records ? event?.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  products: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type?.products },
                };
              });
          }
        } else {
          const lastId = await db.products.bulkPut(productsData);
          console.log("Synced products count:", lastId);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, products: { syncStartTime: syncStartTime, syncEndTime, records: recordsCount } };
              event.records = (event?.records ? event?.records : 0) + products.length;
            });
        }
      } else {
        console.log("Products: Synced");
        productsSynced = true;
      }
    }
    // Code to be executed after syncProducts completion
  } catch (error) {
    console.log("Products: Sync Failed");
    throw error;
  }
};

export const syncPosConfigData = (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
            getPOSConfig(tillId: "${tillId}", name: null, lastSyncTime: ${
            lastUpdatedTime ? `"${lastUpdatedTime}"` : null
          }, uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
              cwrPosConfigId
              name
              posType
              application
              configJson
              PricingRule
              ExpiryDiscount
              activateExpiryDiscount
              registrationTypes
              feedback
            }
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      });

      const posConfig = response.data.data.getPOSConfig;
      const feedbackData = posConfig[0]?.feedback ? JSON.parse(posConfig[0]?.feedback) : [];

      if (posConfig.length > 0) {
        const posConfigData = JSON.parse(posConfig[0].configJson);
        const registrationTypes = JSON.parse(posConfig[0].registrationTypes) || [];
        localStorage.setItem("expiryDiscount", posConfig[0].activateExpiryDiscount);
        localStorage.setItem("posConfig", JSON.stringify(posConfigData));
        localStorage.setItem("registrationTypes", JSON.stringify(registrationTypes));
        localStorage.setItem("feedbackJson", JSON.stringify(feedbackData));
        console.log("POS Config: ", "Synced");
      } else {
        console.log("POS Config: No new config to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("POS Config: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncRFIDData = async (tillData) => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  const value = { tag_value: "", lastSyncTime: `${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}` };
  const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
  const newStringRequest = JSON.stringify(newStringifiedFields);
  const todaySalesInput = {
    query: `mutation { executeAPIBuilder(apiBuilderId:"64d323e6291b3b33ff234dd9", params: ${newStringRequest}) }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: todaySalesInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const executeAPIBuilder = response.data.data.executeAPIBuilder;
      const newResponse = JSON.parse(executeAPIBuilder) || [];

      if (newResponse.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            newResponse.map(async (item) => {
              const existingTag = await db.rfidData.where("tag_value").equals(item.tag_value).toArray();
              if (existingTag.length > 0) {
                recordsData = recordsData + 1;
                await db.rfidData.update(item.tag_value, item);
              } else {
                recordsData = recordsData + 1;
                await db.rfidData.add(item);
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, rfidData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  rfidData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.rfidData },
                };
              });
          }
        } else {
          await db.rfidData.bulkPut(newResponse);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, rfidData: { syncStartTime: syncStartTime, syncEndTime, records: newResponse.length } };
              event.records = (event.records ? event.records : 0) + newResponse.length;
            });
        }
        console.log("RFID Data: ", "Synced");
      } else {
        console.log("RFID Data: No new data to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("RFID Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncRFIDScanConfig = async (tillData) => {
  const value = {};
  const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
  const newStringRequest = JSON.stringify(newStringifiedFields);
  const todaySalesInput = {
    query: `mutation { executeAPIBuilder(apiBuilderId:"670520c68a26395542b489ee", params: ${newStringRequest}) }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: todaySalesInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });
      const executeAPIBuilder = response?.data?.data?.executeAPIBuilder;
      localStorage.setItem("posScanConfigurations", executeAPIBuilder || []);
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("RFID Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncSalesRepData = async () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const value = { cs_bunit_id: tillData.tillAccess.csBunit.csBunitId };
  const newStringifiedFields = JSON.stringify(value).replace(/\\"/g, '\\"');
  const newStringRequest = JSON.stringify(newStringifiedFields);
  const salesRepData = {
    query: `mutation { executeAPIBuilder(apiBuilderId:"669fa7b4befce80600118eef", params: ${newStringRequest})}`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: CWCoreServicesUrl,
        method: "POST",
        data: salesRepData,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const executeAPIBuilder = response.data.data.executeAPIBuilder;
      const newResponse = JSON.parse(executeAPIBuilder) || [];
      await db.salesRep.bulkPut(newResponse);
      console.log("Sales Rep Data: Sync Successful", newResponse);
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("Sales Rep Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncLoyalityData = async () => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  const loyalityQuery = {
    query: `query {
      getLoyaltyconfigurations(lastSyncTime: ${
        lastUpdatedTime ? `"${lastUpdatedTime}"` : null
      }, tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
        loyaltylevelId
        name
        accumulationRate
        redemptionRate
        applicableFor
        isDefault
        levelId
        validityPeriod
        minPoints
        maxPoints
        sequence
        upgradeThreshold
        downgrade_threshold
        benefits
        LoyaltyAccumulation {
          loyaltyAccumulationId
          name
          description
          loyaltyLevelId
          pointsPerUnit
          mProductId
          product
          mProductCategoryId
          productCategory
          minPurchase
          startDate
          endDate
          loyaltyEvent
        }
        loyaltyRedemption {
          loyaltyRedemptionId
          ruleName
          description
          loyaltyLevelId
          pointsRequired
          redemptionValue
          minPurchase
          max_redemption
          startDate
          endDate
        }
        loyaltyEventCalenders {
          loyaltyEventCalenderId
          name
          description
          eventType
          startDate
          endDate
        }
      }
    }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: loyalityQuery,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const loyaltyConfigurations = response.data.data.getLoyaltyconfigurations;

      if (loyaltyConfigurations.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            loyaltyConfigurations.map(async (item) => {
              const existingLoyalty = await db.loyalityData.where("loyaltylevelId").equals(item.loyaltylevelId).toArray();
              if (existingLoyalty.length > 0) {
                item.syncId = syncId;
                recordsData = recordsData + 1;
                await db.loyalityData.update(item.loyaltylevelId, item);
              } else {
                item.syncId = syncId;
                recordsData = recordsData + 1;
                await db.loyalityData.add(item);
              }
            })
          );
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, loyalityData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData } };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  loyalityData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.loyalityData },
                };
              });
          }
        } else {
          loyaltyConfigurations.map((item) => {
            item.syncId = syncId;
          });
          await db.loyalityData.bulkPut(loyaltyConfigurations);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, loyalityData: { syncStartTime: syncStartTime, syncEndTime, records: loyaltyConfigurations.length } };
              event.records = (event.records ? event.records : 0) + loyaltyConfigurations.length;
            });
        }
        console.log("Loyalty Data: ", "Synced");
      } else {
        console.log("Loyalty Data: No new data to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("Loyalty Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const syncGiftCardData = async () => {
  const lastUpdatedTime = localStorage.getItem("lastUpdatedTime");
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  const giftQuery = {
    query: `query {
      getGiftCardType(lastSyncTime: ${lastUpdatedTime ? `"${lastUpdatedTime}"` : null}, tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null, timeZone:"${timeZone}") {
        cwrGiftcardTypeId
        name
        mProductId
        product
        type
        singleUse
        validity
        balanceLimit
        topupLimit
        prefix
        lengthCardNo
        pinRequired
        initialAmount
        giftCardGroup
        promotionEligible
        variableAmount
        printTemplate {
          cwrPrinttemplateId
          name
          htmlcode
          htmlcode2
          xmlcode
          xmlcode2
          obController
        }
      }
    }`,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios({
        url: serverUrl,
        method: "POST",
        data: giftQuery,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${cleanToken}`,
        },
      });

      const giftCardTypes = response.data.data.getGiftCardType;

      if (giftCardTypes.length > 0) {
        let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (lastUpdatedTime) {
          let recordsData = 0;
          await Promise.all(
            giftCardTypes.map(async (item) => {
              const existingGiftCard = await db.giftCardData.where("cwrGiftcardTypeId").equals(item.cwrGiftcardTypeId).toArray();
              if (existingGiftCard.length > 0) {
                recordsData = recordsData + 1;
                item.syncId = syncId;
                await db.giftCardData.update(item.cwrGiftcardTypeId, item);
              } else {
                item.syncId = syncId;
                await db.giftCardData.add(item);
                recordsData = recordsData + 1;
              }
            })
          );

          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = {
                ...event.type,
                giftCardData: { syncStartTime: syncStartTime, syncEndTime, records: recordsData },
              };
              event.records = (event.records ? event.records : 0) + recordsData;
            });
          if (latestIncrementalSync?.length > 0) {
            db.dataSyncSummary
              .where("syncId")
              .equals(latestIncrementalSync[0].syncId)
              .modify((event) => {
                event.type = {
                  ...event.type,
                  giftCardData: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: recordsData, ...event.type.giftCardData },
                };
              });
          }
        } else {
          giftCardTypes.map((item) => {
            item.syncId = syncId;
          });
          await db.giftCardData.bulkPut(giftCardTypes);
          db.dataSyncSummary
            .where("syncId")
            .equals(syncId)
            .modify((event) => {
              event.type = { ...event.type, giftCardData: { syncStartTime: syncStartTime, syncEndTime, records: giftCardTypes.length } };
              event.records = (event.records ? event.records : 0) + giftCardTypes.length;
            });
        }
        console.log("Gift Card Data: ", "Synced");
      } else {
        console.log("Gift Card Data: No new data to sync");
      }
      resolve(); // Resolve the Promise after successful execution
    } catch (error) {
      console.log("Gift Card Data: Sync Failed", error);
      reject(error); // Reject the Promise if an error occurs
    }
  });
};

export const getApprovals = async () => {
  tillId = JSON.parse(localStorage.getItem("tillValue")).cwr_till_id;
  let syncId = uuidv4().replace(/-/g, "").toUpperCase();
  await db.POSWorkFlowRules.clear();
  await db.approvers.clear();
  let syncStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  const latestIncrementalSync = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");
  try {
    const paramsInput = {
      query: `query {
          getPOSWorkflowRules(tillId:"${tillId}",uniqueId: "${syncId}", storeOpsTillId: null,){
              rules{
              cwrRulesId
             ruleName
             ruleCondition
             rulePriority
              cwrEventId
             eventId
             eventName
             apiType
             apiSource
             apiEndPoint
             triggerType
             }
             approvers {
                 name
                 pin
                 csUserId
                 csBunitId
                 bunitName
                 role
             }
            }
        }`,
    };
    const response = await Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    });
    console.log(response, "------->appr");
    if (
      response?.data?.data?.getPOSWorkflowRules?.rules !== null &&
      response?.data?.data?.getPOSWorkflowRules?.rules !== undefined &&
      response?.data?.data?.getPOSWorkflowRules?.rules !== ""
    ) {
      await db.POSWorkFlowRules.bulkPut(response?.data?.data?.getPOSWorkflowRules?.rules);
    }

    const approvers = response?.data?.data?.getPOSWorkflowRules?.approvers || [];

    if (approvers.length > 0) {
      // Generate unique ids for each approver
      const approversWithIds = approvers.map((approver) => ({
        ...approver,
        id: uuidv4(), // Generate unique id
        syncId: syncId,
      }));

      // Add approvers with unique ids to IndexedDB
      await db.approvers.bulkAdd(approversWithIds);
      let syncEndTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      db.dataSyncSummary
        .where("syncId")
        .equals(syncId)
        .modify((event) => {
          event.type = { ...event.type, approvers: { syncStartTime: syncStartTime, syncEndTime, records: approversWithIds.length } };
          event.records = (event.records ? event.records : 0) + approversWithIds.length;
        });
      if (latestIncrementalSync?.length > 0) {
        db.dataSyncSummary
          .where("syncId")
          .equals(latestIncrementalSync[0].syncId)
          .modify((event) => {
            event.type = {
              ...event.type,
              approvers: { incremntalSyncStart: syncStartTime, incremntalSyncEnd: syncEndTime, incremntalSyncRecods: approversWithIds.length, ...event.type.approvers },
            };
          });
      }
      // const { approver } = response.data.data;
      // await db.POSWorkFlowRules.bulkAdd(response?.data?.data?.getPOSWorkflowRules?.approvers);
    }
    // const addOrUpdateRecord = async (tableName, record) => {
    //   await db[tableName].put(record);
    // };
    // for (const approver of response?.data?.data?.getPOSWorkflowRules?.approvers) {
    //   // Check if the record already exists in the store
    //   const existingRecord = await db.approvers.get(approver.csUserId);
    //   if (existingRecord) {
    //     // Record already exists, update it
    //     await addOrUpdateRecord("approvers", approver);
    //   } else {
    //     // Record doesn't exist, add it
    //     await db.approvers.add(approver);
    //   }
    // }
  } catch (error) {
    console.log("Approvals: Sync Failed");
    throw error;
  }
};

const filterBrandAndCategoty = async () => {
  let products = await db.products.toArray();
  let brandDetails = await db.productBrands.toArray();
  let categoryDetails = await db.productCategories.toArray();
  await db.productBrands.clear();
  await db.productCategories.clear();
  const getUniqueBrandsAndCategories = (products) => {
    const brands = new Set();
    const categories = new Set();

    products.forEach((product) => {
      brands.add(product.brandId);
      categories.add(product.mProductCategoryId);
    });

    return {
      brands: Array.from(brands),
      categories: Array.from(categories),
    };
  };
  const { brands, categories } = getUniqueBrandsAndCategories(products);

  const filterBrandsAndCategories = (brands, categories, brandDetails, categoryDetails) => {
    const filteredBrands = brands.reduce((acc, brandId) => {
      const brandDetail = brandDetails.find((brand) => brand.brandId === brandId);
      if (brandDetail) {
        acc.push(brandDetail);
      }
      return acc;
    }, []);

    const filteredCategories = categories.reduce((acc, categoryId) => {
      const categoryDetail = categoryDetails.find((category) => category.mProductCategoryId === categoryId);
      if (categoryDetail) {
        acc.push(categoryDetail);
      }
      return acc;
    }, []);

    return {
      filteredBrands,
      filteredCategories,
    };
  };

  const { filteredBrands, filteredCategories } = filterBrandsAndCategories(brands, categories, brandDetails, categoryDetails);
  await db.productBrands.bulkPut(filteredBrands);
  await db.productCategories.bulkPut(filteredCategories);
};

const syncRestaurantTables = async () => {
  try {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const tillId = tillData?.tillAccess?.cwrTill?.cwrTillID;

    if (!tillId) throw new Error("Till ID is not available");

    // Fetch restaurant tables data
    const getRestaurantTables = await fetchRestaurantTables(tillId);
    if (!getRestaurantTables || getRestaurantTables?.length === 0) {
      // throw new Error("No restaurant tables found");
    }

    // Process restaurant tables
    const { tempData, sectionData } = processRestaurantTables(getRestaurantTables);

    // Store section data
    await db.sectionTables.clear();
    await db.sectionTables.bulkAdd(sectionData);

    // Fetch occupied tables data
    const occupiedData = await fetchOccupiedTablesData();

    // Update table data with occupied information
    updateTableDataWithOccupiedInfo(tempData, occupiedData);

    // Store updated table data
    await db.tableData.clear();
    await db.tableData.bulkPut(tempData);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

// Function to fetch restaurant tables from the server
const fetchRestaurantTables = async (tillId) => {
  const query = `query {
    getRestaurantTables(tillId: "${tillId}") {
      cwrFbsectionId
      sectionName
      posTables {
        cwrFbTableId
        cSBunitID
        cSClientID
        created
        createdby
        csWindowId
        isactive
        updated
        updatedby
        name
        capacity
        cwrFbFloorId
      }
    }
  }`;

  const response = await Axios.post(
    serverUrl,
    { query },
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${authHeaders.access_token}`,
      },
    }
  );

  return response?.data?.data?.getRestaurantTables;
};

// Function to process restaurant tables data
const processRestaurantTables = (getRestaurantTables) => {
  let tempData = [];
  let sectionData = [{ value: "all", name: "All" }];

  getRestaurantTables.forEach((section, index) => {
    section.posTables.forEach((table) => {
      table.title = table.name;
      table.merge = true;
      table.cwrFbsectionId = section.cwrFbsectionId;
      table.sectionName = section.sectionName;
      table.table = table.name;
      table.color = "#a7c957";
      table.statusType = "OPN";
      table.originalIndex = index;
      tempData.push(table);
    });
    sectionData.push({ value: section.cwrFbsectionId, name: section.sectionName });
  });

  return { tempData, sectionData };
};

// Function to fetch occupied tables data
const fetchOccupiedTablesData = async () => {
  const query = `query {
    getTableStatus(tableId: null) {
      cSBunitID
      cSClientID
      isactive
      fbTableId
      fbSectionId
      guestName
      guestType
      referredBy
      status
      salesRepId
      guests
      fbtableStatusId
    }
  }`;

  const response = await Axios.post(
    serverUrl,
    { query },
    {
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${authHeaders.access_token}`,
      },
    }
  );

  return response?.data?.data?.getTableStatus || [];
};

// Function to update table data with occupied information
const updateTableDataWithOccupiedInfo = (tempData, occupiedData) => {
  occupiedData.forEach((occupied) => {
    const table = tempData.find((t) => t.cwrFbTableId === occupied.fbTableId);
    if (table) {
      table.color = getColorByStatus(occupied.status);
      table.noOfPersons = occupied.guests;
      table.guestName = occupied.guestName;
      table.guestType = occupied.guestType;
      table.referredBy = occupied.referredBy;
      table.fbtableStatusId = occupied.fbtableStatusId;
      table.statusType = occupied.status;
      table.waiter = occupied.salesRepId;
    }
  });
};

// Helper function to get color by table status
const getColorByStatus = (status) => {
  switch (status) {
    case "OCU":
      return "#bc4749"; // Occupied
    case "RES":
      return "#f2e8cf"; // Reserved
    case "OPN":
    default:
      return "#a7c957"; // Open
  }
};

export const syncDocTypes = async () => {
  await db.docTypesData.clear();
  const docTypeQuery = {
    query: `mutation { executeAPIBuilder(apiBuilderId:"66f407348e3f1f618d89f888", params: "{}")} `,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${authHeaders.access_token}`,
  };
  try {
    const docTypesResponse = await Axios.post(CWCoreServicesUrl, docTypeQuery, { headers: headers }, { async: true }, { crossDomain: true });
    if (docTypesResponse.status === 200) {
      let docTypesData = JSON.parse(docTypesResponse?.data?.data?.executeAPIBuilder);
      await db.docTypesData.bulkAdd(docTypesData);
      console.log("doc type data: ", " Synced");
    }
  } catch (error) {
    console.error("Error fetching doc types:", error);
    if (error.response) {
      console.log("Error data:", error.response.data);
      console.log("Status:", error.response.status);
      console.log("Headers:", error.response.headers);
    } else if (error.request) {
      console.log("Request error:", error.request);
    } else {
      console.log("Error message:", error.message);
    }
  }
};
