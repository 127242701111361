import React from "react";
import { useState } from "react";
import { Modal, Popover, Row, Col, Card, Progress, Table, Divider, Drawer, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs, Checkbox, Space } from "antd";
import {
  CalendarOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseOutlined,
  LoadingOutlined,
  CloseSquareFilled,
  RightOutlined,
  DownOutlined,
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import BarCodeComponentForRetail from "../../../../lib/BarCodeComponentForRetail";
import TextArea from "antd/lib/input/TextArea";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import sideMenu from "../../../../assets/images/side-menu.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import LockIcon from "../../../../assets/images/lock-icon.svg";
import userIcon from "../../../../assets/images/userId.svg";
import ScanIcon from "../../../../assets/images/scanIcon.svg";
import Online from "../../../../assets/images/green.svg";
import Ofline from "../../../../assets/images/red.svg";
import Wifi from "../../../../assets/images/wifi_Icon.svg";
import LogoutIcon from "../../../../assets/images/logout_Icon.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import FilterIcon from "../../../../assets/images/filterNewICon.svg";
import DefaultProductImage from "../../../../assets/images/no-image.svg";
import CustomerSearch from "../../../../assets/images/c-serach.svg";
import CustomerIcon from "../../../../assets/images/customer1.svg";
import View from "../../../../assets/images/view.svg";
import AddUser from "../../../../assets/images/userAdd.svg";
import styles from "../../../../constants/styles.json";
import SyncIcon from "../../../../assets/images/syncIcon.svg";
import discount from "../../../../assets/images/discount.svg";
import Back from "../../../../assets/images/backIcon.svg";
import CancelArrow from "../../../../assets/images/cancelArrow.svg";
import NotesImage from "../../../../assets/images/notes.svg";
import userAddIcon from "../../../../assets/images/userAddIcon.svg";
import Offers from "../../../../assets/images/promoTag.svg";
import Cancel from "../../../../assets/images/cancel-new.svg";

import { useHistory } from "react-router-dom";

const QuotationComponent = (props) => {
  const {
    userData,
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    onChangeInput,
    closeProductPanel,
    confirmDiscardCart,
    showAddNewCustomerFields,
    decreaseProductQty,
    deleteCart,
    giftCardData,
    setGiftCardData,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    loading,
    setLoading,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    setFilterdDate,
    orderType,
    parkBill,
    parkedList,
    productCategories,
    productBrands,
    handleBrandCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    productSearchInput,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    selectProduct,
    selectedProductInCart,
    selectedProductQty,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setDisplayUAECustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setQtyNumberFlag,
    filtersFlag,
    setFiltersFlag,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    setSalesHistoryType,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    prevProductsListRef,
    prevHistoryRef,
    setManualDiscountModalVisible,
    setDisplayOfferProductSelection,
    offerProductsList,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    upsertPOSLog,
    removeCutomer,
    onChangeTotalQuantity,
    filterDrawer,
    setFilterDrawer,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    // parked Data
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    setFilterdParkedList,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    selectLayAwayOrder,
    // Cash Management
    setAddCashFlag,
    setCouponInput,
    // Order History
    setOrderHistoryInput,
    searchOrderHistory,
    changeOrderHistorySearchType,
    orderHistoryInput,
    orderHistorySearchInputRef,
    orderHistoryDetails,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
    paymentModal,
    tillDataPaymentMethods,
    requestPayment,
    setCouponModalVisible,
    paymentCardRef,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    setAmount,
    setNumb,
    onChangeAmount,
    paymentModalInputRef,
    processOrder,
    paymentModalLoyalityMessages,
    handleAmount,
    handleCashPayment,
    customerFlag,
    setCustomerFlag,
    setOverPayedAmount,
    overPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    selectedProductCategory,
    setCashAddInFlag,
    addCashFlag,
    isPrintModeXML,
    setSelectedKeys,
    setDisplayReturnOrderSearch,
    displayReturnOrderSearch,
    getSearchedItem,
    isProductsVisible,
    setIsProductsVisible,
    pickProduct,
    // gigt card
    validateGiftCard,
    giftCardFlag,
    setGiftCardFlag,
    giftCardItems,
    giftCardType,
    setGiftCardType,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    setIsGiftCardFlag,
    // keyboard
    setKeyboardType,
    keyboardType,
    layout,
    setLayout,
    inputName,
    setInputName,
    keyboardParkbill,
    keyboardRef,
    keyboardProduct,
    handleKeyboardInput,
    handleKeyPress,
    searchHistoryInput,
    setSearchhistoryInput,
    showEditOldCustomerFields,
    setEditFlag,
    openPaymentModalByCustomer,
    setSelectedProductInCart,
    setParkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    quantityInputRef,
    setIsInputFocused,
    isInputFocused,
    getCategoryProducts,
    salesHistoryType,
    setStartRowData,
    startRowData,
    cartObj,
    documentSequence,
    setDocumnetSequence,
    notesValue,
    setNotesValue,
    selectedProductForNotes,
    setSelectedProductForNotes,
    couponModalVisible,
    displayCustomerSearch,
    displayAddNewCustomer,
    displayEditOldCustomer,
    isGiftCardFlag,
    manualDiscountModalVisible,
    loyalityOtpModalVisible,
    productsData,
    orderDelay,
    setOrderDelay,
    addToBagFlag,
    setAddToBagFlag,
    setStockList,
    stockList,
    displayUAECustomer,
    setDisplayUAECustomer,
    showProductionOrder,
    setShowProductionOrder,
    formattedDateTime,
    setIsLocked,
    setEnableProduction,
    removeProductModal,
    verifyStock,
    deleteProductModal,
    setTillLink,
    setIsQuotation,
    setOpen,
    productSearchInputRef,
  } = props;
  const { Option } = Select;
  const history = useHistory();
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};

  return (
    <>
      <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
        <BarCodeComponentForRetail onScan={onBarcodeInput} setProductSearchInput={setProductSearchInput} productSearchInput={productSearchInput} />
        <Row
          style={{
            padding: "0.8% 0",
            height: "9vh",
            alignItems: "center",
            fontSize: "1vw",
          }}
        >
          <Col
            onClick={() => {
              setTillLink(true);
            }}
            style={{ cursor: "pointer" }}
            span={1}
          >
            <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
          </Col>
          {/* 7 */}
          <Col span={3}>
            <img
              src={POSLogo}
              onClick={() => {
                setIsQuotation(false);
                deleteCart();
              }}
              style={{ height: "2.5vw", paddingLeft: "0.4vw", cursor: "pointer" }}
            />
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "flex-end", height: "100%", paddingleft: "10px" }}>
            <span style={{ fontSize: "1em", fontWeight: "500", fontFamily: "Inter", color: "#0F0718" }}>Quotation No: SQ/10010</span>
          </Col>
          <Col span={4} />
          <Col
            span={7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "1em",
                color: "#929098",
                marginLeft: "5vw",
              }}
            >
              {" "}
              {formattedDateTime}
            </span>
          </Col>
          <Col span={1} />

          <Col
            span={5}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "1%",
            }}
          >
            <img
              onClick={() => {
                setIsLocked(true);
              }}
              src={LockIcon}
              style={{ height: "3vh", cursor: "pointer" }}
            />
            &nbsp; &nbsp;
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                padding: "0.5vw",
                border: "1px solid #3C424B",
                borderRadius: "50%",
              }}
            >
              <img src={userIcon} style={{ height: "2.7vh" }} />
            </div>
            <div
              style={{
                marginLeft: "1vw",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "1em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                {/* {userData.user} */}
                CW Admin
              </span>
              <span
                style={{
                  color: "#0F0718",
                  fontSize: "0.8em",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
              </span>
            </div>
            <div style={{ marginLeft: "3%" }}>
              <img
                src={Online}
                style={{
                  display: !isOnline && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
              <img
                src={Ofline}
                style={{
                  display: isOnline && "none",
                  height: "2.5vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp; &nbsp;
              <img
                src={Wifi}
                style={{
                  height: "4vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp; &nbsp;
              <img
                src={LogoutIcon}
                style={{
                  height: "4vh",
                  cursor: "pointer",
                  paddingBottom: "0.5vh",
                }}
              />{" "}
              &nbsp;
            </div>
          </Col>
        </Row>

        <div style={{ display: "flex", height: "91vh" }}>
          {/* Left side UI */}
          <div style={{ width: "63.9%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "0 0 0 1.7%",
              }}
            >
              <div style={{ width: "93%" }}>
                {/* <Input
                  placeholder="Search for products by code/name"
                  id="sm-product-search"
                  value={productSearchInput}
                  onChange={(e) => {
                    e.target.value === "" ? clearProductSearchResults() : onChangeInput(e);
                  }}
                  className="productSearch-sm"
                  style={{
                    borderRadius: "7px",
                    padding: "0.8vh 0.8vw",
                    borderColor: "#fff",
                  }}
                  suffix={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {productSearchInput.length > 0 ? <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} /> : null}
                    </div>
                  }
                  prefix={<img src={SearchIcon} alt="" />}
                /> */}
                <Input
                  placeholder="Search for products by code/name"
                  id="sm-product-search"
                  onChange={(e) => {
                    e.target.value === "" ? clearProductSearchResults() : onChangeInput(e);
                  }}
                  className="productSearch-sm"
                  style={{
                    borderRadius: "7px",
                    padding: "0.8vh 0.8vw",
                    borderColor: "#fff",
                  }}
                  value={productSearchInput}
                  onFocus={(e) => {
                    setKeyboardType({
                      product: true,
                      parkedBill: false,
                      salesHistoryDoc: false,
                      salesHistoryCus: false,
                      searchCus: false,
                      productSearch: false,
                      giftCardSearch: false,
                      addCustomer: false,
                    });
                    setOpen(true);
                  }}
                  onBlur={() => {
                    setIsInputFocused(false);
                  }}
                  // onPressEnter={(e) => {
                  //   !barcodeFlag ? onBarcodeInput(e.target.value, true) : null;
                  // }}
                  suffix={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {productSearchInput.length > 0 ? (
                        // <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
                        <CloseSquareFilled
                          style={{ fontSize: "20px", color: "#2F3856" }}
                          // onClick={() => clearProductSearchResults("")}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (productSearchInputRef?.current) {
                              productSearchInputRef.current.clearInput();
                            }
                            setProductSearchInput("");
                            // clearProductSearchResults();
                          }}
                        />
                      ) : null}
                    </div>
                  }
                  prefix={
                    <img
                      src={SearchIcon}
                      alt=""
                      onClick={() => {
                        onBarcodeInput(productSearchInput, false);
                      }}
                    />
                  }
                />
              </div>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "4.5%",
                  margin: "0 0.2vw 0 0.8vw",
                  borderRadius: "7px",
                  height: "inherit",
                  borderColor: "#fff",
                }}
              >
                <img src={ScanIcon} style={{ height: "2.7vh" }} />
              </Button>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "4.5%",
                  margin: "0 1vw 0 0.5vw",
                  borderRadius: "7px",
                  height: "inherit",
                  borderColor: "#fff",
                }}
                id="sm-product-drawer"
                onClick={() => {
                  setFilterDrawer(true);
                }}
              >
                <img src={FilterIcon} style={{ height: "2.7vh" }} />
              </Button>
            </div>

            <div style={{ padding: "1.5%" }}>
              <Table
                className="sm-cart-table-pos"
                style={{
                  width: "100%",
                  overflowY: "auto",
                  border: "none",
                  borderRadius: "7px",
                  padding: "0px 7px",
                  height: "56.5vh",
                  background: "#fff",
                }}
                pagination={false}
                sticky
                dataSource={
                  // Filter the data where weight is greater than 0
                  (parseFloat(localStorage.getItem("cartObj"))?.items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items || []).filter((item) => item.weight !== 0)
                }
                id="sm-cart-table"
                columns={[
                  {
                    title: "Item Name",
                    dataIndex: "name",
                    key: "name",
                    width: "28%",
                    render: (text, record, index) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              if (cart.type !== "Layaway") {
                                selectProductInCart(record);
                                selectedRowKeys.length > 0 && selectedProductInCart.productId === record.productId ? setShowPaymentMethods(false) : "";
                              }
                            }}
                            id="sm-product-name"
                            className="tableRow"
                          >
                            {record.name}
                          </div>
                          <div className="offerName">
                            <p style={{ margin: 0 }}>{record.cardNo ? record.cardNo : record.value}</p>
                            {/* {record?.customText ? <p style={{ margin: 0, padding: 0, color: "#FFA500" }}>{record?.customText}</p> : ""} */}
                            {record.newCustomAttributes &&
                              record.newCustomAttributes.length > 0 &&
                              Object.entries(record.newCustomAttributes[0]).map(([key, value]) => {
                                return (
                                  value && (
                                    <p style={{ margin: 0, padding: 0, color: "#8c92ac", fontStyle: "italic" }}>
                                      {key} - {value}
                                    </p>
                                  )
                                );
                              })}

                            {record.selectedAddons &&
                              record.selectedAddons.length > 0 &&
                              record.selectedAddons.map((item) => {
                                return item?.name && <p style={{ margin: 0, padding: 0, color: "#8c92ac", fontStyle: "italic" }}>{item.name}</p>;
                              })}

                            <span
                              style={{
                                background: Math.abs(record.discount) > 0 ? "#F8C519" : "",
                                padding: "0.25vw",
                                display: record.discountName ? "" : "none",
                              }}
                            >
                              {Math.abs(record.discount) > 0 ? record.discountName ? <img src={Offers} alt="" width={12} height={12} style={{ marginTop: "-2px" }} /> : "" : ""}{" "}
                              {Math.abs(record.discount) > 0 ? record.discountName || "" : ""}{" "}
                            </span>
                          </div>
                        </>
                      );
                    },
                  },
                  {
                    title: "Qty",
                    dataIndex: "weight",
                    key: "weight",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Discount",
                    dataIndex: "discount",
                    key: "discount",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Tax",
                    dataIndex: "taxAmount",
                    key: "taxAmount",
                    width: "11%",
                    align: "center",
                  },
                  {
                    title: "Price",
                    dataIndex: "realPrice",
                    key: "realPrice",
                    width: "10%",
                    align: "center",
                  },
                  {
                    title: "Total",
                    dataIndex: "nettotal",
                    key: "nettotal",
                    width: "15%",
                    align: "center",
                  },
                  {
                    title: "",
                    dataIndex: "",
                    key: "",
                    width: "10%",
                    render: (text, record) => {
                      return (
                        <div style={{ position: "relative", display: "flex", justifyContent: "space-evenly" }}>
                          <img
                            src={DeleteProduct}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearSelectedProductInCart();
                              removeProductModal(record);
                              setIsInputFocused(false);
                            }}
                            id="sm-product-delete"
                            style={{
                              height: "2.5vh",
                              cursor: "pointer",
                              right: 1,
                              top: "15%",
                            }}
                            // style={{ height: "18px", width: "18px", cursor: "pointer", right: 1, top: "15%", paddingRight: "3px" }}
                          />
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              // console.log("notesValue--------=======>.>",selectedProductForNotes)
                              selectProductInCart(record);
                              setIsInputFocused(false);
                            }}
                            src={NotesImage}
                            alt=""
                            style={{
                              height: "14px",
                              width: "14px",
                              cursor: "pointer",
                              paddingleft: "0.8vw",
                            }}
                          />
                          {/* {posConfig.showLineSalesRep === "Y" && (
                            <img src={userIcon} style={{ height: "2.5vh", cursor: "pointer" }} alt="" onClick={() => setOpenSalesRep({ flag: true, level: "line" })} />
                          )} */}
                          {posConfig.showLineSalesRep === "Y" && (
                            <img
                              // src={userIcon}
                              src={userAddIcon}
                              style={{ height: "2.3vh", cursor: "pointer", paddingTop: "1px" }}
                              alt=""
                              onClick={() => {
                                // setOpenSalesRep({ flag: true, level: "line" });
                              }}
                            />
                          )}
                        </div>
                      );
                    },
                    align: "center",
                  },
                ]}
                onRow={(record) => ({
                  onClick: () => {
                    // if (cart.type !== "Layaway") {
                    setIsInputFocused(false);
                    setAmount(cart.total - cart.paid);
                    selectProductInCart(record);
                    setSelectedProductInCart(record);
                    setStockList([]);
                    // } else {
                    //   message.info("This order cannot be edited!");
                    // }
                  },
                })}
              />
            </div>

            <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
              <div
                style={{
                  background: themeJSON.taxAndDiscountSectionBGColor || "#fff",
                  height: "23.5vh",
                  width: "60%",
                  marginRight: "1%",
                  border: "1px solid #fff",
                  borderRadius: "7px",
                  padding: "0.5vh",
                  fontSize: "1vw",
                }}
              >
                <Row style={{ height: "4.5vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      textAlign: "left",
                      fontWeight: "500",
                      color: "#0F0718",
                    }}
                  >
                    <p style={styles.screenStyles.mainScreen.subTotalText}>Subtotal:</p>
                  </Col>
                  {/* <Col span={12} style={{ display: "flex", alignItems: "center", paddingRight: "0.8vw", textAlign: "right", justifyContent: "end", color: "#0F0718" }}> */}
                  <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {(
                        parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.total : cart.total).toFixed(2) -
                        parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.tax : cart.tax).toFixed(2)
                      ).toFixed(2)}
                    </p>
                    <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                  </Col>
                </Row>

                <Row style={{ height: "4.4vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <p style={styles.screenStyles.mainScreen.subTotalText}>Discount:</p>
                  </Col>
                  <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {parseFloat(cart.discount ? cart.discount : 0).toFixed(2)}
                    </p>
                    <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                  </Col>
                </Row>

                <Row style={{ height: "4.5vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <p style={styles.screenStyles.mainScreen.subTotalText}>Tax:</p>
                  </Col>
                  <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {parseFloat(cart.tax).toFixed(2)}
                    </p>
                    <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                  </Col>
                </Row>

                <Row style={{ height: "4.5vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <p style={styles.screenStyles.mainScreen.subTotalText}>Net Value:</p>
                  </Col>
                  <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj")).total : cart.total).toFixed(2)}
                    </p>
                    <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                  </Col>
                </Row>

                <Row style={{ height: "4.5vh" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.8vw",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <p style={styles.screenStyles.mainScreen.subTotalText}>Total Items / Total Qty:</p>
                  </Col>
                  <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                    <p
                      style={{
                        textAlign: "right",
                        margin: "0",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                    </p>
                  </Col>
                </Row>
              </div>
              <div style={{ height: "23.7vh", width: "40%", fontSize: "1vw" }}>
                <div
                  id="sm-cart-total"
                  // onClick={handleCartTotalClick}
                  onClick={verifyStock}
                  style={{
                    height: "18.2vh",
                    position: "relative",
                    background: themeJSON.totalAmountToPaySectionBGColor || "#2F3856",
                    borderRadius: "7px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      padding: "0.7vw 0 0 1vw",
                      color: "#fff",
                      fontSize: "1em",
                      fontWeight: "500",
                      height: "4.5vh",
                    }}
                  >
                    Total Amount To Pay
                  </p>
                  {cart.total !== undefined ? (
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "2.5em",
                        fontWeight: "500",
                        height: "14.4vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      {parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj")).total : cart.total).toFixed(2)}
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "2.5em",
                        fontWeight: "500",
                        height: "14.4vh",
                        width: "100%",
                      }}
                    >
                      {" "}
                      0.00
                    </p>
                  )}
                </div>
                <Row style={{ marginTop: "0.8vh" }}>
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        fontWeight: "500",
                        justifyContent: "center",
                        border: `1px solid ${themeJSON.holdAndCancelButtonBGColor || "rgba(146, 144, 152, 0.5)"}`,
                        height: "4.8vh",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: themeJSON.holdAndCancelButtonBGColor || "#fff",
                      }}
                      id="sm-delete-cart"
                      onClick={deleteProductModal}
                    >
                      <img src={Cancel} style={{ height: "2.5vh" }} />
                      <span
                        style={{
                          textAlign: "center",
                          margin: "0",
                          color: themeJSON.holdAndCancelButtonTextColor || "#2F3856",
                          paddingLeft: "0.5vw",
                          fontSize: "1em",
                          fontWeight: 500,
                        }}
                      >
                        Cancel
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* Right side UI */}
          <div
            style={{
              width: "35.6%",
              padding: "0% 0% 0 0.4%",
              borderRadius: "10px",
              paddingBottom: "0",
              height: "89vh",
              marginRight: "1%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                className="productSearch-sm"
                id="sm-customer-search"
                style={{
                  borderRadius: "0.5vw",
                  height: "4.9vh",
                  borderColor: "#fff",
                }}
                placeholder="Enter customer number/name"
                prefix={<img src={CustomerSearch} alt="" style={{ height: "2.7vh" }} />}
                onClick={() => {
                  if (cart.isReturn !== true) {
                    setDisplayCustomerSearch(true);
                    setCloseCustomerFlag(true);
                  }
                }}
              />
              <img
                src={AddUser}
                style={{
                  height: "1.6vw",
                  paddingLeft: "1vw",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (cart.isReturn !== true) showAddNewCustomerFields(cart.customer);
                }}
              />
            </div>
            <div style={{ display: "flex", fontSize: "1vw" }}>
              <div
                style={{
                  width: "18%",
                  paddingTop: "3%",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: "0.01rem",
                    display: "block",
                  }}
                >
                  <button
                    className="cartMenuItems"
                    id="sm-product-sync"
                    style={{
                      backgroundColor: "#2F3856",
                      border: `1px solid ${"#2F3856"}`,
                      borderRadius: "7px",
                      height: "9.5vh",
                    }}
                    onClick={() => {
                      let parkedBills = JSON.parse(localStorage.getItem("parkedList"));
                      if (cart.items.length > 0) {
                        openDisplayParkedBillModal();
                      } else if (parkedBills?.length > 0) {
                        message.error("Please clear the Parked Bills to Sync Products!");
                      } else {
                        if (isOnline) {
                          //   handleKeyvalue("4");
                        } else {
                          //   errorMessage("Sync Failed", "Please check your network connection and try again");
                        }
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        fontSize: "1vw",
                      }}
                    >
                      <img src={SyncIcon} style={{ height: "2.5vh" }} color={"#fff"} />
                      <span style={styles.screenStyles.mainScreen.posIcon}>Product Sync</span>
                    </div>
                  </button>
                </div>

                <div style={{ width: "100%", marginBottom: "0.37rem", display: "block" }}>
                  <button
                    className="cartMenuItems"
                    id="sm-discount"
                    style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                    onClick={() => {
                      parseInt(cart.total) > 0 && setManualDiscountModalVisible(true);
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <img src={discount} style={{ height: "2.6vh" }} color={"#fff"} />
                      <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}>
                        Manual Discount
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: "81.5%",
                  paddingTop: "3%",
                  display: showPaymentMethods && "none",
                }}
              >
                {/* <div
                      style={{
                        background: "#fff",
                        height: "41vh",
                        width: "95%",
                        margin: "0% 1% 2.7% 3.5%",
                        border: "1px solid #fff",
                        borderRadius: "7px",
                        visibility: Object.keys(selectedProductInCart).length > 0 ? "visible" : "hidden",
                      }}
                    >
                      <Row gutter={[16]} style={{ paddingTop: "3vh" }}>
                        <Col span={7} style={{ height: "24vh" }}>
                          <img src="" />
                          <img
                            style={{
                              width: "100%",
                              height: "27vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              paddingTop: "1vh",
                              alignSelf: "center",
                              padding: "1vh 0 1vh 0.8vw",
                            }}
                            src={selectedProductInCart.imageurl || DefaultProductImage}
                            alt=""
                          />
                        </Col>
                        <Col span={17} style={{ height: "24vh" }}>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>
                            {selectedProductInCart["name"]?.toString().substring(0, 27)}...
                          </p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>{selectedProductInCart["productBrandName"]}</p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "2.5em", fontFamily: "Inter" }}>
                            {currenciesList[0].symbolRightSide === "N"
                              ? `${currenciesList[0].currSymbol}`
                              : currenciesList[0].symbolRightSide === "Y"
                              ? `${currenciesList[0].currSymbol}`
                              : ""}{" "}
                            {selectedProductInCart["realPrice"]}
                          </p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>Quantity : {selectedProductInCart["weight"]}</p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>Current stock : {selectedProductInCart["stock"]}</p>
                          {stockList.length > 0 ? <CloseCircleOutlined onClick={() => setStockList([])} style={{ position: "absolute", top: "25vh", left: "18vw" }} /> : null}
                          <button
                            onClick={getProductNearByStock}
                            style={{backgroundColor: "#046307C2", border: "1px solid #fff", borderRadius: "7px", height: "5vh", width: "7vw", cursor: "pointer"}}
                           >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                color: "#fff",
                                fontSize: "1em",
                                fontWeight: 500,
                                fontFamily: "Inter",
                              }}
                             >
                              view stock
                            </div>
                          </button>
                          {stockList.map((record) => {
                            return (
                              <p style={{margin: 0, fontWeight: "500", fontSize: "1em", color: "#0F0718", fontFamily: "Inter"}}>
                                {record?.name || ""} {""} stock : {record?.warehouse?.storageBin?.totalQty || 0}
                              </p>
                            );
                          })}




                          <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Availability : {selectedProductInCart["stock"]}</p>
                             <p style={{margin:0,fontWeight:"500",fontSize:"2vh"}}>Offers Available : 0</p> 
                            <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Warehouse : {wareHouse.tillAccess.csBunit.mWarehouse.name} </p>




                        </Col>
                      </Row>
                      {stockList.length < 0 ? (
                        <div style={{ padding: "1vh 1vw" }}>
                          <p style={{ margin: 0, textAlign: "left", color: "#0F0718", fontWeight: "500" }}>Description:</p>
                          <p style={{ margin: 0, textAlign: "left", color: "#2F3856", fontSize: "1em", fontWeight: "500" }}>
                            {selectedProductInCart["description"]?.substring(0, 180)?.concat("....")}
                          </p>
                        </div>
                      ) : null}
                      {stockList.length > 0 ? (
                        <Table
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            borderRadius: "10px",
                            padding: "0",
                            height: "14vh",
                            background: "#fff",
                          }}
                          size="small"
                          locale={{ emptyText: "No data" }}
                          pagination={false}
                          dataSource={[...stockList]}
                          className="sm-stock-table"
                          columns={[
                            {
                              title: "Store",
                              dataIndex: "store",
                              key: "store",
                              render: (text, record, index) => <div>{record?.warehouse?.name || ""}</div>,
                            },
                            {
                              title: "In Stock",
                              dataIndex: "stock",
                              key: "stock",
                              render: (text, record, index) => <div>{record?.warehouse?.storageBin?.totalQty || 0}</div>,
                              align: "right",
                            },
                          ]}
                        />

                      ) : null}
                    </div> */}
                <div
                  style={{
                    background: "#fff",
                    height: "41vh",
                    width: "95%",
                    margin: "0% 1% 2.7% 3.5%",
                    border: "1px solid #fff",
                    borderRadius: "7px",
                    visibility: Object.keys(selectedProductInCart).length > 0 ? "visible" : "hidden",
                  }}
                >
                  {stockList.length > 0 ? (
                    <div>
                      <img
                        src={Back}
                        onClick={() => setStockList([])}
                        id="sm-parked-bill-back"
                        style={{
                          height: "2vw",
                          paddingBottom: "0.5em",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "0.7em",
                        }}
                      />
                      <Table
                        style={{
                          width: "100%",
                          overflowY: "auto",
                          borderRadius: "10px",
                          padding: "0",
                          height: "36vh",
                          background: "#fff",
                          maxHeight: "34vh",
                          display: "block",
                        }}
                        size="small"
                        locale={{ emptyText: "No data" }}
                        pagination={false}
                        dataSource={[...stockList]}
                        className="sm-stock-table"
                        columns={[
                          {
                            title: "Store",
                            dataIndex: "store",
                            key: "store",
                            render: (text, record) => <div>{record?.warehouse?.name || ""}</div>,
                          },
                          {
                            title: "In Stock",
                            dataIndex: "stock",
                            key: "stock",
                            render: (text, record) => <div>{record?.warehouse?.storageBin?.totalQty || 0}</div>,
                            align: "right",
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    <Row gutter={[16]} style={{ paddingTop: "3vh" }}>
                      <Col span={7} style={{ height: "24vh" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "27vh",
                            objectFit: "contain",
                            borderRadius: "5px",
                            paddingTop: "1vh",
                            alignSelf: "center",
                            padding: "1vh 0 1vh 0.8vw",
                          }}
                          src={selectedProductInCart.imageurl || DefaultProductImage}
                          alt=""
                        />
                      </Col>
                      <Col span={17} style={{ height: "24vh", position: "relative" }}>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "500",
                            color: "#0F0718",
                            fontSize: "1em",
                            fontFamily: "Inter",
                          }}
                        >
                          {selectedProductInCart["name"]?.toString().substring(0, 27)}
                          ...
                        </p>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "500",
                            color: "#0F0718",
                            fontSize: "1em",
                            fontFamily: "Inter",
                          }}
                        >
                          {selectedProductInCart["productBrandName"]}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "500",
                            color: "#0F0718",
                            fontSize: "2.5em",
                            fontFamily: "Inter",
                          }}
                        >
                          {selectedProductInCart["realPrice"]}
                        </p>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "500",
                            color: "#0F0718",
                            fontSize: "1em",
                            fontFamily: "Inter",
                          }}
                        >
                          Quantity : {selectedProductInCart["weight"]}
                        </p>
                        {/* <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "1em",
                                fontFamily: "Inter",
                              }}
                            >
                              Current stock : {selectedProductInCart["stock"]}
                            </p> */}
                        {stockList.length > 0 ? (
                          <CloseCircleOutlined
                            onClick={() => setStockList([])}
                            style={{
                              position: "absolute",
                              top: "25vh",
                              left: "18vw",
                              cursor: "pointer",
                            }}
                          />
                        ) : null}
                        <button
                          //   onClick={handleViewStockClick}
                          style={{
                            backgroundColor: "#046307C2",
                            border: "1px solid #fff",
                            borderRadius: "7px",
                            height: "5vh",
                            width: "7vw",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              color: "#fff",
                              fontSize: "1em",
                              fontWeight: 500,
                              fontFamily: "Inter",
                            }}
                          >
                            View Stock
                          </div>
                        </button>
                        {stockList.map((record) => {
                          return (
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                fontSize: "1em",
                                color: "#0F0718",
                                fontFamily: "Inter",
                              }}
                            >
                              {record?.name || ""} {""} stock : {record?.warehouse?.storageBin?.totalQty || 0}
                            </p>
                          );
                        })}

                        {/* <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Availability : {selectedProductInCart["stock"]}</p>
              <p style={{margin:0,fontWeight:"500",fontSize:"2vh"}}>Offers Available : 0</p> 
              <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Warehouse : {wareHouse.tillAccess.csBunit.mWarehouse.name} </p> */}
                      </Col>
                    </Row>
                  )}
                  {stockList.length < 0 ? (
                    <div style={{ padding: "1vh 1vw" }}>
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                          color: "#0F0718",
                          fontWeight: "500",
                        }}
                      >
                        Description:
                      </p>
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                          color: "#2F3856",
                          fontSize: "1em",
                          fontWeight: "500",
                        }}
                      >
                        {selectedProductInCart["description"]?.substring(0, 180)?.concat("....")}
                      </p>
                    </div>
                  ) : null}
                </div>
                <Row style={{ margin: " 2% 0 0 0 " }}>
                  <Input
                    className="productSearch sm-payment-search totalQtyInput"
                    style={{
                      borderRadius: "0.5vw",
                      height: "8.3vh",
                      margin: "0 1% 2.7% 3.5%",
                      borderRadius: "5px",
                      textAlign: "right",
                      fontWeight: "500",
                      fontSize: "2.5em",
                      borderColor: "#fff",
                      color: "#2F3856",
                      fontFamily: "Inter",
                    }}
                    // prefix={<span style={{ color: "#0F0718", fontWeight: "600" }}>Amount Tendered</span>}
                    value={selectedProductInCart.weight}
                    id="sm-amount-input"
                    // type="number"
                    onChange={(e) => {
                      if (cart.items.length > 0 && Object.keys(selectedProductInCart).length > 0) {
                        onChangeTotalQuantity(e.target.value?.replace(/[^\d.]/g, ""));
                      }
                    }}
                    readOnly={cart.items.length > 0 && Object.keys(selectedProductInCart).length > 0 ? false : true}
                    placeholder="Enter Quantity"
                    onFocus={() => {
                      setIsInputFocused(true);
                    }}
                    onBlur={() => {
                      setIsInputFocused(false);
                    }}
                    // ref={quantityInputRef}
                    onPressEnter={enterTotalQty}
                  />
                </Row>
                <Row style={{ padding: "0 1% 0 3.5%" }} gutter={[8, 6]}>
                  <Col span={6}>
                    <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleTotalQty("1")}>
                      1
                    </button>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleTotalQty("2")}>
                      2
                    </button>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleTotalQty("3")}>
                      3
                    </button>
                  </Col>
                  <Col span={6}>
                    <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleTotalQty("x")}>
                      <img src={CancelArrow} alt="back space" width={25} height={15} />
                    </button>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleTotalQty("4")}>
                      4
                    </button>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleTotalQty("5")}>
                      5
                    </button>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleTotalQty("6")}>
                      6
                    </button>
                  </Col>
                  <Col span={6}>
                    {/* {!plusMinus?
                            <button id="sm-amount-buttonClear" onClick={()=>{setPlusMinus(true)}} className="amt-dial-btn-market">Quantity</button>: */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        id="sm-amount-addQuantity"
                        onClick={() => {
                          if (Object.keys(selectedProductInCart).length > 0) {
                            decreaseProductQty(selectedProductInCart);
                          }
                        }}
                        className="amt-dial-btn-market-qty"
                      >
                        -
                      </button>
                      <button
                        id="sm-amount-addQuantity"
                        onClick={async () => {
                          if (Object.keys(selectedProductInCart).length > 0) {
                            increaseProductQty(selectedProductInCart);
                            // if(selectedProductInCart?.bundleId !== undefined){
                            //   const productItem = await getProductData(selectedProductInCart.productId);
                            //   productItem.weight = 1;
                            //   productItem.isReturn = false;
                            //   increaseProductQty(productItem);
                            // }else{
                            //   increaseProductQty(selectedProductInCart);
                            // }
                          }
                        }}
                        className="amt-dial-btn-market-qty"
                      >
                        +
                      </button>
                    </div>
                  </Col>
                  <Col span={18}>
                    <Row gutter={[8, 6]}>
                      <Col span={8}>
                        <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleTotalQty("7")}>
                          7
                        </button>
                      </Col>
                      <Col span={8}>
                        <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleTotalQty("8")}>
                          8
                        </button>
                      </Col>
                      <Col span={8}>
                        <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalQty("9")}>
                          9
                        </button>
                      </Col>
                      <Col span={8}>
                        <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalQty("0")}>
                          0
                        </button>
                      </Col>
                      <Col span={8}>
                        <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleTotalQty(".")}>
                          .
                        </button>
                      </Col>
                      <Col span={8}>
                        <button id="sm-amount-button." className="amt-dial-btn-market" onClick={() => handleTotalQty("clear")}>
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <button id="sm-amount-buttonEnter" className="amt-dial-btn-market2" onClick={enterTotalQty}>
                      Enter
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationComponent;
