import React, { Fragment, useContext, useState, useEffect, useRef, createContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Popover, Row, Col, Card, Progress, Table, Divider, Drawer, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs, Checkbox, Space } from "antd";
import {
  CalendarOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  CloseOutlined,
  LoadingOutlined,
  CloseSquareFilled,
  RightOutlined,
  DownOutlined,
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "react-aspect-ratio/aspect-ratio.css";
import "../../../style.css";
import "../styles.css";
import { Scrollbars } from "react-custom-scrollbars";
import "../../../globalStyles.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useWindowDimensions } from "../../../../lib/useViewport";
import BarCodeComponentForRetail from "../../../../lib/BarCodeComponentForRetail";
import db from "../../../../database";
import { v4 as uuidv4 } from "uuid";
import { processSync } from "../../../Sync/syncData";
import Axios from "axios";
import { clock, timeStamp } from "../../../../utility/clock";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";
import { useEventBus } from "../../../../lib/hooks/EventBusProvider";
import userIcon from "../../../../assets/images/userId.svg";
import clearFilters from "../../../../assets/images/clearFilters.svg";
import POSLogo from "../../../../assets/images/NewLogoCW.svg";
import LockScreen from "../../../../assets/images/lockScreen.svg";
import Close from "../../../../assets/images/close-x.svg";
import sideMenu from "../../../../assets/images/side-menu.svg";
import NotesImage from "../../../../assets/images/notes.svg";
import userAddIcon from "../../../../assets/images/userAddIcon.svg";
import leftLoading from "../../../../assets/images/opo.gif";
import unlinkTill from "../../../../assets/images/unlink-icon.svg";
import couponIcon from "../../../../assets/images/coupon-icon.svg";
import SearchIcon from "../../../../assets/images/searchIcon.svg";
import LockIcon from "../../../../assets/images/lock-icon.svg";
import information from "../../../../assets/images/information.svg";
import FilterIcon from "../../../../assets/images/filterNewICon.svg";
import View from "../../../../assets/images/view.svg";
import ScanIcon from "../../../../assets/images/scanIcon.svg";
import AddUser from "../../../../assets/images/userAdd.svg";
import CustomerSearch from "../../../../assets/images/c-serach.svg";
import ticSelect from "../../../../assets/images/tic.png";
import CustomerIcon from "../../../../assets/images/customer1.svg";
import Retrieve from "../../../../assets/images/retrieve.png";
import { htmlPrint } from "../../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../../lib/printer/xmlPrinter";
import printerIcon from "../../../../assets/images/printerIcon.svg";
import salesReportIcon from "../../../../assets/images/salesReportIcon.svg";
import openDrawerIcon from "../../../../assets/images/openDrawer.svg";
import layAwayIcon from "../../../../assets/images/retrieve.png";
import layAwaySvg from "../../../../assets/images/layaway.svg";
import Discard from "../../../../assets/images/return.png";
import Cancel from "../../../../assets/images/cancel-new.svg";
import Back from "../../../../assets/images/backIcon.svg";
import Hold from "../../../../assets/images/pause-new.svg";
import POSIcon from "../SvgComponent/POS";
import ParkedBillIcon from "../SvgComponent/ParkedBill";
import CashManagementIcon from "../SvgComponent/CashManagementIcon";
import SalesHistoryIcon from "../SvgComponent/SalesHistoryIcon";
import data from "../../../../constants/retailPos.json";
import Offers from "../../../../assets/images/promoTag.svg";
import CancelArrow from "../../../../assets/images/cancelArrow.svg";
import DeleteProduct from "../../../../assets/images/delete1.svg";
import discount from "../../../../assets/images/discount.svg";
import giftVoucher from "../../../../assets/images/giftVoucher.svg";
import creditNote from "../../../../assets/images/creditNote.svg";
import SyncIcon from "../../../../assets/images/syncIcon.svg";
import SyncSide from "../../../../assets/images/sideFullSync.svg";
import Loyality from "../../../../assets/images/loyalityIcon.svg";
import UPI from "../../../../assets/images/upiIcon.svg";
import Gift from "../../../../assets/images/giftIcon.svg";
import Credit from "../../../../assets/images/creditCardIcon.svg";
import cardType from "../../../../assets/images/cardType.svg";
import ReturnIcon from "../../../../assets/images/return.svg";
import Logout from "../../../../assets/images/logout-new.svg";
import Online from "../../../../assets/images/green.svg";
import Ofline from "../../../../assets/images/red.svg";
import _ from "lodash";
import RetailModals from "../RetailModals";
import QuantityUpdate from "../QuantityUpdate";
import { useGlobalContext } from "../../../../lib/auth/globalStore";
import DefaultProductImage from "../../../../assets/images/no-image.svg";
import HCPrint from "../../../../lib/printer/hardWareControllerPrinter";
import printController from "../../../../lib/printer/printController";
import moment from "moment";
import openDrawer from "../../../../lib/printer/openDrawer";
import PoleDisplay from "../../../../lib/printer/poleDisplay";
import salesReportPrinter from "../../../../lib/printer/salesReportPrinter";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import ProductPanelSM from "../productPanelSM";
import styles from "../../../../constants/styles.json";
import user_add from "../../../../assets/images/user_add.svg";
import userTieIcon from "../../../../assets/images/userTieIcon.svg";
import NoteEditIcon from "../../../../assets/images/NoteEditIcon.svg";
import NoteAddIcon from "../../../../assets/images/NoteAddIcon.svg";
import QuotationComponent from "../Quotation";
import productionIcon from "../../../../assets/images/production.svg";

const serverUrl = process.env.REACT_APP_serverUrl;
const rfidScanerFlag = process.env.REACT_APP_ENABLE_VISION;
const version_number = process.env.REACT_APP_release_no || "24Q0.0.0";

const EditableContext = createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, setModalEditState, setIsInputFocused, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(record.qtyToReturn); // State to manage input value
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { t } = useTranslation();
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    setModalEditState(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.qtyToReturn = parseFloat(values.qtyToReturn);
      let returnTotal = record.qty - record.returnQty;
      let isDecimalFlag = false;
      await db.products
        .where("mProductId")
        .equalsIgnoreCase(record.product.mProductId)
        .toArray()
        .then((product) => {
          if (product.length > 0 && product[0].uomData[0]?.decimal === "Y" && !Number.isInteger(values.qtyToReturn)) {
            isDecimalFlag = true;
          }
        });

      if (values.qtyToReturn > returnTotal) {
        {
          message.warning(`${t("sales_return_error")}`);
        }
        throw new Error("Qty is more than ordered, invalid value");
      } else if (isDecimalFlag === false && values.qtyToReturn.toString().includes(".")) {
        message.warning("Return Qty can't be a Decimal Value !");
        throw new Error("Qty is not a valid type, invalid value");
      } else {
        toggleEdit();
        record.qtyToReturn = values.qtyToReturn;
        handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        initialValue={record.qtyToReturn}
      >
        <Input
          className="qtyReturn"
          readOnly={record.qtyToReturn.toString().includes(".")}
          style={{ width: "40%", height: "5vh", borderRadius: "5px" }}
          onFocus={() => setIsInputFocused(true)}
          ref={inputRef}
          type="number"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            save();
          }}
          onBlur={toggleEdit}
        />
      </Form.Item>
    ) : (
      <div
        className="qtyReturn"
        style={{
          border: "1px solid #d9d9d9",
          padding: "0.4vh 1vw",
          width: "40%",
          borderRadius: "5px",
          fontSize: "14px",
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const PointOfSaleMarket = (props) => {
  const { t } = useTranslation();
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  let parkedBillRef = useRef();
  let searchCustomersRef = useRef();
  let searchDocumentNoREf = useRef();
  let notesInputRef = useRef();

  const redirectUrl = process.env.REACT_APP_redirectURL;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const domainURL = process.env.REACT_APP_domain;
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const authHeaders = getOAuthHeaders();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { dispatchEvent } = useEventBus();
  const {
    currentInput,
    setCurrentInput,
    inputValues,
    setInputValues,
    refs,
    addProduct,
    cart,
    setCart,
    checkIsManualWeight,
    clearProductSearchResults,
    closeProductPanel,
    confirmDiscardCart,
    showAddNewCustomerFields,
    decreaseProductQty,
    deleteCart,
    giftCardData,
    setGiftCardData,
    deleteProduct,
    displayClock,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    loader,
    loading,
    setLoading,
    onBarcodeInput,
    openDisplayParkedBillModal,
    openPaymentModal,
    setFilterdDate,
    orderType,
    parkBill,
    parkedList,
    showProductionOrder,
    setShowProductionOrder,
    productCategories,
    productBrands,
    handleBrandCheckboxChange,
    setSelectedProductBrand,
    selectedProductBrand,
    selectCategotyList,
    setSelectCategotyList,
    handleCategoryCheckboxChange,
    productSearchInput,
    productsCopy,
    selectProductCategory,
    selectProductInCart,
    selectProduct,
    selectedProductInCart,
    selectedProductQty,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedKeys,
    setDisplayCustomerSearch,
    setDisplayUAECustomerSearch,
    setCloseCustomerFlag,
    setDisplayOrderType,
    setIsProductsFilter,
    setQtyNumberFlag,
    filtersFlag,
    setFiltersFlag,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    showOrderHistory,
    tillData,
    setSalesHistoryType,
    openSideMenu,
    currencyType,
    setSalesRepModalOpen,
    salesRepresent,
    setSalesRepresent,
    setSalesRepresentSearchInput,
    setFilteredSalesRepresentList,
    salesReprestiveList,
    setManualDiscountInput,
    prevProductsListRef,
    prevHistoryRef,
    setManualDiscountModalVisible,
    setDisplayOfferProductSelection,
    offerProductsList,
    clearSelectedProductInCart,
    productListCardRef,
    posLogActivity,
    upsertPOSLog,
    removeCutomer,
    onChangeTotalQuantity,
    filterDrawer,
    setFilterDrawer,
    setDenaminationsKeyboard,
    denaminationsKeyboard,
    // parked Data
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    setFilterdParkedList,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    discardParkedBill,
    selectParkedBill,
    selectLayAwayOrder,
    // Cash Management
    setAddCashFlag,
    setCouponInput,
    // Order History
    setOrderHistoryInput,
    searchOrderHistory,
    changeOrderHistorySearchType,
    orderHistoryInput,
    orderHistorySearchInputRef,
    orderHistoryDetails,
    setOrderHistoryDetails,
    setOrdersCopy,
    ordersCopy,
    paymentModal,
    tillDataPaymentMethods,
    requestPayment,
    setCouponModalVisible,
    paymentCardRef,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    amount,
    setAmount,
    setNumb,
    onChangeAmount,
    paymentModalInputRef,
    processOrder,
    paymentModalLoyalityMessages,
    handleAmount,
    handleCashPayment,
    customerFlag,
    setCustomerFlag,
    setOverPayedAmount,
    overPayedAmount,
    handleTotalQty,
    enterTotalQty,
    showPaymentMethods,
    setShowPaymentMethods,
    selectedProductCategory,
    setCashAddInFlag,
    addCashFlag,
    isPrintModeXML,
    setSelectedKeys,
    setDisplayReturnOrderSearch,
    displayReturnOrderSearch,
    getSearchedItem,
    isProductsVisible,
    setIsProductsVisible,
    pickProduct,
    // gigt card
    validateGiftCard,
    giftCardFlag,
    setGiftCardFlag,
    giftCardItems,
    giftCardType,
    setGiftCardType,
    isCardPaymentFlag,
    setIsCardPaymentFlag,
    setIsGiftCardFlag,
    // keyboard
    setKeyboardType,
    keyboardType,
    layout,
    setLayout,
    inputName,
    setInputName,
    keyboardParkbill,
    keyboardRef,
    keyboardProduct,
    handleKeyboardInput,
    handleKeyPress,
    searchHistoryInput,
    setSearchhistoryInput,
    showEditOldCustomerFields,
    setEditFlag,
    openPaymentModalByCustomer,
    setSelectedProductInCart,
    setParkedBillSearchInput,
    salesHistoryCustomerSearchInput,
    salesHistoryDocumentNoSearchInput,
    setSalesHistoryCustomerSearchInput,
    setSalesHistoryDocumentNoSearchInput,
    quantityInputRef,
    setIsInputFocused,
    isInputFocused,
    getCategoryProducts,
    salesHistoryType,
    setStartRowData,
    startRowData,
    cartObj,
    documentSequence,
    setDocumnetSequence,
    notesValue,
    setNotesValue,
    selectedProductForNotes,
    setSelectedProductForNotes,
    couponModalVisible,
    displayCustomerSearch,
    displayAddNewCustomer,
    displayEditOldCustomer,
    isGiftCardFlag,
    manualDiscountModalVisible,
    loyalityOtpModalVisible,
    productsData,
    orderDelay,
    setOrderDelay,
    addToBagFlag,
    setAddToBagFlag,
    setStockList,
    stockList,
    displayUAECustomer,
    setDisplayUAECustomer,
    showSaleType,
    setShowSaleType,
    isSaleTypeFlag,
    setIsSaleTypeFlag,
    selectedSaleType,
    setSelectedSaleType,
    selectedSaleTypeData,
    setSelectedSaleTypeData,
    openSalesRep,
    setOpenSalesRep,
    manualDiscountForm,
    verifyStock,
    setIsStockModal,
    showPayments,
    setShowPayments,
    filteredPaymentMethods,
    setStartIndex,
    startIndex,
    getFilteredPaymentMethods,
    handleCartTotalClick,
    isShowingAll,
    setIsShowingAll,
    initialShowPayments,
    open,
    setOpen,
    productSearchInputRef,
    customerSearchInputRef,
    customerSearchInput,
    setCustomerSearchInput,
    layoutName,
    setLayoutName,
    giftCardRef,
    searchTerm,
    setSearchTerm,
    salesRepRef,
    salesRepValue,
    setSalesRepValue,
  } = props;
  const { globalStore, setGlobalStore } = useGlobalContext();
  const { percent, promotionPercentage, productPercentage, productCategoryPercentage } = globalStore;
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const posConfigData = JSON.parse(localStorage.getItem("posConfigData"));
  const customerId = tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId;
  const precision = tillData.tillAccess.csBunit.currencies[0].prcPrecision;
  let taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  const ObFlag = tillData.tillAccess.cwrTill.printTemplate.obController === "Y" ? true : false;
  const [tableValue, setTableValue] = useState("POS");
  const [isReturn, setIsReturn] = useState(false);
  const [layawayFlag, setLayAwayFlag] = useState(false);
  const [reasonPopup, setReasonPopup] = useState(false);
  const [parkedBill, setParkedBill] = useState();
  const [keyValue, setKeyValue] = useState("0");
  const [reasonForm] = Form.useForm();
  const { width } = useWindowDimensions();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [cashManagementData, setCashManagementData] = useState(false);
  const [productArray, setProductArray] = useState();
  const [productItems, setProductItems] = useState([]);
  // const [open, setOpen] = useState(true);
  const [tillLink, setTillLink] = useState(false);
  const [showReturnPurchase, setShowReturnPurchase] = useState(false);
  const [paymentType, setPaymentType] = useState(tillDataPaymentMethods);
  const [isParkedBill, setIsparkedBill] = useState(false);
  const [isPurchaseReturn, setIsPurchaseReturn] = useState(false);
  const [isCashMangement, setIsCashManagement] = useState(false);
  const [returnFlag, setReturnFlag] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [syncLoad, setSyncLoad] = useState(false);
  const [productsDisplay, setProductDisplay] = useState("super-market");
  // const productsDisplay = "super-market";
  const [isLocked, setIsLocked] = useState(false);
  const history = useHistory();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [clock1, setClock1] = useState(clock());
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const [showOverlay, setShowOverlay] = useState(rfidScanerFlag || false);
  const [enableProduction, setEnableProduction] = useState(posConfigData?.posType === "PRD" ? true : false);
  const [isQuotation, setIsQuotation] = useState(() => {
    const cartObj = localStorage.getItem("cartObj");
    return cartObj ? JSON.parse(cartObj).isQuotation ?? false : false;
  });
  const [showCreateOrder, setShowCreateOrder] = useState(false);
  const [rejectQuotation, setRejectQuotation] = useState(false);

  // Return Bill Start
  const [retrunSearchType, setReturnSearchType] = useState("orderNo");
  const [selectedReturnOrder, setSelectedReturnOrder] = useState({});
  const [modalEditState, setModalEditState] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [layoutName, setLayoutName] = useState("default");
  const showVirtualKeyboard = posConfig.showKeyboard === "Y" ? true : false;
  const returnReasons = JSON.parse(localStorage.getItem("tillData")).returnReasons;
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillAccessData = tillData?.tillAccess;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};
  // document.documentElement.style.setProperty('--table-bg-color', themeJSON.productTableBGColor || "#fff");
  const productSearchRef = useRef(null);
  const productPanelSearchRef = useRef(null);
  //view stock
  const [viewStock, setViewStock] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const keyboard = useRef(null);
  const selectBagRef = useRef(null);

  useEffect(() => {
    if (isPurchaseReturn) {
      setTimeout(() => {
        searchCustomersRef.current.focus();
      }, 10);
    }
  }, [isPurchaseReturn]);

  useEffect(() => {
    if (isParkedBill) {
      setTimeout(() => {
        parkedBillRef.current.focus();
      }, 10);
    }
  }, [isParkedBill]);

  const handleProductionClick = () => {
    // setEnableProduction(true);
    // setTillLink(false);
    history.push("/production");
  };

  const handleQuotationClick = () => {
    cart.isQuotation = true;
    setCart(cart);
    localStorage.setItem("cartObj", JSON.stringify(cart));
    setIsQuotation(true);
    setTillLink(false);
  };

  const handleViewStockClick = () => {
    getProductNearByStock();
    setViewStock(true);
  };
  useEffect(() => {
    document.documentElement.style.setProperty("--table-bg-color", themeJSON.productTableBGColor || "#fff");
  }, [themeJSON]);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, [isLocked]);

  // const productSearchInputRef = useRef(null);
  useEffect(() => {
    getCategoryProducts();
    // Focus the input element when the component mounts
    setTimeout(() => {
      try {
        const cartDetails = JSON.parse(localStorage.getItem("cartObj"));
        if (cartDetails !== null) {
          setCart(cartDetails);
        }
      } catch (error) {
        console.error("Error parsing cart details:", error);
      }
    }, 200);
  }, []);

  const handleOutsideClick = (event) => {
    const excludedIds = ["sm-parked-bill-customer", "sm-salesHistory-customer", "sm-product-search", "sm-salesHistory-document"];
    if (!excludedIds.includes(event.target?.id) && event.target.offsetParent?.className !== "ant-drawer-content") {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Update the current date and time every second
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let syncOrdersInterval;

    const startSyncInterval = () => {
      syncOrdersInterval = setInterval(async () => {
        // Check if cartObj exists in localStorage
        if (!localStorage.getItem("cartObj")) {
          return;
        }

        const lastUpdatedTimeString = moment(localStorage.getItem("lastUpdatedTime")).format("HH:mm:ss");
        const lastUpdatedTime = moment(lastUpdatedTimeString, "HH:mm:ss");
        const lastUpdatedOrder = moment(orderDelay).format("HH:mm:ss");
        const lastOrderTime = moment(lastUpdatedOrder, "HH:mm:ss");

        // Create a moment object for the current time
        let cartObj = JSON.parse(localStorage.getItem("cartObj"));
        const currentTime = moment();
        const syncTime = posConfig["Incremental Data Sync Frequency (In Minutes)"] ? posConfig["Incremental Data Sync Frequency (In Minutes)"] : 15;
        const lastFullSyncTime = await db.dataSyncSummary.where("syncType").equals("Full Sync").reverse().sortBy("syncEndTime");

        const fullSync = posConfig["fullDataSyncFrequency"] ? posConfig["fullDataSyncFrequency"] : 360;
        // Calculate the difference in minutes and seconds
        const diffMinutes = currentTime.diff(lastUpdatedTime, "minutes");
        let lastUpdatedFullTimeString = moment(new Date()).format("HH:mm:ss");
        if (lastFullSyncTime?.length > 0) {
          lastUpdatedFullTimeString = moment(lastFullSyncTime[0]?.syncEndTime).format("HH:mm:ss");
        }
        const lastUpdatedFullTime = moment(lastUpdatedFullTimeString, "HH:mm:ss");
        const diffMinutesFullSync = currentTime.diff(lastUpdatedFullTime, "minutes");
        const diffSeconds = currentTime.diff(lastOrderTime, "seconds");
        if (cartObj.items?.length === 0 && diffMinutesFullSync >= fullSync) {
          localStorage.removeItem("lastUpdatedTime");
          setTillLink(false);
          setSyncLoad(true);
          let tillData = JSON.parse(localStorage.getItem("tillData"));
          const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
          setCart({ ...cart, customer: defaultCustomer });
          await processSync(tillData, setGlobalStore, "Full Sync");
          setSyncLoad(false);
          setGlobalStore({ percent: "0%" });
          setGlobalStore({ productCategoryPercentage: "0" });
          setGlobalStore({ productPercentage: "0" });
          setGlobalStore({ promotionPercentage: "0" });
          // window.location.reload();
        } else if (cartObj.items?.length === 0 && diffMinutes >= syncTime && diffSeconds >= 15) {
          clearInterval(syncOrdersInterval); // Clear the interval while syncing
          setSyncLoad(true);
          let tillData = JSON.parse(localStorage.getItem("tillData"));
          const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
          setCart({ ...cartObj, customer: defaultCustomer });
          await processSync(tillData, setGlobalStore, "Incremental Sync");
          setSyncLoad(false);
          setGlobalStore({ percent: "0%" });
          setGlobalStore({ productCategoryPercentage: "0" });
          setGlobalStore({ productPercentage: "0" });
          setGlobalStore({ promotionPercentage: "0" });
          // window.location.reload();
          // startSyncInterval(); // Restart the interval after sync is complete
        }
      }, 1000); // 30000 milliseconds = 30 seconds
    };

    startSyncInterval();

    return () => {
      clearInterval(syncOrdersInterval);
    };
  }, []);

  const formattedDateTime = `${currentDateTime.toLocaleTimeString()} | ${currentDateTime.toLocaleDateString("en-US", {
    weekday: "long", // Full day name (e.g., Wednesday)
    day: "numeric", // Day of the month (e.g., 28)
    month: "long", // Full month name (e.g., June)
    year: "numeric", // Full year (e.g., 2023)
  })}`;

  const searchReturnHistory = async (documentno, record) => {
    const searchQuery = `${retrunSearchType} : "${documentno}"`;
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      try {
        setLoading(true);
        const searchData = await Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `query {
                      verifyReturn(${searchQuery}) {
                          sOrderID
                          created
                          createdby
                          updated
                          updatedby
                          accumulation
                          redemption
                          documentno
                          dateordered
                          totalQty
                          orderTime
                          cwrProductQty
                          taxamt
                          grosstotal
                          discAmount
                          csBUnit {
                              csBunitId
                              name
                          }
                          cwrB2cCustomer {
                              cwrCustomerId
                              code
                              name
                              mobileNo
                              pincode
                              email
                              retlLoyaltyBalance
                              loyaltyLevel{
                                cwrLoyaltyLevelId
                                name
                                accumulationRate
                                redemptionRate
                            }
                            b2cCustomerSegment{
                              name
                              cwrB2CCustomerSegmentId
                               }
                              sCustomer {
                                  sCustomerID
                                  customerCategory {
                                      sCustomerCateforyId
                                      value
                                      name
                                      description
                                  }
                              }
                          }
                          saleType {
                              cwrSaletypeId
                              name
                              value
                          }
                          cwrTill {
                              cwrTillID
                              till
                          }
                          finReceiptPlan {
                              finReceiptPlanDetails {
                                  amount
                                  cwrPaymentmethod {
                                      cWRPaymentMethodID
                                      finFinancialAccountId
                                      finPaymentmethodId
                                      integratedPayment
                                      isloyalty
                                      paymentProvider
                                  }
                              }
                          }
                          line {
                              sOrderlineID
                              sOrderId
                              line
                              description
                              qty
                              netlist
                              netunit
                              linetax
                              unittax
                              linenet
                              linegross
                              grosslist
                              grossstd
                              returnline
                              returnQty
                              discount
                              product {
                                  mProductId
                                  name
                                  value
                                  upc
                                  hsncode
                                  imageurl
                                  isManualQty
                                  shortDescription
                                  returnable
                                  returnDays
                              }
                              uom {
                                  csUomId
                                  name
                              }
                              tax {
                                  csTaxID
                                  name
                                  rate
                              }
                              pricingRule {
                                mPricingrulesId
                                name
                                discount
                              }
                          }
                      }
                  }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });
        let matchedRecord = searchData.data.data.verifyReturn.filter((item) => item.sOrderID === record.sOrderID);
        selectPurchaseBillForReturn(matchedRecord);
        setShowReturnPurchase(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = (row) => {
    setIsInputFocused(true);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
    handleSaveReturnCartRecord(newData);
  };

  let index = 0;
  const selectPurchaseBillForReturn = (data) => {
    if (index === 0) {
      let returnBillItem = data[0];
      setDisplayReturnOrderSearch(false);
      setReturnSearchType("orderNo");

      const discountValue = returnBillItem.discAmount;
      let grossstdTotal = 0;
      for (let i = 0; i < returnBillItem.line.length; i += 1) {
        grossstdTotal += parseFloat(returnBillItem.line[i].grossstd);
      }
      const zeroDiscountCount = returnBillItem.line.filter((item) => item.discount === 0).length;

      if (zeroDiscountCount === returnBillItem.line.length && discountValue > 0) {
        returnBillItem.line.forEach((addedToCart, index) => {
          const discountAmt = parseFloat((addedToCart.grossstd / grossstdTotal) * discountValue) / addedToCart.qty;
          const sp = parseFloat(addedToCart.grossstd) - discountAmt;
          addedToCart.grossstd = sp;
          returnBillItem.line[index] = addedToCart;
        });
      }
      const updatedDataSource = [...returnBillItem.line];
      let itemsToReturn = 0;
      let itemsQtyToReturn = 0;
      let returnAmountTotal = 0;

      updatedDataSource.forEach((_, i) => {
        updatedDataSource[i].key = `${i}`;
        updatedDataSource[i].remainingQty = updatedDataSource[i].returnQty > 0 ? updatedDataSource[i].qty - updatedDataSource[i].returnQty : updatedDataSource[i].qty;
        updatedDataSource[i].qtyToReturn = updatedDataSource[i].returnQty > 0 ? updatedDataSource[i].qty - updatedDataSource[i].returnQty : updatedDataSource[i].qty;
        itemsToReturn += 1;
        itemsQtyToReturn += updatedDataSource[i].qtyToReturn > 0 ? updatedDataSource[i].qtyToReturn : updatedDataSource[i].qty;
        returnAmountTotal += updatedDataSource[i].qtyToReturn > 0 ? updatedDataSource[i].qtyToReturn : updatedDataSource[i].qty * updatedDataSource[i].grossstd;
      });
      updatedDataSource.itemsToReturn = 0;
      updatedDataSource.itemsQtyToReturn = 0;
      updatedDataSource.returnAmountTotal = 0;

      returnBillItem.itemsToReturn = itemsToReturn;
      returnBillItem.itemsQtyToReturn = itemsQtyToReturn;
      returnBillItem.returnAmountTotal = returnAmountTotal;
      setDataSource([...updatedDataSource]);
      setSelectedReturnOrder({ ...returnBillItem });
      index++;
    }
  };

  const handleSaveReturnCartRecord = (data) => {
    let itemsToReturn = 0;
    let itemsQtyToReturn = 0;
    let returnAmountTotal = 0;
    data.forEach((item, i) => {
      if (item.qtyToReturn > 0) {
        itemsToReturn += 1;
        itemsQtyToReturn += item.qtyToReturn;
        returnAmountTotal += item.qtyToReturn * item.grossstd;
      }
    });

    selectedReturnOrder.line = data;
    selectedReturnOrder.itemsToReturn = itemsToReturn;
    selectedReturnOrder.itemsQtyToReturn = itemsQtyToReturn;
    selectedReturnOrder.returnAmountTotal = returnAmountTotal;
    setSelectedReturnOrder({ ...selectedReturnOrder });
  };

  const addSelectedReturnProductsToCart = async () => {
    try {
      let cartItems = [...cart.items];
      for (let index = 0; index < selectedReturnOrder.line.length; index++) {
        const item = { ...selectedReturnOrder.line[index] };
        let giftCardItem = giftCardItems.filter((giftCard) => giftCard.productId === item.product.mProductId);
        const productItem = checkedKeys.includes(item.key) && giftCardItem.length === 0 ? await getProductData(item.product.mProductId, item) : null;
        if (productItem && item.qtyToReturn > 0) {
          productItem.sOrderReturnId = item.sOrderId;
          productItem.sOrderlineReturnId = item.sOrderlineID;
          if (item.returnQty < item.qty) {
            let discountAmount = productItem.discount / item.qty;
            productItem.weight = -item.qtyToReturn;
            const mrp = parseFloat(Math.abs(productItem.salePrice)) * parseFloat(item.qtyToReturn);
            const tax = productItem.taxAmount / item.qty;
            productItem.discount = discountAmount * item.qtyToReturn;
            productItem.taxAmount = tax * item.qtyToReturn;
            productItem.realPrice = item.grossstd;
            productItem.nettotal = (mrp - parseFloat(productItem.discount ? Math.abs(productItem.discount) : 0)) * -1;
            let netStd = parseFloat(Math.abs(productItem.sunitprice) - Math.abs(productItem.sunitprice) / (1 + Math.abs(productItem.taxRate) / 100));
            productItem.netStd =
              taxIncludeFlag === "Y"
                ? parseFloat(((Math.abs(productItem.sunitprice) - netStd) * -1).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
                : productItem.sunitprice;
            cartItems.push(productItem);
          }
          if (
            db.logConfiguration.toArray().then((fetched) => {
              fetched.map((item) => {
                item.salesReturn.filter((item) => item.log === "Y").length > 0;
              });
            })
          ) {
            posLogActivity(productItem, "SLR");
          }
        }
      }

      let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
        ? JSON.parse(localStorage.getItem("orderTimeDetails"))
        : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
      orderTimeDetails = {
        ...orderTimeDetails,
        paymentStartTime: "",
        orderStartTime: orderTimeDetails.orderStartTime !== "" ? orderTimeDetails.orderStartTime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
      };
      localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));

      let updatedCart = {
        ...cart,
        items: cartItems,
        isReturn: true,
        customer: selectedReturnOrder.cwrB2cCustomer,
      };
      let updatedTotalTax = 0;
      let updatedTotalPrice = 0;
      let updatedTotalItemsQty = 0;
      let updatedTotalDiscounts = 0;
      for (let i = 0; i < updatedCart.items.length; i += 1) {
        updatedTotalPrice += updatedCart.items[i].nettotal;
        updatedTotalItemsQty += updatedCart.items[i].weight;
        updatedTotalTax += updatedCart.items[i].taxAmount;
        updatedTotalDiscounts += updatedCart.items[i].discount ? updatedCart.items[i].discount : 0;
        updatedCart.items[i].key = i;
      }
      let finalCartObj = {
        ...updatedCart,
        items: [...updatedCart.items],
        total: parseFloat(updatedTotalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
        tax: updatedTotalTax,
        discount: updatedTotalDiscounts,
        totalQty: updatedTotalItemsQty,
      };
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
      setCart({ ...finalCartObj });
      setLoading(false);
      setKeyValue("0");
    } catch (error) {
      console.error("Error adding selected return products to cart:", error);
    }
  };

  const getProductData = (data, itemData) => {
    return new Promise(function (resolve) {
      db.products
        .where("mProductId")
        .equalsIgnoreCase(data)
        .toArray()
        .then((product) => {
          if (product.length > 0) {
            const obj = { ...product[0] };
            if (obj.overRideTax === "Y" && obj.sunitprice <= obj.overRideCondition) {
              // prettier-ignore
              const originalPrice = obj.sunitprice - (obj.sunitprice - (obj.sunitprice * (100 / (100 + obj.taxRate))));
              const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
              obj.sunitprice = taxedPrice;
              obj.cTaxId = obj.contraTaxId;
              obj.taxRate = obj.contraRate;
            }
            const productDefined = {
              batchno: null,
              description: obj.description,
              discount: itemData.discount * -1,
              imageurl: obj.imageurl,
              isDecimal: obj.isDecimal,
              isManualQty: obj.isManualQty,
              isPromoApplicable: false,
              isReturn: true,
              mBatchId: null,
              mPricingruleId: null,
              name: obj.name,
              name2: obj.name2,
              nettotal: itemData.linegross * -1,
              primaryOrderLine: null,
              productId: obj.mProductId,
              realPrice: obj.sunitprice * -1,
              listPrice: obj.slistprice * -1,
              sunitprice: obj.sunitprice * -1,
              returnQty: null,
              salePrice: itemData.grossstd * -1,
              unitTax: itemData.unittax * -1,
              mrpPrice: obj.sunitprice * -1,
              stock: obj.onhandQty,
              taxCategory: obj.taxCategory,
              taxName: obj?.taxName,
              taxFlag: obj?.taxFlag || "N",
              tax: obj.cTaxId,
              taxAmount: itemData.linetax * -1,
              taxRate: obj.taxRate,
              uom: obj.csUomId,
              uom_name: obj.uomName,
              isDecimalQty: obj.uomData?.length > 0 ? (obj.uomData[0].decimal === "Y" ? true : false) : false,
              isQtyDesimal: obj.uomData?.length > 0 ? obj.uomData[0].stdprecision : 2,
              upc: obj.upc,
              value: obj.value,
              weight: 0,
              shortDescription: obj.shortDescription,
              hsncode: obj.hsncode,
              csBunitId: obj.csBunitId,
              mProductCategoryId: obj.mProductCategoryId,
              productManufacturerId: obj.productManufacturerId,
              productBrandId: obj.productBrandId,
              batchedProduct: obj.batchedProduct,
              batchedForSale: obj.batchedForSale,
              batchedForStock: obj.batchedForStock,
              multiPrice: obj.multiPrice,
              shelfLife: obj.shelfLife,
              discountName: itemData.pricingRule.name,
              mPricingruleId: itemData.pricingRule.mPricingrulesId,
            };
            resolve(productDefined);
          } else {
            {
              message.warning(`${t("product_search_error")}`);
            }
            resolve(null);
          }
        });
    });
  };

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey && keyCode === 82) {
      if (posConfig.showSalesReturn === "Y") {
        setDisplayReturnOrderSearch(true);
      }
    }
  };

  useEffect(() => {
    let methods = [];
    tillDataPaymentMethods.map((item) => {
      if (item.name === "Cash" && posConfig.enableCash === "Y") {
        methods.push(item);
      } else if (item.name === "Card" && posConfig.enableCard === "Y") {
        methods.push(item);
      } else if (item.name !== "Cash" && item.name !== "Card") {
        methods.push(item);
      }
    });
    setPaymentType(methods);
    if (!localStorage.getItem("salesReturn")) {
      localStorage.setItem("salesReturn", "N");
    }

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  // get stock details

  const getProductNearByStock = async () => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const stockInputValues = {
      query: `
        query {
          getProductsNearbyStock(
            bUnitId: "${tillData.tillAccess.csBunit.csBunitId}",
            code: "${selectedProductInCart.value}"
          ) {
            csBunitId
            value
            name
            totalQty
            stores {
              csNearbyStoreId
              name
              nearbybUnitId
              viewStock
              isIssue
              warehouse {
                mWarehouseID
                name
                value
                storageBin {
                  mStoragebinID
                  name
                  stocks {
                    mStockID
                    qtyonhand
                    product {
                      mProductId
                      value
                      name
                      upc
                      uom {
                        csUomId
                        name
                      }
                      productCategory {
                        mProductCategoryId
                        value
                        name
                      }
                    }
                    batch {
                      mBatchId
                      batchno
                      upc
                      price
                      startdate
                      enddate
                      life
                    }
                  }
                  totalQty
                }
              }
            }
          }
        }
      `,
    };

    await Axios({
      url: serverUrl,
      method: "POST",
      data: stockInputValues,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${setAuthTokens}`,
      },
    })
      .then((response) => {
        const { stores, totalQty, csBunitId } = response.data.data.getProductsNearbyStock;

        const currentStock = {
          csNearbyStoreId: "53F4FBEBDAB94C4AB205B7415EBD739E",
          name: tillData.tillAccess.csBunit.mWarehouse.name,
          nearbybUnitId: "EEF9FADCE0D74AE3B8B6096E6C35B6AA",
          viewStock: "Y",
          isIssue: "Y",
          warehouse: {
            mWarehouseID: "79FD0E516E4F4C5F9A7932F0C5C82C92",
            name: tillData.tillAccess.csBunit.mWarehouse.name,
            value: "Central WH TTLLC",
            storageBin: {
              mStoragebinID: "09581CA09C154830A20B88370C501FD6",
              name: "Central Warehouse",
              stocks: [],
              totalQty: totalQty || 0,
            },
          },
        };

        const isStorePresent = stores?.some((store) => store?.nearbybUnitId === csBunitId);
        setStockList(isStorePresent ? stores : [currentStock, ...stores]);
      })
      .catch((error) => {
        console.error("Error fetching nearby stock:", error);
      });
  };

  // handle scroll start

  let scrollLeft;
  let maxScroll;
  let currentScroll;

  const tableBodyRef = useRef(null);
  const dateRef = useRef(null);

  let debounceTimeout;

  const fetchMoreData = (event) => {
    const lengthOff = localStorage.getItem("dataLength");
    let previousMaxScroll = maxScroll;
    let previousScroll = currentScroll;
    maxScroll = event.target.scrollHeight - event.target.clientHeight;
    currentScroll = event.target.scrollTop;
    if (scrollLeft !== event.target.scrollLeft) {
      scrollLeft = event.target.scrollLeft;
      return null;
    }

    if (Math.round(currentScroll) >= Math.round(maxScroll) - 1) {
      let orderD = JSON.parse(localStorage.getItem("orderType"));

      setStartRowData((prevData) => {
        const newStartRow = lengthOff;
        const newEndRow = lengthOff + 20;

        // Validate using previous data
        if (orderD.length > 0 && (prevData.startRow !== newStartRow || prevData.endRow !== newEndRow) && (previousMaxScroll !== maxScroll || previousScroll !== currentScroll)) {
          if (tableBodyRef.current) {
            tableBodyRef.current.scrollTop = Math.round(currentScroll) - 5;
          }
          let dateFlag = true;
          if (dateRef.current.textContent !== "Choose date range") {
            dateFlag = false;
          }

          clearTimeout(debounceTimeout);

          debounceTimeout = setTimeout(() => {
            searchOrderHistory(orderD[0], orderD[1], lengthOff, true, dateFlag);
          }, 300);
        }

        // Return the new state
        return {
          startRow: newStartRow,
          endRow: newEndRow,
        };
      });
    }
  };

  // let antTableBody = document.querySelector(".sm-cart-table");

  useEffect(() => {
    const antTableBody = tableBodyRef.current;
    if (antTableBody) {
      antTableBody.addEventListener("scroll", fetchMoreData);
      return () => {
        antTableBody.removeEventListener("scroll", fetchMoreData);
      };
    }
  }, [isPurchaseReturn]);

  // handle Scroll stop

  const returnBillSearchInputRef = useRef();
  const parkedBillsRef = useRef();
  useEffect(() => {
    if (displayReturnOrderSearch) {
      setTimeout(() => {
        returnBillSearchInputRef.current.focus();
      }, 100);
    }
  }, [retrunSearchType, displayReturnOrderSearch]);

  const returnProcessRef = useRef();

  // Return Bill end

  useEffect(async () => {
    filterdParkedList?.map((item, index) => {
      item.key = uuidv4().replace(/-/g, "").toUpperCase();
    });
    let data = await db.cashInCashOut.toArray();
    setCashManagementData(data);
    if (paymentModal === false) {
      setTimeout(() => {
        setKeyValue("0");
      }, 1000);
    }
  }, [addCashFlag, paymentModal]);

  orderHistoryDetails.map((item) => {
    item.name = item.customer.name;
    item.status = item.isSynced === 1 || item.status === "Success" ? "Success" : "Pending";
  });

  const removeProductModal = (record) => {
    deleteProduct(record);
    // if (productSearchInputRef.current) {
    //   productSearchInputRef.current.focus();
    //   const productSearchInput = document.getElementById("sm-product-search");
    //   if (productSearchInput) {
    //     productSearchInput.focus();
    //     productSearchInput.select();
    //   }
    // }
  };

  const discardGiftCard = async (cart) => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    if (giftCardData.length > 0) {
      let details = [];
      cart?.giftCardData?.map((ele) => {
        details.push(`{
          cardNo: ${ele.number ? `"${ele.number}"` : null}
          referenceNo: "${ele.refId}"
              }`);
      });
      await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation{
            discardGiftCard(giftCards: [${details}]){
           status
           message
            }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });
      let upsertDetails = [];
      giftCardData.map((ele) => {
        upsertDetails.push(`{
          cardNo: ${ele.number ? `"${ele.number}"` : null}
          type: "RD"
          referenceNo: "${cart.referenceId}"
          amount: ${ele.amount * -1}
          cardPin: ${data.pin ? `"${data.pin}"` : null}
              }`);
      });
      await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation{
                        upsertGiftCardTransaction(giftCardTransactions:[${upsertDetails}]){
                            status
                            message
                        }
                    }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      });
    }
  };

  const deleteProductModal = () => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "This action will remove all products from the cart, including the selected customer.Do you wish to proceed?",
        icon: null,
        cancelText: "No",
        okText: "Yes",
        autoFocusButton: null,
        onOk() {
          let setAuthTokens;
          if (authHeaders && authHeaders.access_token) {
            setAuthTokens = authHeaders.access_token;
          }
          if (cart?.couponInput?.length > 0) {
            const serverUrl = process.env.REACT_APP_serverUrl;

            const couponsData = [...cart.couponInput];
            // Function to discard a coupon
            const discardCoupon = async (coupon) => {
              const discardCouponQuery = {
                query: `query {
                  discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
                    status
                    message
                  }
                }`,
              };
              try {
                const response = await Axios({
                  url: serverUrl,
                  method: "POST",
                  data: discardCouponQuery,
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${setAuthTokens}`,
                  },
                });

                console.log(`Coupon ${coupon.couponCode} discarded:`, response.data);
              } catch (error) {
                console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
              }
            };
            couponsData.forEach((coupon) => {
              discardCoupon(coupon);
            });
          }

          discardGiftCard(cart);
          let index = cart.payments.findIndex((payment) => payment.name.toLowerCase() === "loyalty" && !cart.isReturn);
          if (index >= 0) {
            handleLoyaltyPayment(cart.payments[index], cart);
          }
          clearCart(cart.type);
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setTimeout(() => {
              productSearchInput.focus();
            }, 300);
          }
        },
      });
    }
  };

  const handleLoyaltyPayment = async (payment, cart) => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    const serverUrl = process.env.REACT_APP_serverUrl;
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    let value = parseFloat((payment.amount / cart.customer.redeemptionValue).toFixed(2));
    if (cart.customer.loyaltyLevel.cwrLoyaltyLevelId) {
      return await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
                    upsertLoyaltyTransaction(transaction: [{
                        csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
                        loyaltyPoints: ${value * -1}
                        b2cCustomerId: "${cart.customer.cwrCustomerId}"
                        referenceId: "${cart.referenceId}"
                      }]) {
                      status   
                      message
                    }
                  }
                  `,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${setAuthTokens}`,
        },
      })
        .then(async (loyalityResponse) => {
          // cart.accumulationPoints = value * -1;
          cart.customer.retlLoyaltyBalance = cart.customer.retlLoyaltyBalance + parseFloat(value);
        })
        .catch((err) => {
          message.error("Network Error");
        });
    }
  };

  const showMoreItems = () => {
    const PaymentMethods = tillData.tillAccess.csBunit.paymentMethodList;
    const paymentDetails = getFilteredPaymentMethods();
    let itemsPerPage =
      tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && PaymentMethods.length === 5 ? 5 : cart.layAway === "Y" ? 4 : 5;
    if (startIndex + itemsPerPage >= paymentDetails.length) {
      if (startIndex < paymentDetails.length) {
        setShowPayments(paymentDetails.slice(startIndex, paymentDetails.length));
        setStartIndex(paymentDetails.length);
      } else {
        setShowPayments(paymentDetails.slice(0, itemsPerPage));
        setStartIndex(4);
      }
    } else {
      setShowPayments(paymentDetails.slice(startIndex, itemsPerPage + startIndex));
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const showPreviousItems = () => {};

  useEffect(async () => {
    if (cart.items.length === 0 && cart.payments.length > 0) {
      await cart.payments.map(async (payment) => {
        handleDeletePayment(payment);
      });
    }
  }, [cart]);

  const handleDeletePayment = (payment) => {
    let paid = 0;
    Promise.all(
      cart.payments.map(async (pay) => {
        if (payment.name.toLowerCase() === "gift card" && payment.finPaymentmethodId === pay.finPaymentmethodId) {
          let setAuthTokens;
          if (authHeaders && authHeaders.access_token) {
            setAuthTokens = authHeaders.access_token;
          }
          const upsertDetails = giftCardData.map((ele) => ({
            cardNo: ele.number ? ele.number : null,
            type: "RD",
            referenceNo: cart.referenceId,
            amount: ele.amount * -1,
            cardPin: ele.pin ? ele.pin : null,
          }));

          const upsertDetailsStrings = upsertDetails.map(
            (detail) => `{
          cardNo: ${detail.cardNo ? `"${detail.cardNo}"` : null},
          type: "${detail.type}",
          referenceNo: "${detail.referenceNo}",
          amount: ${detail.amount},
          cardPin: ${detail.cardPin ? `"${detail.cardPin}"` : null}
        }`
          );

          await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `mutation{
                          upsertGiftCardTransaction(giftCardTransactions:[${upsertDetailsStrings}]){
                              status
                              message
                          }
                      }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${setAuthTokens}`,
            },
          }).then((response) => {
            let cardDetails = [...giftCardData];
            let uniqId = uuidv4().replace(/-/g, "").toUpperCase();
            if (response.data.data.upsertGiftCardTransaction.status == 200) {
              upsertDetails.forEach((detail) => {
                cardDetails.push({
                  number: detail.cardNo,
                  amount: detail.amount,
                  redemptionId: uniqId,
                  pin: detail.cardPin ? detail.cardPin : null,
                });
              });
            }
            setGiftCardData(cardDetails);
          });
        } else if (payment.name.toLowerCase() === "loyalty" && payment.finPaymentmethodId === pay.finPaymentmethodId) {
          await handleLoyaltyPayment(payment, cart);
        } else if (pay.name !== payment.name && payment.name.toLowerCase() !== "card") {
          paid = paid + parseFloat(pay.amount);
          return pay;
        } else if (payment.name.toLowerCase() === "card" && (payment.authorization ? pay.authorization !== payment.authorization : true)) {
          paid = paid + parseFloat(pay.amount);
          return pay;
        }
      })
    )
      .then((results) => {
        // Filter out undefined results (where payment name matched)
        cart.payments = results.filter((result) => result !== undefined);
        cart.paid = paid + (cart?.advancePayment || 0);
        setAmount(parseFloat((cart.total - cart.paid).toFixed(2)));
        localStorage.setItem("cartObj", JSON.stringify(cart));
        setCart(cart);
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };

  const clearCart = async (type) => {
    const tillSession = JSON.parse(localStorage.getItem("tillSession"));
    const tillSessionId = tillSession.tillSessionId;
    const newDocumentSequence = cart.parked === "Y" ? documentSequence : documentSequence + 1;
    let cartToDb = cart;
    cartToDb.orderTime = timeStamp();
    cartToDb.createdBy = tillData.tillAccess.csUserId;
    cartToDb.orderType = orderType?.cwrSaletype?.cwrSaletypeId;
    cartToDb.orderDate = moment(new Date()).format("YYYY-MM-DD");
    cartToDb.tillSessionId = tillSessionId;
    cartToDb.key = uuidv4().replace(/-/g, "").toUpperCase();
    cartToDb.isSynced = 0;
    cartToDb.syncAttempts = 0;
    cartToDb.customerSearchKey = cart.customer.code;
    cartToDb.tax = 0;
    cartToDb.total = 0;
    cartToDb.totalQty = 0;
    cartToDb.couponInput = [];
    cartToDb.discount = 0;
    cartToDb.isReturn = false;
    cartToDb.items.forEach((item) => {
      item.salePrice = 0;
      item.sunitprice = 0;
      item.netStd = 0;
      item.nettotal = 0;
      item.unitTax = 0;
      item.taxAmount = 0;
      item.weight = 0;
      item.unitPrice = 0;
      item.isReturn = false;
      item.netList = 0;
      item.discount = 0;
      item.linetax = 0;
      item.linenet = 0;
      item.linegross = 0;
      item.netunit = 0;
      item.listPrice = 0;
      item.grossunit = 0;
      item.grossstd = 0;
      item.grosslist = 0;
    });
    deleteCart(true, cart.parked === "Y" ? "parked" : cartToDb.type);
    setGiftCardData([]);
    if (type === "Layaway") {
      setDocumnetSequence(documentSequence);
    } else {
      localStorage.setItem("documentSequence", newDocumentSequence);
      setDocumnetSequence(newDocumentSequence);
      await db.orders.add(cartToDb);
    }
  };

  const columns = [
    {
      title: "Parked Sale",
      dataIndex: "parkedTime",
      key: "parkedTime",
      width: "20%",
    },
    {
      title: "Document No",
      dataIndex: "parkedDocNo",
      key: "parkedDocNo",
      width: "25%",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "20%",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
    },
  ];

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [notesPopup, setNotesPopup] = useState(false);

  const onSelectAllChange = (e) => {
    if (e.target.checked) {
      // If the checkbox in the header is checked, select all rows
      const allKeys = dataSource.map((record) => {
        let giftCardItem = giftCardItems.filter((giftCard) => giftCard.mProductId === record.product.mProductId);
        if (record.returnQty < record.qty && giftCardItem.length === 0) return record.key;
      });
      setCheckedKeys(allKeys);
      setSelectedRecords(dataSource);
    } else {
      // If the checkbox in the header is unchecked, clear the selection
      setCheckedKeys([]);
      setSelectedRecords([]);
    }
  };

  const onSelect = (record) => {
    const newCheckedKeys = [...checkedKeys];
    const recordKey = record.key;
    let giftCardItem = giftCardItems.filter((giftCard) => giftCard.mProductId === record.product.mProductId);
    if (newCheckedKeys.includes(recordKey)) {
      // If the record is already selected, deselect it
      const index = newCheckedKeys.indexOf(recordKey);
      newCheckedKeys.splice(index, 1);

      // Remove the record from the selectedRecords array
      const newSelectedRecords = selectedRecords.filter((selectedRecord) => selectedRecord.key !== recordKey);
      setSelectedRecords(newSelectedRecords);
    } else {
      const index = dataSource.findIndex((record) => record.key === recordKey);
      if (dataSource[index].returnQty < dataSource[index].qty && giftCardItem.length === 0) {
        newCheckedKeys.push(recordKey);
      }
      // If the record is not selected, select it

      // Add the record to the selectedRecords array
      const newSelectedRecords = [...selectedRecords, record];
      setSelectedRecords(newSelectedRecords);
    }

    setCheckedKeys(newCheckedKeys);
  };

  const rowSelection = {
    selectedRowKeys: checkedKeys,
    onChange: (selectedKeys) => {
      setCheckedKeys(selectedKeys);
    },
  };

  const inputReturnQty = (record, e) => {
    dataSource.map((item) => {
      if (item.key === record.key) {
        item.qtyToReturn = e.target.value;
      }
    });
    setDataSource(dataSource);
  };

  const columns1 = [
    {
      title: <Checkbox onChange={onSelectAllChange} checked={checkedKeys.length === dataSource.length} />,
      width: "7%",
      dataIndex: "selection", // Use a unique dataIndex for this column
      render: (_, record) => <Checkbox checked={checkedKeys.includes(record.key)} onChange={() => onSelect(record)} />,
    },
    {
      title: "Description",
      dataIndex: "product",
      width: "25%",
      render: (_, record) => {
        return record.product.name;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "grossstd",
      width: "15%",
      render: (_, record) => {
        return parseFloat(record.grossstd).toFixed(2);
      },
    },
    {
      title: "Qty Ordered",
      dataIndex: "qty",
      width: "15%",
    },
    {
      title: "Remaining Qty",
      dataIndex: "remainingQty",
      width: "15%",
    },
    {
      title: "Qty to Return",
      dataIndex: "qtyToReturn",
      width: "15%",
      // render: (_, record) => (
      //   <Input
      //   value={record.qtyToReturn}
      //     onChange={(e) => inputReturnQty(record,e)}
      //   />
      // ),
    },
  ];

  const orderHistoryColumns = [
    {
      title: "Document Number",
      dataIndex: "documentno",
      key: "documentno",
      width: "20%",
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: "20%",
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      width: "25%",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "19%",
    // },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "20%",
      filters: [
        {
          text: "Layaway",
          value: "Layaway",
        },
        {
          text: "Return",
          value: "Return",
        },
        {
          text: "Receipt",
          value: "Receipt",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (text, record) => {
        let colorStyle;
        switch (record.type) {
          case "Layaway":
            colorStyle = { color: "#007BFF" };
            break;
          case "Return":
            colorStyle = { color: "#f0ad4e" };
            break;
          case "Receipt":
            colorStyle = { color: "#28a745" };
            break;
          default:
            colorStyle = {};
        }

        return (
          <div style={{ position: "relative" }}>
            <span style={colorStyle}>{text}</span>
            {record.status === "Success" ? (
              <CheckCircleTwoTone
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "1vw",
                  position: "absolute",
                  right: 1,
                  top: "15%",
                }}
                twoToneColor="#A4CD38"
              />
            ) : (
              <ExclamationCircleTwoTone
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "1vw",
                  position: "absolute",
                  right: 1,
                  top: "15%",
                }}
                twoToneColor="red"
              />
            )}
          </div>
        );
      },
    },
  ];

  const cashManagementColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "30%",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "30%",
    },
    {
      title: "Reasons",
      dataIndex: "note",
      key: "note",
      width: "33%",
    },
    {
      title: "Transactions(Rs)",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text, record) => {
        return record.cashAddInFlag === true ? <span style={{ color: "red" }}>-{record.amount}</span> : record.amount;
      },
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const handleKeyvalue = async (key) => {
    if (key === "0") {
      setSelectedPaymentMethod({});
      setKeyValue("0");
      setSelectedKeys([]);
    }
    if (key === "1") {
      filterdParkedList?.map((item, index) => {
        item.status = "Parked";
        item.key = uuidv4().replace(/-/g, "").toUpperCase();
        item.customer = item.parkedCart.customer.name;
        item.qty = item.parkedCart.totalQty;
      });
      setKeyValue("1");
      setSelectedKeys([]);
    }
    if (key === "2") {
      setKeyValue("2");
      setSelectedKeys([]);
    }

    if (key === "3") {
      showOrderHistory();
      setKeyValue("3");
      setSelectedKeys([]);
    }

    if (key === "4") {
      setSyncLoad(true);
      let tillData = JSON.parse(localStorage.getItem("tillData"));
      const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
      setCart({ ...cart, customer: defaultCustomer });
      await processSync(tillData, setGlobalStore, "Incremental Sync");
      setSyncLoad(false);
      setGlobalStore({ percent: "0%" });
      setGlobalStore({ productCategoryPercentage: "0" });
      setGlobalStore({ productPercentage: "0" });
      setGlobalStore({ promotionPercentage: "0" });
      // window.location.reload();
    }

    if (key === "7") {
      setKeyValue("7");
      setSelectedKeys([]);
    }

    if (key === "parked-bill") {
      filterdParkedList?.map((item, index) => {
        item.status = "Parked";
        item.key = uuidv4().replace(/-/g, "").toUpperCase();
        item.customer = item.parkedCart.customer.name;
        item.qty = item.parkedCart.totalQty;
      });
    }
  };

  const retrieveParkedData = (record) => {
    if (cart.items.length > 0) {
      openDisplayParkedBillModal("retrieve", record);
    } else {
      selectParkedBill(record, "management");
    }
  };

  const retrieveLayaway = (record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Save Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            You can retrieve the bill later by selecting the 'Retrieve' option in Parked Bills.
            <br />
            Do you want to continue parking the bill?
          </div>
        ),
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          parkBill();
          upsertPOSLog(cart, "OPK", "Parked");
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setTimeout(() => {
              productSearchInput.focus();
            }, 600);
          }
          setTimeout(() => {
            // selectParkedBill(record, "management");
          }, 2000);
        },
        onCancel() {
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setTimeout(() => {
              productSearchInput.focus();
            }, 300);
          }
          // selectParkedBill(record, "management");
        },
      });
    } else {
      selectLayAwayOrder(record);
    }
  };

  const clearReturnCartData = (record) => {
    if (cart.items.length > 0) {
      Modal.confirm({
        title: "Clear The Cart Items ?",
        icon: <ExclamationCircleOutlined />,
        content: <div>Do you want to clear the bill?</div>,
        okText: "Yes",
        cancelText: "No",
        autoFocusButton: null,
        onOk() {
          let setAuthTokens;
          if (authHeaders && authHeaders.access_token) {
            setAuthTokens = authHeaders.access_token;
          }
          const serverUrl = process.env.REACT_APP_serverUrl;
          if (cart?.couponInput?.length > 0) {
            const couponsData = [...cart.couponInput];

            // Function to discard a coupon
            const discardCoupon = async (coupon) => {
              const discardCouponQuery = {
                query: `query {
                  discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
                    status
                    message
                  }
                }`,
              };
              try {
                const response = await Axios({
                  url: serverUrl,
                  method: "POST",
                  data: discardCouponQuery,
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${setAuthTokens}`,
                  },
                });
              } catch (error) {
                console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
              }
            };
            // Iterate over each coupon code in couponsData and make the API call
            couponsData.forEach((coupon) => {
              discardCoupon(coupon);
            });
          }
          if (giftCardData.length > 0) {
            let details = [];
            giftCardData.map((ele) => {
              details.push(`{
                cardNo: ${ele.number ? `"${ele.number}"` : null}
                type: "TO"
                referenceNo: "${cart.referenceId}"
                amount: ${ele.amount * -1}
                cardPin: ${data.pin ? `"${data.pin}"` : null}
                    }`);
            });
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation{
                              upsertGiftCardTransaction(giftCardTransactions:[${details}]){
                                  status
                                  message
                              }
                          }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${setAuthTokens}`,
              },
            });
          }
          let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
            ? JSON.parse(localStorage.getItem("orderTimeDetails"))
            : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
          orderTimeDetails = {
            ...orderTimeDetails,
            paymentStartTime: "",
            orderStartTime: "", // Update orderStartTime to current time
          };
          localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
          deleteCart(true, true);
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setTimeout(() => {
              productSearchInput.focus();
              setOpen(false);
            }, 500);
          }
        },
        onCancel() {
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setTimeout(() => {
              productSearchInput.focus();
              setOpen(false);
            }, 300);
          }
          // selectParkedBill(record, "management");
        },
      });
    }
  };

  // const handleExpand = (expanded, record) => {
  //   if (expanded) {
  //     selectProduct(record);
  //     // if(record)
  //     // if(record.isReturn){
  //     //   setIsReturn(true)
  //     // }else{
  //     //   setIsReturn(false)
  //     // }
  //     if (record.layAway === "Y") {
  //       setLayAwayFlag(true);
  //     } else {
  //       setLayAwayFlag(false);
  //     }
  //   } else {
  //     setSelectedKeys([]);
  //     console.log("Record unexpanded:", record);
  //   }
  // };
  // const [filterDrawer, setFilterDrawer] = useState(false);

  const onKeyPress = (button) => {
    // if (customerSearchInputRef?.current) {
    //   customerSearchInputRef.current.focus();
    // }
    const productSearchInput = document.getElementById("sm-product-search");
    if (button === "{bksp}") {
      if (keyboardType.searchCus) {
        setCustomerSearchInput((prev) => prev.slice(0, -1));
      }
      if (keyboardType.giftCardSearch) {
        setSearchTerm((prev) => prev.slice(0, -1));
      }
    }
    if (button === "{enter}") {
      setLayoutName("default");
      switch (true) {
        case keyboardType.parkedBill:
          // searchParkedBill();
          searchParkedBill(parkedBillSearchInput);
          break;
        case keyboardType.salesHistoryDoc:
          searchOrderHistory();
        // case keyboardType.salesHistoryCus:
        //   searchOrderHistory();
        //   break;
        case keyboardType.salesHistoryCus:
          if (keyboardType.salesHistoryCus && searchCustomersRef?.current) {
            const inputValue = searchHistoryInput;
            if (inputValue !== "") {
              searchOrderHistory("customer", inputValue, 0, dateRange?.length > 0);
            }
          }
          break;
        case keyboardType.product:
          onBarcodeInput(productSearchInput.value, true);
          // getSearchedProducts();
          break;
        case keyboardType.productSearch:
          handleSelectProduct();
        default:
          // Handle the default case or do nothing
          break;
      }
    } else if (button === "{number}") {
      setLayoutName("number");
    } else if (button === "{numbers}") {
      setLayoutName("numbers");
    } else if (button === "{caps}") {
      setLayoutName("default");
    } else if (button === "{small}") {
      setLayoutName("shift");
    } else if (button === "{shift}" || button === "{lock}") handleShift();
    // if (productSearchInput) {
    //   productSearchInput.focus();
    // };
  };

  const handleShift = () => {
    layoutName === "default" ? setLayoutName("shift") : setLayoutName("default");
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    if (keyboardType.product) {
      if (keyboardType.product) {
        if (showVirtualKeyboard) {
          const productSearchInput = document.getElementById("sm-product-search");
          if (productSearchInput) {
            setProductSearchInput(input);
            localStorage.setItem("productName", input);
            productSearchInput.focus();
          }

          if (productSearchInputRef.current) {
            productSearchInputRef.current.value = input;
          }
        } else {
          setProductSearchInput(input);
          localStorage.setItem("productName", input);
        }
      } else {
        setProductSearchInput(input);
        localStorage.setItem("productName", input);
      }
    } else if (keyboardType.parkedBill) {
      if (showVirtualKeyboard) {
        setParkedBillSearchInput(parkedBillSearchInput);
        parkedBillRef.setInput(input);
      } else {
        setParkedBillSearchInput(input);
      }
    } else if (keyboardType.salesHistoryCus) {
      changeOrderHistorySearchType("orderCustomerSearchKey");
      if (showVirtualKeyboard) {
        setSalesHistoryCustomerSearchInput(salesHistoryCustomerSearchInput);
        searchCustomersRef.setInput(input);
      } else {
        setSalesHistoryCustomerSearchInput(input);
      }
    } else if (keyboardType.salesHistoryDoc) {
      changeOrderHistorySearchType("orderDocumentNo");
      if (showVirtualKeyboard) {
        setSalesHistoryDocumentNoSearchInput(salesHistoryDocumentNoSearchInput);
        searchDocumentNoREf.setInput(input);
      } else {
        setSalesHistoryDocumentNoSearchInput(input);
      }
    } else {
      setParkedBillSearchInput(input);
    }

    if (input === "") {
      setFilterdParkedList(parkedList);
    }
  };

  // const onChangeInput = (event) => {
  //   const input = event.target.value;

  //   if (keyboardType.product) {
  //     if (showVirtualKeyboard) {
  //       const productSearchInput = document.getElementById("sm-product-search");
  //       if (productSearchInput) {
  //         const cursorPosition = productSearchInput.selectionStart;
  //         productSearchInput.value = input;
  //         productSearchInput.setSelectionRange(cursorPosition, cursorPosition);
  //         if (document.activeElement !== productSearchInput) {
  //           productSearchInput.focus();
  //         }
  //         setProductSearchInput(input);
  //         localStorage.setItem("productName", input);
  //       }
  //       if (productSearchInputRef.current) {
  //         productSearchInputRef.current.value = input;
  //       }
  //     } else {
  //       setProductSearchInput(input);
  //       localStorage.setItem("productName", input);
  //     }
  //   } else if (keyboardType.parkedBill) {
  //     // Handle for parkedBill keyboard type
  //     if (showVirtualKeyboard) {
  //       setParkedBillSearchInput(parkedBillSearchInput);
  //       parkedBillRef.setInput(input);
  //     } else {
  //       setParkedBillSearchInput(input);
  //     }
  //   } else if (keyboardType.salesHistoryCus) {
  //     // Handle for salesHistoryCus keyboard type
  //     changeOrderHistorySearchType("orderCustomerSearchKey");
  //     if (showVirtualKeyboard) {
  //       setSalesHistoryCustomerSearchInput(salesHistoryCustomerSearchInput);
  //       searchCustomersRef.setInput(input);
  //     } else {
  //       setSalesHistoryCustomerSearchInput(input);
  //     }
  //   } else if (keyboardType.salesHistoryDoc) {
  //     // Handle for salesHistoryDoc keyboard type
  //     changeOrderHistorySearchType("orderDocumentNo");
  //     if (showVirtualKeyboard) {
  //       setSalesHistoryDocumentNoSearchInput(salesHistoryDocumentNoSearchInput);
  //       searchDocumentNoREf.setInput(input);
  //     } else {
  //       setSalesHistoryDocumentNoSearchInput(input);
  //     }
  //   } else {
  //     // Default handler for other cases
  //     setParkedBillSearchInput(input);
  //   }

  //   // If input is empty, reset the filtered parked list
  //   if (input === "") {
  //     setFilterdParkedList(parkedList);
  //   }
  // };

  // useEffect(() => {
  //   setLayoutName("default");
  // }, [keyboardType]);

  const handleSelectProduct = async () => {
    await db.products.toArray().then((productsFetched) => {
      const lowerCaseSearchInput = productSearchInput.toLowerCase();
      const filteredProducts = productsFetched.filter(
        (product) =>
          product?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
          product?.batchIndex === lowerCaseSearchInput ||
          product?.upcIndex === lowerCaseSearchInput ||
          product?.value === lowerCaseSearchInput ||
          product?.upc === lowerCaseSearchInput
      );
      if (filteredProducts.length === 0) {
        {
          message.info(`${t("product_search_category_error")}`);
        }
      } else {
        setProductsList(filteredProducts);
      }
    });
  };

  useEffect(() => {
    if (orderHistoryDetails.length > 0) {
      const newArray = orderHistoryDetails.map((item) => {
        const isReturn = item.items.some((product) => product.isReturn);
        const isLayaway = posConfig.advanceLayaway === "Y" ? item.layAway === "Y" : item.layAway === "Y" && item.paid === 0;
        const isCancel = (item.total === 0 || item.total === "0.00") && item.totalQty === 0;
        const type = item.isReturn === "Y" ? "Return" : isLayaway ? "Layaway" : isCancel ? "Cancelled" : "Receipt";
        const newItem = { ...item, type };
        return newItem;
      });
      if (!arraysAreEqual(newArray, orderHistoryDetails)) {
        setOrderHistoryDetails(newArray);
      }
    }
  }, [orderHistoryDetails]);
  useEffect(() => {
    const notesValueInput = document.getElementById("notes-input");
    if (notesPopup) {
      notesValueInput.focus();
    } else {
      notesValueInput?.blur(); // Remove focus
      setIsInputFocused(false);
    }
  }, [notesPopup]);
  // Helper function to check if two arrays are equal
  function arraysAreEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  const wareHouse = JSON.parse(localStorage.getItem("tillData"));
  const tillUnlink = async () => {
    let parkedBills = JSON.parse(localStorage.getItem("parkedList"));
    let posConfig = JSON.parse(localStorage.getItem("posConfig"));
    //  console.log(cart.items.length)
    const orders = await db.orders.where("isSynced").equals(0).toArray();
    //  console.log(orders)
    if (posConfigData?.posType === "PRD") {
      const unlinkTillAPI = async () => {
        try {
          let id = tillData.tillAccess.cwrTill.cwrTillID;

          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `mutation {
                unlinkTill(tillId: "${id}") {
                  status
                  message
                } 
              }`,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          });

          if (response.data.data.unlinkTill.status === "200") {
            const keepKey = "";
            localStorage.clear();
            await Promise.all(
              db.tables.map(async (table) => {
                if (table.name !== keepKey) {
                  await table.clear();
                }
              })
            );
          }

          localStorage.removeItem("unlink");
          window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
        } catch (error) {
          console.error("Error in unlinkTillAPI:", error);
        }
      };
      await unlinkTillAPI();
    }
    if (parkedBills?.length > 0) {
      {
        message.error(`${t("unlink_till_parked_bill_error")}`);
      }
    } else if (cart?.items?.length > 0) {
      {
        message.error(`${t("unlink_till_cart_error")}`);
      }
    } else if (orders?.length > 0) {
      message.error("To unlink the Till please sync pending Orders from Order History");
    } else {
      // If none of the conditions is satisfied, you can provide a default message
      if (posConfig.shiftClose === "Y") {
        if (posConfig.showDenominations === "Y") {
          history.push("/close-till");
          localStorage.setItem("unlink", true);
        } else if (posConfig.showDenominations === "N") {
          history.push("/close-till-all");
          localStorage.setItem("unlink", true);
        }
      } else {
        history.push("/");
      }
    }
  };

  const handleLogin = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());

    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }

    // }
    // localStorage.removeItem("tillSession")
    localStorage.setItem("locked", isLocked);
    const redirectWithIsLocked = `${SSOURL}sign-in/?redirect_uri=${redirectUrl}&username=${authHeaders.username}&readOnly=true`;
    window.location.assign(redirectWithIsLocked);
  };
  const errorMessage = (modalTitle, modalContent, user) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
    });
  };
  const handleButtonClick = () => {
    setPopoverVisible(!isPopoverVisible);
  };
  const getDisplayDate = () => {
    if (dateRange && dateRange.length === 2) {
      const [start, end] = dateRange;
      return `${start.format("DD-MM-YYYY")} to ${end.format("DD-MM-YYYY")}`;
    }
    return "Choose date range";
  };

  const content = (
    <RangePicker
      open={isPopoverVisible}
      allowEmpty
      id="sm-salesHistory-date"
      onPressEnter={searchOrderHistory}
      allowClear={true}
      format={"DD-MM-YYYY"}
      suffixIcon={null}
      separator={null}
      value={dateRange}
      onChange={(e) => {
        if (e?.length > 0) {
          setFilterdDate(null);
          setPopoverVisible(false);
          setDateRange(e);
          localStorage.setItem("dataLength", 0);
          searchOrderHistory("orderDateSearchKey", e, 0, searchHistoryInput !== "" ? true : false, false);
        } else if (e === null && searchHistoryInput === "") {
          setFilterdDate(null);
          setDateRange(e);
          setOrderHistoryDetails([]);
          db.orders
            .orderBy("orderTime")
            .limit(20)
            .reverse()
            .toArray()
            .then((data) => {
              // Your logic to handle data here
            });
        } else if (e === null && searchHistoryInput !== "") {
          setDateRange(e);
          setFilterdDate(null);
          setOrderHistoryDetails([]);
          searchOrderHistory("customer", searchHistoryInput, 0, false);
        }
      }}
      className="salesHistory-datePicker-sm"
    />
  );

  const handleCancel = () => {
    // Handle the cancel action here
    Modal.destroyAll();
    const productSearchInput = document.getElementById("sm-product-search");
    if (productSearchInput) {
      setTimeout(() => {
        productSearchInput.focus();
      }, 300);
    }
  };

  const handleOk = (record) => {
    let setAuthTokens;
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    discardGiftCard(record.parkedCart);
    if (record?.parkedCart?.couponInput?.length > 0) {
      const serverUrl = process.env.REACT_APP_serverUrl;

      const couponsData = [...record.parkedCart.couponInput];
      // Function to discard a coupon
      const discardCoupon = async (coupon) => {
        const discardCouponQuery = {
          query: `query {
            discardCoupon(couponcode: "${coupon.couponCode}", referenceId: "${coupon.referenceId}") {
              status
              message
            }
          }`,
        };
        try {
          const response = await Axios({
            url: serverUrl,
            method: "POST",
            data: discardCouponQuery,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${setAuthTokens}`,
            },
          });
        } catch (error) {
          console.error(`Error discarding coupon ${coupon.couponCode}:`, error);
        }
      };
      couponsData.forEach((coupon) => {
        discardCoupon(coupon);
      });
    }
    let index = record.parkedCart.payments.findIndex((payment) => payment.name.toLowerCase() === "loyalty" && !record.parkedCart.isReturn);
    if (index >= 0) {
      handleLoyaltyPayment(record.parkedCart.payments[index], record.parkedCart);
    }
    setGiftCardData([]);
    discardParkedBill(record);
    const productSearchInput = document.getElementById("sm-product-search");
    if (productSearchInput) {
      setTimeout(() => {
        productSearchInput.focus();
      }, 300);
    }
  };

  const discardMessage = (modalTitle, modalContent, record) => {
    Modal.confirm({
      title: modalTitle,
      content: modalContent,
      onOk: () => handleOk(record),
      autoFocusButton: null,
      onCancel: handleCancel,
    });
  };
  let reconstructedObject;
  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;
    const printObject = rawDataArray.find((item) => item.key === "Print");
    const printObjectValue = printObject?.value;
    if (printObjectValue !== undefined) {
      const cleanedData = printObjectValue.replace(/[{}"]/g, "");

      const keyValuePairs = cleanedData.split(",");

      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      console.error("printObjectValue is undefined");
    }
  }

  const handleExpand = (record) => {
    const nextExpandedKeys = [...expandedRowKeys];
    const index = nextExpandedKeys.indexOf(record.key);
    if (index > -1) {
      nextExpandedKeys.splice(index, 1); // Collapse row if already expanded
    } else {
      nextExpandedKeys.push(record.key); // Expand row if not expanded
    }
    setExpandedRowKeys(nextExpandedKeys);
  };

  const onAddNotes = () => {
    let updatedCart;
    // console.log("notesValue--------",notesValue)
    if (selectedProductForNotes !== null && cart.items.length > 0) {
      // console.log("notesValue--------",selectedProductForNotes)
      updatedCart = { ...cart };
      let index = updatedCart.items.findIndex((res) => res.lineId === selectedProductForNotes.lineId);
      if (index >= 0) {
        updatedCart.items[index].notes = notesValue;
      }
      setNotesValue();
    } else {
      updatedCart = {
        ...cart,
        description: notesValue,
      };
      // setNotesValue()
    }
    setSelectedProductForNotes(null);
    setCart(updatedCart);
    localStorage.setItem("cartObj", JSON.stringify(updatedCart));
    setNotesPopup(false);
    setIsInputFocused(false);
    let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails"))
      ? JSON.parse(localStorage.getItem("orderTimeDetails"))
      : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    if (updatedCart.items.length === 0 || tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId === updatedCart.customer.cwrCustomerId) {
      orderTimeDetails = {
        ...orderTimeDetails,
        paymentStartTime: "",
        orderStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
      };
    } else if (updatedCart.items.length > 0 || tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId !== updatedCart.customer.cwrCustomerId) {
      orderTimeDetails = {
        ...orderTimeDetails,
        paymentStartTime: "",
      };
    }
    if (updatedCart.description === "") {
      orderTimeDetails = {
        ...orderTimeDetails,
        paymentStartTime: "",
        orderStartTime: "",
      };
    }
    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior (new line)
      onAddNotes(); // Call your custom function to add notes
    }
  };

  const onChange = (input) => {
    if (input !== "Enter") {
      setNotesValue(input);
    }
  };

  // const keyboardRef = useRef(null);

  const onChangeInputKeyboard = (event) => {
    const input = event.target.value;
    setNotesValue(input);

    if (keyboardRef.current) {
      keyboardRef.current.setInput(input);
    }
  };

  let barcodeFlag = false;
  const inputElement = document.getElementById("sm-customer-search");
  const amountInput = document.getElementById("sm-amount-input");
  const totalAmountInout = document.getElementById("sm-total-amount-input");
  const inputFocused = inputElement === document.activeElement || amountInput === document.activeElement || totalAmountInout === document.activeElement;
  const approvalFlag = localStorage.getItem("approvalFlag") ? localStorage.getItem("approvalFlag") : "1";
  if (
    !couponModalVisible &&
    !displayAddNewCustomer &&
    !validateGiftCard &&
    !displayCustomerSearch &&
    !displayEditOldCustomer &&
    !giftCardFlag &&
    !filterDrawer &&
    !isGiftCardFlag &&
    !manualDiscountModalVisible &&
    !loyalityOtpModalVisible &&
    !inputFocused &&
    approvalFlag === "1"
  ) {
    barcodeFlag = true;
  } else {
    barcodeFlag = false;
  }

  const onFinish = async (values) => {
    let updatedCart;
    console.log("Form values:", values);
    const returnSummary = {
      returnReferenceNo: values.referenceNo,
      returnRemarks: values.remarks,
    };

    updatedCart = {
      ...cart,
      returnSummary,
      returnReasonId: values.reason,
    };
    setCart(updatedCart);
    localStorage.setItem("cartObj", JSON.stringify(updatedCart));
    await handleCartTotalClick();
    setReasonPopup(false);
  };

  const mainDivStyles = themeJSON.appBgImageURL
    ? { backgroundImage: `url(${themeJSON.appBgImageURL})`, height: "100vh", width: "100%", display: isLocked ? "none" : "block" }
    : { backgroundColor: colorVariable ? colorVariable : "#f3f4f9", height: "100vh", width: "100%", display: isLocked ? "none" : "block" };
  document.querySelectorAll("amt-dial-btn-market").forEach((element) => {
    element.style.backgroundColor = themeJSON.keyBoardColor;
  });

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      {isParkedBill ? (
        <div
          style={{
            background: colorVariable ? colorVariable : "#f3f4f9",
            height: "100vh",
          }}
        >
          <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
            <Col span={6}>
              <img
                src={Back}
                id="sm-parked-bill-back"
                onClick={() => {
                  setIsparkedBill(false);
                  setIsInputFocused(false);
                  setParkedBillSearchInput("");
                  setLayoutName("default");
                }}
                // style={{ height: "3vw", paddingBottom: "0.5em", cursor: "pointer" }}
                style={{
                  height: "3vw",
                  paddingBottom: "0.7em",
                  cursor: "pointer",
                }}
              />
              <span
                style={{
                  paddingRight: "1vw",
                  fontSize: "1.8em",
                  fontWeight: 500,
                  margin: "0.5em",
                  paddingTop: "0.5em",
                  color: "#0F0718",
                }}
              >
                {/* <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} /> */}
                Park Bills
              </span>
            </Col>
            <Col
              span={18}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Input
                placeholder={`Search for customers`}
                ref={parkedBillRef}
                prefix={<img src={CustomerSearch} style={{ width: "1.5em" }} alt="" />}
                // size="middle"
                allowClear={false}
                style={{ width: "70%", borderRadius: "5px", border: "none" }}
                onPressEnter={() => {
                  searchParkedBill(parkedBillSearchInput);
                }}
                onChange={onChangeInput}
                id="sm-parked-bill-customer"
                onFocus={() => {
                  setKeyboardType({
                    product: false,
                    parkedBill: true,
                    salesHistoryDoc: false,
                    salesHistoryCus: false,
                    searchCus: false,
                    productSearch: false,
                    giftCardSearch: false,
                    addCustomer: false,
                  });
                  setOpen(true);
                }}
                onBlur={() => {
                  setIsInputFocused(false);
                }}
                value={parkedBillSearchInput}
                className="parkedCustomer-sm"
                width={700}
              />
            </Col>
          </Row>
          <br />
          {/* <Row style={{ paddingBottom: "3vh" }}>
        
          <Col span={13} style={{ display: "flex", justifyContent: "end" }}>
          <span style={{ position: "absolute", bottom: 0, paddingRight: "1vw", color: "#A4CD38", fontSize: "1.1vw" }}>
            <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} />
            Export list
          </span>
          </Col>
          </Row> */}
          <Row style={{ padding: "0 1em" }}>
            <Table
              style={{
                width: "100%",
                overflowY: "auto",
                border: "none",
                fontSize: "1vw",
                borderRadius: "10px",
                padding: "0 1em",
                height: "83vh",
                background: themeJSON.productTableBGColor || "#fff",
                //   boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
              }}
              pagination={false}
              columns={columns}
              dataSource={filterdParkedList}
              // scroll={{ y: "53vh" }}
              rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
              className="sm-cart-table"
              expandable={{
                expandedRowKeys,
                onExpand: handleExpand,
                expandedRowRender: (record) => (
                  <div
                    style={{
                      padding: "1% 4% 1% 4%",
                      backgroundColor: "#f3f4f9",
                    }}
                  >
                    <Row>
                      <Col span={21}>
                        <Row>
                          <Col span={12} className="td-nested-title-sm">
                            Description
                          </Col>
                          <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                            Qty
                          </Col>
                          <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                            Unit Price
                          </Col>
                          <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
                            Net Total
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "0.5vw", fontSize: "1vw" }}>
                          {record.parkedCart.items.map((e) => {
                            return (
                              <>
                                {/* <Col span={12} className="td-nested-description-sm"> */}
                                <Col span={12} className="td-nested-title-sm" style={{ fontSize: "1.2em" }}>
                                  {e.name}
                                </Col>
                                <Col
                                  span={3}
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "400",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  {e.weight}
                                </Col>
                                <Col span={1} style={{ textAlign: "center" }} />
                                <Col
                                  span={3}
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "400",
                                    paddingRight: "1vw",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  {e.realPrice}
                                </Col>
                                <Col span={1} style={{ textAlign: "right" }} />
                                <Col
                                  span={3}
                                  style={{
                                    textAlign: "right",
                                    marginLeft: "6vw",
                                    fontWeight: "400",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  {e.nettotal.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)}
                                </Col>
                              </>
                            );
                          })}
                        </Row>
                        <p
                          style={{
                            width: "96%",
                            marginLeft: "0%",
                            borderTop: "1.3px solid rgba(52, 40, 44, 0.55)",
                            marginBottom: "0px",
                          }}
                        />
                        <Row style={{ paddingTop: "5px" }}>
                          <Col span={3} offset={17} className="td-subtotal">
                            <span className="td-subTotalText">Subtotal</span>
                            <span className="td-subTotalText">Total tax</span>
                          </Col>
                          <Col span={3} className="td-subtotal" style={{ textAlign: "right" }}>
                            <span className="td-subTotalText">{(record.parkedCart.total - record.parkedCart.tax).toFixed(2)}</span>
                            <span className="td-subTotalText">{record.parkedCart.tax.toFixed(2)}</span>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "1%" }}>
                          <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                            <span className="td-total">SALE TOTAL</span>
                          </Col>
                          <Col
                            span={3}
                            className="td-subtotal"
                            style={{
                              borderBottom: "1px solid rgba(52, 40, 44, 0.55)",
                              textAlign: "right",
                              paddingTop: "4px",
                            }}
                          >
                            <span className="td-total">{record.parkedCart.total}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={3} className="td-bottons">
                        <p
                          style={{
                            cursor: "pointer",
                            padding: "5.5% 12%",
                            borderRadius: "3px",
                            border: "1px solid #fff",
                            background: "#fff",
                            fontSize: "1em",
                          }}
                          onClick={() => {
                            setTableValue("POS");
                            setIsparkedBill(false);
                            retrieveParkedData(record);
                            upsertPOSLog(cart, "OPK", "Retrieved");
                          }}
                          id="sm-parked-bill-retrieve"
                        >
                          <img
                            src={Retrieve}
                            style={{
                              height: "1vw",
                              paddingRight: "0.4vw",
                              cursor: "pointer",
                            }}
                          />
                          Retrieve sale
                        </p>
                        <p
                          style={{
                            cursor: "pointer",
                            padding: "5.5% 13%",
                            borderRadius: "3px",
                            border: "1px solid #fff",
                            background: "#fff",
                            fontSize: "1em",
                          }}
                          onClick={() => {
                            setParkedBill(record);
                            discardMessage("Discard Sale", "Are you sure you want to discard the sale!", record);
                          }}
                          id="sm-parked-bill-discard"
                        >
                          <img src={Cancel} style={{ height: "1.1vw", paddingRight: "0.4vw" }} />
                          Discard sale
                        </p>
                      </Col>
                    </Row>
                  </div>
                ),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <span id="sm-parked-bill-expand" onClick={(e) => onExpand(record, e)}>
                    {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                  </span>
                ),
              }}
              onRow={(record) => ({
                onClick: () => handleExpand(record), // Handle row click to expand
              })}
              onExpand={handleExpand}
            />
          </Row>
        </div>
      ) : isCashMangement ? (
        <div
          style={{
            background: colorVariable ? colorVariable : "#f3f4f9",
            height: "100vh",
            fontSize: "1vw",
          }}
        >
          <Row style={{ padding: "1.5em 1.5em 0 1.5em" }}>
            <Col span={6}>
              <img
                src={Back}
                id="sm-cash-management-back"
                onClick={() => {
                  setIsCashManagement(false);
                }}
                style={{
                  height: "3.1vw",
                  paddingBottom: "0.7em",
                  cursor: "pointer",
                }}
              />
              <span
                style={{
                  paddingRight: "1vw",
                  color: "#0F0718",
                  fontSize: "1.8em",
                  fontWeight: 500,
                  marginLeft: "0.5em",
                }}
              >
                {/* <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} /> */}
                Cash Management
              </span>
            </Col>
            <Col
              span={18}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  width: "10.3vw",
                  height: "5.5vh",
                  // border: "1px solid #0F0718",
                  borderRadius: "4px",
                  fontWeight: "500",
                  marginRight: "1.4vw",
                  fontSize: "1em",
                  color: "#fff",
                  background: "#2F3856",
                }}
                id="sm-cash-management-remove"
                onClick={() => {
                  setAddCashFlag(true);
                  setCashAddInFlag(true);
                }}
              >
                Remove Cash
              </Button>
              <Button
                id="sm-cash-management-add"
                style={{
                  width: "10.3vw",
                  height: "5.5vh",
                  borderRadius: "4px",
                  fontWeight: "500",
                  fontSize: "1em",
                  color: "#fff",
                  background: "#2F3856",
                }}
                onClick={() => {
                  setAddCashFlag(true);
                  setCashAddInFlag(false);
                }}
              >
                Add Cash
              </Button>
            </Col>
          </Row>
          <br />
          <Row style={{ padding: "0 1.5em" }}>
            <Table
              style={{
                width: "100%",
                overflowY: "auto",
                border: "none",
                fontSize: "1.5vw",
                borderRadius: "10px",
                padding: "0 1em",
                height: "83vh",
                background: themeJSON.productTableBGColor || "#fff",
                // boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
              }}
              pagination={false}
              columns={cashManagementColumn}
              className="sm-cashmanagement-table"
              // className="sm-cart-table"
              dataSource={cashManagementData}
            />
          </Row>
          <RetailModals {...props} selectBagRef={selectBagRef} />
        </div>
      ) : isQuotation ? (
        // <div
        //   style={{
        //     background: colorVariable ? colorVariable : "#f3f4f9",
        //     height: "100vh",
        //   }}
        // >
        //   {!showReturnPurchase ? (
        //     <>
        //       <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
        //         <Col span={6}>
        //           <img
        //             src={Back}
        //             id="sm-salesHistory-back"
        //             onClick={() => {
        //               setIsQuotation(false)
        //               setOrderHistoryDetails([]);
        //               setExpandedRowKeys([]);
        //               setDateRange([]);
        //               setFiltersFlag(false);
        //               setIsInputFocused(false);
        //               setSearchhistoryInput("");

        //             }}
        //             style={{
        //               height: "3vw",
        //               paddingBottom: "0.7em",
        //               cursor: "pointer",
        //             }}
        //           />
        //           <span
        //             style={{
        //               paddingRight: "1vw",
        //               color: "#0F0718",
        //               fontSize: "1.8em",
        //               fontWeight: 500,
        //               marginLeft: "0.5em",
        //             }}
        //           >
        //             Sales Quotation
        //           </span>
        //         </Col>
        //         <Col
        //           span={8}
        //           offset={2}
        //           style={{
        //             justifyContent: "center",
        //             display: "flex",
        //             alignItems: "center",
        //           }}
        //         >
        //           <Input
        //             ref={searchCustomersRef}
        //             placeholder="Search for Customers/Document Number/Contact"
        //             prefix={<img src={CustomerSearch} style={{ width: "1.4em" }} alt="" />}
        //             allowClear={false}
        //             onPressEnter={(e) => {
        //               if (e.target.value !== "") {
        //                 searchOrderHistory("customer", e.target.value, 0, dateRange?.length > 0 ? true : false);
        //               }
        //             }}
        //             onBlur={() => {
        //               setIsInputFocused(false);
        //             }}
        //             value={searchHistoryInput}
        //             id="sm-salesHistory-customer"
        //             onFocus={() => {
        //               setOpen(true);
        //               setIsInputFocused(true);
        //               setKeyboardType({
        //                 product: false,
        //                 parkedBill: false,
        //                 salesHistory: false,
        //                 salesHistoryCus: true,
        //               });
        //             }}
        //             className="salesHistory-input-sm"
        //             onChange={async (e) => {
        //               setSearchhistoryInput(e.target.value);
        //               if (e.target.value === "" && dateRange?.length === 0) {
        //                 setOrderHistoryDetails([]);
        //                 db.orders
        //                   .orderBy("orderTime")
        //                   .limit(20)
        //                   .reverse()
        //                   .toArray()
        //                   .then((data) => {
        //                   });
        //               } else if (e.target.value === "" && dateRange?.length !== 0) {
        //                 await setSearchhistoryInput("");
        //                 searchOrderHistory("orderDateSearchKey", dateRange, 0, false);
        //               }
        //             }}
        //           />
        //         </Col>

        //         <Col
        //           span={8}
        //           style={{
        //             paddingRight: "",
        //             paddingLeft: "1.5vw",
        //             justifyContent: "space-between",
        //             display: "flex",
        //             alignItems: "center",
        //           }}
        //         >
        //           <Popover content={content} trigger="click" visible={isPopoverVisible} onVisibleChange={(visible) => setPopoverVisible(visible)}>
        //             <Button
        //               icon={<CalendarOutlined />}
        //               style={{
        //                 border: "none",
        //                 width: "27vw",
        //                 display: "flex",
        //                 alignItems: "center",
        //                 color: "#929098",
        //                 fontWeight: 400,
        //                 fontSize: "1em",
        //                 fontFamily: "Inter",
        //               }}
        //               onClick={handleButtonClick}
        //               ref={dateRef}
        //             >
        //               {getDisplayDate()}
        //             </Button>
        //           </Popover>

        //           {filtersFlag ? (
        //             <Button
        //               onClick={() => {
        //                 setOrderHistoryDetails(ordersCopy);
        //                 setFiltersFlag(false);
        //                 setDateRange([]);
        //                 setSearchhistoryInput("");
        //                 setDateRange(null);
        //               }}
        //               style={{ border: "none", padding: "4px 10px" }}
        //             >
        //               <img src={clearFilters} alt="clear" />
        //             </Button>
        //           ) : null}
        //         </Col>
        //       </Row>
        //       <br />
        //       <Row style={{ padding: "0 1.5em" }}>
        //         <Table
        //           className="sm-cart-table"
        //           ref={tableBodyRef}
        //           style={{
        //             width: "100%",
        //             overflowY: "auto",
        //             border: "none",
        //             fontSize: "1.5vw",
        //             borderRadius: "7px",
        //             height: "84vh",
        //             padding: "0 1em",
        //             background: themeJSON.productTableBGColor || "#fff",
        //             boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
        //           }}
        //           onRow={(record) => ({
        //             onClick: () => handleExpand(record),
        //           })}
        //           pagination={false}
        //           columns={orderHistoryColumns}
        //           dataSource={orderHistoryDetails}
        //           rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
        //           onExpand={handleExpand}
        //           expandable={{
        //             expandedRowKeys,
        //             onExpand: handleExpand,
        //             expandedRowRender: (record) => (
        //               <div
        //                 style={{
        //                   padding: "1% 4% 1% 4%",
        //                   backgroundColor: "#f3f4f9",
        //                 }}
        //               >
        //                 <Row>
        //                   <Col span={21}>
        //                     <Row>
        //                       <Col span={12} className="td-nested-title-sm">
        //                         Description
        //                       </Col>
        //                       <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
        //                         Qty
        //                       </Col>
        //                       <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "center" }}>
        //                         Unit Price
        //                       </Col>
        //                       <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
        //                         Net Total
        //                       </Col>
        //                     </Row>
        //                     <Row style={{ paddingBottom: "0.5vw" }}>
        //                       {record.items.map((e) => {
        //                         return (
        //                           <>
        //                             <Col span={12} className="td-nested-description">
        //                               {e.name}
        //                             </Col>
        //                             <Col
        //                               span={3}
        //                               style={{
        //                                 textAlign: "center",
        //                                 fontWeight: "400",
        //                               }}
        //                             >
        //                               {e.weight}
        //                             </Col>
        //                             <Col
        //                               span={1}
        //                               style={{
        //                                 textAlign: "center",
        //                                 fontWeight: "400",
        //                               }}
        //                             />
        //                             <Col
        //                               span={3}
        //                               style={{
        //                                 textAlign: "right",
        //                                 fontWeight: "400",
        //                               }}
        //                             >
        //                               {e && typeof e.salePrice === "number" && e.salePrice.toFixed(2)}
        //                             </Col>
        //                             <Col span={2} style={{ textAlign: "center" }} />
        //                             <Col
        //                               span={3}
        //                               style={{
        //                                 textAlign: "right",
        //                                 fontWeight: "400",
        //                               }}
        //                             >
        //                               {e.nettotal.toFixed(2)}
        //                             </Col>
        //                           </>
        //                         );
        //                       })}
        //                     </Row>
        //                     <p
        //                       style={{
        //                         width: "96%",
        //                         marginLeft: "0%",
        //                         borderTop: "1.3px solid rgba(52, 40, 44, 0.55)",
        //                         marginBottom: "0px",
        //                       }}
        //                     />
        //                     <Row style={{ paddingTop: "5px" }}>
        //                       <Col span={14}>
        //                         {record?.paymentData?.length > 0 && (
        //                           <Row>
        //                             <Col span={8} style={{ textAlign: "left" }}>
        //                               <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Payment method</p>
        //                             </Col>
        //                             <Col span={5}>
        //                               <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Amount</p>
        //                             </Col>
        //                             <Col span={8}>
        //                               <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Advance Payment</p>
        //                             </Col>
        //                           </Row>
        //                         )}

        //                         {record?.paymentData?.map((ele, ind) => (
        //                           <Row>
        //                             <Col span={8} style={{ textAlign: "left" }}>
        //                               {ele.name}
        //                             </Col>
        //                             <Col span={5}>{ele.amount}</Col>
        //                             {ind === 0 && <Col span={5}>{record.advancePayment > 0 && record.layAway === "Y" ? record.advancePayment : 0}</Col>}
        //                           </Row>
        //                         ))}
        //                       </Col>
        //                       <Col span={3} offset={3} className="td-subtotal">
        //                         <span className="td-subTotalText">Subtotal</span>
        //                         <span className="td-subTotalText">Total tax</span>
        //                       </Col>
        //                       <Col span={3} className="td-subtotal" style={{ textAlign: "right" }}>
        //                         <span className="td-subTotalText">{(record.total - record.tax).toFixed(2)}</span>
        //                         <span className="td-subTotalText">{record.tax.toFixed(2)}</span>
        //                       </Col>
        //                     </Row>
        //                     <Row style={{ marginBottom: "1%" }}>
        //                       <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
        //                         <span className="td-total">SALE TOTAL</span>
        //                       </Col>
        //                       <Col
        //                         span={3}
        //                         style={{
        //                           fontSize: "1em",
        //                           borderBottom: "1px solid rgba(52, 40, 44, 0.55)",
        //                           textAlign: "right",
        //                           paddingTop: "4px",
        //                         }}
        //                       >
        //                         <span className="td-total">{parseFloat(record.total).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)}</span>
        //                       </Col>
        //                     </Row>
        //                   </Col>
        //                   <Col span={3} className="td-bottons" style={{ fontSize: "1vw" }}>

        //                     <p
        //                       id="sm-salesHistory-printer"
        //                       style={{
        //                         cursor: "pointer",
        //                         padding: "5.5% 7%",
        //                         borderRadius: "5px",
        //                         border: "1px solid #92909880",
        //                         backgroundColor: "#fff",
        //                         fontSize: "1em",
        //                         fontWeight: 500,
        //                         color: "#0F0718",
        //                       }}

        //                     >
        //                       <img
        //                         src={printerIcon}
        //                         style={{
        //                           height: "1.4vw",
        //                           paddingRight: "0.4vw",
        //                           cursor: "pointer",
        //                         }}
        //                       />
        //                       Print Quotation
        //                     </p>

        //                     <p
        //                       style={{
        //                         cursor: "pointer",
        //                         padding: "5.5% 13%",
        //                         borderRadius: "5px",
        //                         border: "1px solid #92909880",
        //                         backgroundColor: "#fff",
        //                         fontSize: "1em",
        //                         fontWeight: 500,
        //                         color: "#0F0718",
        //                       }}
        //                       id="sm-sales-return"
        //                       onClick={() => {
        //                         const isAlreadyParked = filterdParkedList.some((item) => item.parkedOrderID === record.sOrderID);
        //                         if (isAlreadyParked) {
        //                           Modal.error({
        //                             title: "This return order is already parked. Please access it from the bill parking area to continue.",
        //                           });
        //                         } else {
        //                           prevHistoryRef.current = orderHistoryDetails;
        //                           setCheckedKeys([]);
        //                           searchReturnHistory(record.documentno, record);
        //                           setOrderHistoryDetails([]);
        //                           localStorage.setItem("salesReturn", "Y");
        //                         }
        //                       }}
        //                     >
        //                       <img
        //                         src={CreateOrderIcon}
        //                         style={{
        //                           height: "1.1vw",
        //                           paddingRight: "0.4vw",
        //                         }}
        //                       />
        //                       Create Order
        //                     </p>

        //                     <p
        //                       style={{
        //                         cursor: "pointer",
        //                         padding: "5.5% 28%",
        //                         borderRadius: "5px",
        //                         border: "1px solid #92909880",
        //                         backgroundColor: "#fff",
        //                         fontSize: "1em",
        //                         fontWeight: 500,
        //                         color: "#0F0718",
        //                       }}
        //                       id="sm-sales-return"
        //                       onClick={() => setRejectQuotation(true)}

        //                     >
        //                       <img
        //                         src={RejectIcon}
        //                         style={{
        //                           height: "1.1vw",
        //                           paddingRight: "0.4vw",
        //                           marginBottom:"0.1rem"
        //                         }}
        //                       />
        //                       Reject
        //                     </p>
        //                   </Col>
        //                 </Row>
        //               </div>
        //             ),
        //             rowExpandable: (record) => record.name !== "Not Expandable",
        //             expandIcon: ({ expanded, onExpand, record }) => (
        //               <span id="sm-salesHistory-expand" onClick={(e) => onExpand(record, e)}>
        //                 {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
        //               </span>
        //             ),
        //           }}
        //         />
        //       </Row>
        //     </>
        //   ) : (
        //     <>
        //       <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
        //         <Col span={6}>
        //           <img
        //             src={Back}
        //             id="sm-salesReturn"
        //             onClick={() => {
        //               setOrderHistoryDetails(ordersCopy);
        //               setShowReturnPurchase(!showReturnPurchase);
        //               setSearchhistoryInput("");
        //               setDateRange("");
        //               setFiltersFlag(false);
        //             }}
        //             style={{
        //               height: "3vw",
        //               paddingBottom: "0.7em",
        //               cursor: "pointer",
        //             }}
        //           />
        //           <span
        //             style={{
        //               paddingRight: "1vw",
        //               color: "",
        //               fontSize: "1.8em",
        //               fontWeight: 500,
        //               marginLeft: "0.5em",
        //               color: "#0F0718",
        //             }}
        //           >
        //             Create Order
        //           </span>
        //         </Col>
        //       </Row>
        //       <br />
        //       <Row style={{ padding: "0 1.5em 1.5em 1.5em", fontSize: "1vw" }}>
        //         <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
        //           Document No: {selectedReturnOrder.documentno}
        //         </Col>
        //         <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
        //           Date : {selectedReturnOrder.dateordered}
        //         </Col>
        //         <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
        //           Total : {selectedReturnOrder.grosstotal}
        //         </Col>
        //         <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
        //           Discount : {parseFloat(selectedReturnOrder.discAmount).toFixed(2)}
        //         </Col>
        //       </Row>
        //       <Row style={{ padding: "0 1.5em", fontSize: "1vw" }}>
        //         <Table
        //           className="sm-cart-table-return"
        //           style={{
        //             width: "100%",
        //             overflowY: "auto",
        //             border: "none",
        //             fontSize: "1.1vw",
        //             borderRadius: "7px",
        //             height: "43vh",
        //             padding: "0 1em",
        //             background: themeJSON.productTableBGColor || "#fff",
        //             boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
        //           }}
        //           pagination={false}
        //           dataSource={dataSource}
        //           components={{
        //             body: {
        //               row: EditableRow,
        //               cell: EditableCell,
        //             },
        //           }}
        //           setModalEditState={setModalEditState}
        //           setIsInputFocused={setIsInputFocused}
        //           columns={columns1.map((col) => ({
        //             ...col,
        //             onCell: (record) => ({
        //               record,
        //               editable: col.dataIndex === "qtyToReturn" && record.product.returnable,
        //               dataIndex: col.dataIndex,
        //               title: col.title,
        //               handleSave: handleSave,
        //               setModalEditState: setModalEditState,
        //               setIsInputFocused: setIsInputFocused,
        //             }),
        //           }))}
        //         />
        //       </Row>
        //       <Row style={{ paddingTop: "2vh" }}>
        //         <Col span={9}>
        //         </Col>
        //         <Col span={4} />
        //         <Col span={11}>
        //           <Row style={{ fontSize: "1vw" }}>
        //             <Col span={11} offset={1}>
        //               <p style={styles.screenStyles.returnPurchase.returnItems}>Return Items / Qty: </p>
        //               <p style={styles.screenStyles.returnPurchase.returnAmount}>Return Amount Total:</p>
        //             </Col>
        //             <Col span={11} style={{ textAlign: "right" }}>
        //               <p style={styles.screenStyles.returnPurchase.returnItems}>
        //                 {" "}
        //                 {selectedReturnOrder.itemsToReturn ? selectedReturnOrder.itemsToReturn : "0"} /{" "}
        //                 {selectedReturnOrder.itemsQtyToReturn ? parseFloat(selectedReturnOrder.itemsQtyToReturn).toFixed(2) : "0.00"}
        //               </p>
        //               <p style={styles.screenStyles.returnPurchase.returnAmount}>
        //                 {" "}
        //                 {selectedReturnOrder.returnAmountTotal ? parseFloat(selectedReturnOrder.returnAmountTotal).toFixed(2) : "0.00"}
        //               </p>
        //             </Col>
        //           </Row>
        //           <Row style={{ marginTop: "10vh", fontSize: "1vw" }}>
        //             <Col span={8} />
        //             <Col span={8}>
        //               <Button
        //                 style={{
        //                   width: "85%",
        //                   height: "5.5vh",
        //                   fontSize: "1.2em",
        //                   fontWeight: "500",
        //                   borderColor: "#fff",
        //                   color: "#0F0718",
        //                   borderRadius: "7px",
        //                   border: "1px solid rgba(146, 144, 152, 0.5)",
        //                   marginLeft: "2.5vw",
        //                 }}
        //                 onClick={() => {
        //                   setShowReturnPurchase(!showReturnPurchase);
        //                   setOrderHistoryDetails(prevHistoryRef.current);
        //                 }}
        //                 disabled={loader}
        //               >
        //                 Cancel
        //               </Button>
        //             </Col>
        //             <Col span={1} />
        //             <Col span={8}>
        //               <Button
        //                 style={{
        //                   width: "85%",
        //                   height: "5.5vh",
        //                   fontSize: "1.2em",
        //                   fontWeight: "500",
        //                   backgroundColor: "#2F3856",
        //                   color: "#fff",
        //                   borderRadius: "7px",
        //                   marginLeft: "1vw",
        //                 }}
        //                 ref={returnProcessRef}
        //                 onClick={(e) => {
        //                   const filteredKeys = checkedKeys.filter((key) => key !== null && key !== undefined);
        //                   if (filteredKeys.length > 0) {
        //                     setLoading(true);
        //                     setIsPurchaseReturn(false);
        //                     setIsInputFocused(true);
        //                     addSelectedReturnProductsToCart(e);
        //                   }
        //                 }}
        //                 className="focusDashboardCard"
        //               >
        //                 Order
        //               </Button>
        //             </Col>
        //           </Row>
        //         </Col>
        //       </Row>
        //     </>
        //   )}

        // </div>
        <QuotationComponent
          formattedDateTime={formattedDateTime}
          setShowProductionOrder={setShowProductionOrder}
          removeProductModal={removeProductModal}
          deleteProductModal={deleteProductModal}
          setTillLink={setTillLink}
          setIsQuotation={setIsQuotation}
          onChangeInput={onChangeInput}
          setOpen={setOpen}
          productSearchInputRef={productSearchInputRef}
          {...props}
        />
      ) : isPurchaseReturn ? (
        <div
          style={{
            background: colorVariable ? colorVariable : "#f3f4f9",
            height: "100vh",
          }}
        >
          {!showReturnPurchase ? (
            <>
              <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
                <Col span={6}>
                  <img
                    src={Back}
                    id="sm-salesHistory-back"
                    onClick={() => {
                      setIsPurchaseReturn(false);
                      setStartRowData({ startRow: "0", endRow: "10" });
                      setOrderHistoryDetails([]);
                      setExpandedRowKeys([]);
                      setDateRange([]);
                      setFiltersFlag(false);
                      setIsInputFocused(false);
                      setSearchhistoryInput("");
                      setLayoutName("default");
                    }}
                    style={{
                      height: "3vw",
                      paddingBottom: "0.7em",
                      cursor: "pointer",
                    }}
                  />
                  <span
                    style={{
                      paddingRight: "1vw",
                      color: "#0F0718",
                      fontSize: "1.8em",
                      fontWeight: 500,
                      marginLeft: "0.5em",
                    }}
                  >
                    {/* <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} /> */}
                    Sales History
                  </span>
                </Col>
                <Col
                  span={8}
                  offset={2}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input
                    ref={searchCustomersRef}
                    placeholder="Search for Customers/Document Number/Contact"
                    prefix={<img src={CustomerSearch} style={{ width: "1.4em" }} alt="" />}
                    allowClear={false}
                    onPressEnter={(e) => {
                      if (e.target.value !== "") {
                        searchOrderHistory("customer", e.target.value, 0, dateRange?.length > 0 ? true : false);
                      }
                    }}
                    onBlur={() => {
                      setIsInputFocused(false);
                    }}
                    value={searchHistoryInput}
                    id="sm-salesHistory-customer"
                    onFocus={() => {
                      setOpen(true);
                      setIsInputFocused(true);
                      setKeyboardType({
                        product: false,
                        parkedBill: false,
                        salesHistoryDoc: false,
                        salesHistoryCus: true,
                        searchCus: false,
                        productSearch: false,
                        giftCardSearch: false,
                        addCustomer: false,
                      });
                    }}
                    className="salesHistory-input-sm"
                    onChange={async (e) => {
                      setSearchhistoryInput(e.target.value);
                      if (e.target.value === "" && dateRange?.length === 0) {
                        setOrderHistoryDetails([]);
                        db.orders
                          .orderBy("orderTime")
                          .limit(20)
                          .reverse()
                          .toArray()
                          .then((data) => {
                            // setOrdersCopy([...data]);
                            // setOrderHistoryDetails([...data]);
                          });
                      } else if (e.target.value === "" && dateRange?.length !== 0) {
                        await setSearchhistoryInput("");
                        searchOrderHistory("orderDateSearchKey", dateRange, 0, false);
                      }
                    }}
                  />
                </Col>

                <Col
                  span={8}
                  style={{
                    paddingRight: "",
                    paddingLeft: "1.5vw",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Popover content={content} trigger="click" visible={isPopoverVisible} onVisibleChange={(visible) => setPopoverVisible(visible)}>
                    <Button
                      icon={<CalendarOutlined />}
                      style={{
                        border: "none",
                        width: "27vw",
                        display: "flex",
                        alignItems: "center",
                        color: "#929098",
                        fontWeight: 400,
                        fontSize: "1em",
                        fontFamily: "Inter",
                      }}
                      onClick={handleButtonClick}
                      ref={dateRef}
                    >
                      {getDisplayDate()}
                    </Button>
                  </Popover>

                  {filtersFlag ? (
                    <Button
                      onClick={() => {
                        setOrderHistoryDetails(ordersCopy);
                        setFiltersFlag(false);
                        setDateRange([]);
                        setSearchhistoryInput("");
                        setDateRange(null);
                      }}
                      style={{ border: "none", padding: "4px 10px" }}
                    >
                      <img src={clearFilters} alt="clear" />
                    </Button>
                  ) : null}
                  {/* <RangePicker
                    placeholder={["Choose date range", ""]}
                    allowEmpty
                    id="sm-salesHistory-date"
                    onPressEnter={searchOrderHistory}
                    allowClear={true}
                    format={"DD-MM-YYYY"}
                    onFocus={() => {
                      setIsInputFocused(true);
                    }}
                    separator={null}
                    onChange={(e) => {
                      // e === "" ? showOrderHistory() : setOrderHistoryInput(e);
                      if (e.length > 0) {
                        searchOrderHistory("orderDateSearchKey", e);
                      }
                    }}
                    className="salesHistory-datePicker-sm"
                  /> */}
                </Col>
                {/* <Col span={6} style={{ paddingRight: "", paddingLeft: "0.3%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                      <Input
                        placeholder="Search for customers/Document Number/Contact"
                        allowClear={false}
                        id="sm-salesHistory-document"
                        ref={searchDocumentNoREf}
                        className="salesHistory-doc-input-sm"
                        onPressEnter={searchOrderHistory}
                        value={salesHistoryDocumentNoSearchInput}
                        onFocus={() => {
                          setOpen(true);
                          setKeyboardType({ product: false, parkedBill: false, salesHistoryDoc: true, salesHistoryCus: false })
                        }}
                        onChange={(e) => {
                          // e.target.value === "" ? showOrderHistory() :
                           onChangeInput(e)
                        }}
                      />
                    </Col> */}
              </Row>
              <br />
              <Row style={{ padding: "0 1.5em" }}>
                <Table
                  className="sm-cart-table"
                  ref={tableBodyRef}
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    fontSize: "1.5vw",
                    borderRadius: "7px",
                    height: "84vh",
                    padding: "0 1em",
                    background: themeJSON.productTableBGColor || "#fff",
                    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                  }}
                  onRow={(record) => ({
                    onClick: () => handleExpand(record), // Handle row click to expand
                  })}
                  pagination={false}
                  columns={orderHistoryColumns}
                  dataSource={orderHistoryDetails}
                  rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
                  onExpand={handleExpand}
                  expandable={{
                    expandedRowKeys,
                    onExpand: handleExpand,
                    expandedRowRender: (record) => (
                      <div
                        style={{
                          padding: "1% 4% 1% 4%",
                          backgroundColor: "#f3f4f9",
                        }}
                      >
                        <Row>
                          <Col span={21}>
                            <Row>
                              <Col span={12} className="td-nested-title-sm">
                                Description
                              </Col>
                              <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                Qty
                              </Col>
                              <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                                Unit Price
                              </Col>
                              <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
                                Net Total
                              </Col>
                            </Row>
                            <Row style={{ paddingBottom: "0.5vw" }}>
                              {record.items.map((e) => {
                                return (
                                  <>
                                    <Col span={12} className="td-nested-description">
                                      {e.name}
                                    </Col>
                                    <Col
                                      span={3}
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {e.weight}
                                    </Col>
                                    <Col
                                      span={1}
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "400",
                                      }}
                                    />
                                    <Col
                                      span={3}
                                      style={{
                                        textAlign: "right",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {e && typeof e.salePrice === "number" && e.salePrice.toFixed(2)}
                                    </Col>
                                    <Col span={2} style={{ textAlign: "center" }} />
                                    <Col
                                      span={3}
                                      style={{
                                        textAlign: "right",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {e.nettotal.toFixed(2)}
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                            <p
                              style={{
                                width: "96%",
                                marginLeft: "0%",
                                borderTop: "1.3px solid rgba(52, 40, 44, 0.55)",
                                marginBottom: "0px",
                              }}
                            />
                            <Row style={{ paddingTop: "5px" }}>
                              <Col span={14}>
                                {record?.paymentData?.length > 0 && (
                                  <Row>
                                    <Col span={8} style={{ textAlign: "left" }}>
                                      <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Payment method</p>
                                    </Col>
                                    <Col span={5}>
                                      <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Amount</p>
                                    </Col>
                                    <Col span={8}>
                                      <p style={{ margin: "0", fontWeight: "500", textAlign: "left" }}>Advance Payment</p>
                                    </Col>
                                  </Row>
                                )}

                                {record?.paymentData?.map((ele, ind) => (
                                  <Row>
                                    <Col span={8} style={{ textAlign: "left" }}>
                                      {ele.name}
                                    </Col>
                                    <Col span={5}>{ele.amount}</Col>
                                    {ind === 0 && <Col span={5}>{record.advancePayment > 0 && record.layAway === "Y" ? record.advancePayment : 0}</Col>}
                                  </Row>
                                ))}
                              </Col>
                              <Col span={3} offset={3} className="td-subtotal">
                                <span className="td-subTotalText">Subtotal</span>
                                <span className="td-subTotalText">Total tax</span>
                              </Col>
                              <Col span={3} className="td-subtotal" style={{ textAlign: "right" }}>
                                <span className="td-subTotalText">{(record.total - record.tax).toFixed(2)}</span>
                                <span className="td-subTotalText">{record.tax.toFixed(2)}</span>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: "1%" }}>
                              <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                                <span className="td-total">SALE TOTAL</span>
                              </Col>
                              <Col
                                span={3}
                                style={{
                                  fontSize: "1em",
                                  borderBottom: "1px solid rgba(52, 40, 44, 0.55)",
                                  textAlign: "right",
                                  paddingTop: "4px",
                                }}
                              >
                                <span className="td-total">{parseFloat(record.total).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={3} className="td-bottons" style={{ fontSize: "1vw" }}>
                            {isReturn ? (
                              reconstructedObject?.ReturnPrint === "N" ? null : (
                                <p
                                  id="sm-salesHistory-printer"
                                  style={{
                                    cursor: "pointer",
                                    padding: "5.5% 12%",
                                    borderRadius: "5px",
                                    border: "1px solid #fff",
                                    backgroundColor: "#fff",
                                    fontSize: "1em",
                                    fontWeight: 500,
                                    color: "#0F0718",
                                  }}
                                  onClick={async () => {
                                    const workflowRules = await db.POSWorkFlowRules.toArray();
                                    const approvers = await db.approvers.toArray();
                                    if (workflowRules && workflowRules.length > 0) {
                                      workflowRules.forEach((res) => {
                                        if (res.eventName === "Receipt Reprint Request") {
                                          dispatchEvent(res.eventName, res.ruleName, res.ruleCondition, approvers, (eventData) => {
                                            if (eventData?.approvalGranted) {
                                              ObFlag
                                                ? HCPrint(record, "Duplicate Copy")
                                                : isPrintModeXML
                                                ? xmlPrint(record, "Duplicate Copy")
                                                : htmlPrint(record, "Duplicate Copy");
                                            }
                                          });
                                        }
                                      });
                                    } else {
                                      ObFlag ? HCPrint(record, "Duplicate Copy") : isPrintModeXML ? xmlPrint(record, "Duplicate Copy") : htmlPrint(record, "Duplicate Copy");
                                      upsertPOSLog(record, "RRC");
                                    }
                                  }}
                                >
                                  <img
                                    src={printerIcon}
                                    style={{
                                      height: "1.4vw",
                                      paddingRight: "0.4vw",
                                      cursor: "pointer",
                                    }}
                                  />
                                  Print receipt
                                </p>
                              )
                            ) : reconstructedObject?.Reprint === "N" || record.total === 0 || record.total === "0.00" ? null : (
                              <p
                                id="sm-salesHistory-printer"
                                style={{
                                  cursor: "pointer",
                                  padding: "5.5% 12%",
                                  borderRadius: "5px",
                                  border: "1px solid #fff",
                                  backgroundColor: "#fff",
                                  fontSize: "1em",
                                  fontWeight: 500,
                                  color: "#0F0718",
                                }}
                                onClick={async () => {
                                  const workflowRules = await db.POSWorkFlowRules.toArray();
                                  const approvers = await db.approvers.toArray();
                                  if (workflowRules && workflowRules.length > 0) {
                                    workflowRules.forEach((res) => {
                                      if (res.eventName === "Receipt Reprint Request") {
                                        dispatchEvent(res.eventName, res.ruleName, res.ruleCondition, approvers, (eventData) => {
                                          if (eventData?.approvalGranted) {
                                            ObFlag ? HCPrint(record, "Duplicate Copy") : isPrintModeXML ? xmlPrint(record, "Duplicate Copy") : htmlPrint(record, "Duplicate Copy");
                                          }
                                        });
                                      }
                                    });
                                  } else {
                                    ObFlag ? HCPrint(record, "Duplicate Copy") : isPrintModeXML ? xmlPrint(record, "Duplicate Copy") : htmlPrint(record, "Duplicate Copy");
                                    upsertPOSLog(record, "RRC");
                                  }
                                }}
                              >
                                <img
                                  src={printerIcon}
                                  style={{
                                    height: "1.4vw",
                                    paddingRight: "0.4vw",
                                    cursor: "pointer",
                                  }}
                                />
                                Print receipt
                              </p>
                            )}
                            {parseInt(record.total) > 0 && posConfig.showSalesReturn === "Y" && record.layAway === "N" && record.paid !== 0 ? (
                              <p
                                style={{
                                  cursor: "pointer",
                                  padding: "5.5% 13%",
                                  borderRadius: "5px",
                                  border: "1px solid #fff",
                                  backgroundColor: "#fff",
                                  fontSize: "1em",
                                  fontWeight: 500,
                                  color: "#0F0718",
                                }}
                                id="sm-sales-return"
                                onClick={() => {
                                  const isAlreadyParked = filterdParkedList.some((item) => item.parkedOrderID === record.sOrderID);
                                  if (isAlreadyParked) {
                                    Modal.error({
                                      title: "This return order is already parked. Please access it from the bill parking area to continue.",
                                    });
                                  } else {
                                    prevHistoryRef.current = orderHistoryDetails;
                                    setCheckedKeys([]);
                                    searchReturnHistory(record.documentno, record);
                                    setOrderHistoryDetails([]);
                                    localStorage.setItem("salesReturn", "Y");
                                  }
                                }}
                              >
                                <img
                                  src={Discard}
                                  style={{
                                    height: "1.1vw",
                                    paddingRight: "0.4vw",
                                  }}
                                />
                                Sales Return
                              </p>
                            ) : record.layAway === "Y" ? (
                              <p
                                style={{
                                  cursor: "pointer",
                                  padding: "5.5% 13%",
                                  borderRadius: "5px",
                                  border: "1px solid #fff",
                                  backgroundColor: "#fff",
                                  fontSize: "1em",
                                  fontWeight: 500,
                                  color: "#0F0718",
                                }}
                                id="sm-sales-return"
                                onClick={async () => {
                                  const isDocnoInList = filterdParkedList?.some((item) => item.parkedDocNo === record.documentno);
                                  if (isDocnoInList) {
                                    Modal.info({
                                      title: "This layaway order is already parked. Please access it from the bill parking area to continue.",
                                      icon: null,
                                      cancelText: "No",
                                      okText: "Okay",
                                      autoFocusButton: null,
                                    });
                                  } else {
                                    setIsPurchaseReturn(false);
                                    retrieveLayaway(record);
                                    setOrderHistoryDetails([]);
                                    const saleData = await db.posSaletypes.toArray();
                                    saleData.map((item) => {
                                      if (item.cwrSaletype.cwrSaletypeId === record.saleType.cwrSaletypeId) {
                                        setSelectedSaleTypeData(item);
                                        localStorage.setItem("saleTypeData", JSON.stringify(item));
                                        setSelectedSaleType(item?.cwrSaletype?.name);
                                      }
                                    });
                                    const paymentDetails = getFilteredPaymentMethods(record?.saleType?.cwrSaletypeId || null);

                                    let payments =
                                      paymentDetails.length >= 5
                                        ? _.sortBy(paymentDetails, "sequenceNo").slice(
                                            0,
                                            tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === record?.customer?.cwrCustomerId && paymentDetails.length === 5 ? 4 : 4
                                          )
                                        : paymentDetails;
                                    setStartIndex(payments.length);
                                    setShowPayments(payments);
                                  }
                                  // setTableValue("POS");
                                  // setIsPurchaseReturn(false);
                                  // retrieveLayaway(record);
                                }}
                              >
                                <img
                                  src={layAwayIcon}
                                  style={{
                                    height: "1vw",
                                    paddingRight: "0.4vw",
                                  }}
                                />
                                Retrieve Sale
                              </p>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    ),
                    rowExpandable: (record) => record.name !== "Not Expandable",
                    expandIcon: ({ expanded, onExpand, record }) => (
                      <span id="sm-salesHistory-expand" onClick={(e) => onExpand(record, e)}>
                        {expanded ? <DownOutlined style={{ color: "#929098" }} /> : <RightOutlined style={{ color: "#929098" }} />}
                      </span>
                    ),
                  }}
                />
              </Row>
            </>
          ) : (
            <>
              <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
                <Col span={6}>
                  <img
                    src={Back}
                    id="sm-salesReturn"
                    onClick={() => {
                      setOrderHistoryDetails(ordersCopy);
                      setShowReturnPurchase(!showReturnPurchase);
                      setSearchhistoryInput("");
                      setDateRange("");
                      setFiltersFlag(false);
                    }}
                    // style={{ height: "3.5vw", paddingBottom: "0.5em", cursor: "pointer" }}
                    style={{
                      height: "3vw",
                      paddingBottom: "0.7em",
                      cursor: "pointer",
                    }}
                  />
                  <span
                    style={{
                      paddingRight: "1vw",
                      color: "",
                      fontSize: "1.8em",
                      fontWeight: 500,
                      marginLeft: "0.5em",
                      color: "#0F0718",
                    }}
                  >
                    {/* <img src={DownloadIcon} style={{ height: "1.3vw", paddingRight: "4px" }} /> */}
                    Return Purchase
                  </span>
                </Col>
              </Row>
              <br />
              <Row style={{ padding: "0 1.5em 1.5em 1.5em", fontSize: "1vw" }}>
                <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                  Document No: {selectedReturnOrder.documentno}
                </Col>
                <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                  Date : {selectedReturnOrder.dateordered}
                </Col>
                <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                  Total : {selectedReturnOrder.grosstotal}
                </Col>
                <Col span={6} style={styles.screenStyles.returnPurchase.docNo}>
                  Discount : {parseFloat(selectedReturnOrder.discAmount).toFixed(2)}
                </Col>
              </Row>
              <Row style={{ padding: "0 1.5em", fontSize: "1vw" }}>
                <Table
                  className="sm-cart-table-return"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    border: "none",
                    fontSize: "1.1vw",
                    borderRadius: "7px",
                    height: "43vh",
                    padding: "0 1em",
                    background: themeJSON.productTableBGColor || "#fff",
                    boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                  }}
                  pagination={false}
                  dataSource={dataSource}
                  components={{
                    body: {
                      row: EditableRow,
                      cell: EditableCell,
                    },
                  }}
                  setModalEditState={setModalEditState}
                  setIsInputFocused={setIsInputFocused}
                  columns={columns1.map((col) => ({
                    ...col,
                    onCell: (record) => ({
                      record,
                      editable: col.dataIndex === "qtyToReturn" && record.product.returnable,
                      dataIndex: col.dataIndex,
                      title: col.title,
                      handleSave: handleSave,
                      setModalEditState: setModalEditState,
                      setIsInputFocused: setIsInputFocused,
                    }),
                  }))}
                />
              </Row>
              <Row style={{ paddingTop: "2vh" }}>
                <Col span={9}>
                  {/* <Row style={{ padding: "0 1% 0 3.5%" }} gutter={[8, 6]}>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return">
                            1
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            2
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            3
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            4
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            5
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return" >
                            6
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market-return">Clear</button>
                        </Col>
                        <Col span={18}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return">
                                7
                              </button>
                            </Col>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return" >
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return">
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return" >
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return" >
                                00
                              </button>
                            </Col>
                            <Col span={8}>
                              <button className="amt-dial-btn-market-return" >
                                .
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <button
                            className="amt-dial-btn-market2-return"
                            onClick={parseFloat(cart.total) > parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                          >
                            Enter
                          </button>
                        </Col>
                      </Row> */}
                </Col>
                <Col span={4} />
                <Col span={11}>
                  <Row style={{ fontSize: "1vw" }}>
                    <Col span={11} offset={1}>
                      <p style={styles.screenStyles.returnPurchase.returnItems}>Return Items / Qty: </p>
                      <p style={styles.screenStyles.returnPurchase.returnAmount}>Return Amount Total:</p>
                    </Col>
                    <Col span={11} style={{ textAlign: "right" }}>
                      <p style={styles.screenStyles.returnPurchase.returnItems}>
                        {" "}
                        {selectedReturnOrder.itemsToReturn ? selectedReturnOrder.itemsToReturn : "0"} /{" "}
                        {selectedReturnOrder.itemsQtyToReturn ? parseFloat(selectedReturnOrder.itemsQtyToReturn).toFixed(2) : "0.00"}
                      </p>
                      <p style={styles.screenStyles.returnPurchase.returnAmount}>
                        {" "}
                        {selectedReturnOrder.returnAmountTotal ? parseFloat(selectedReturnOrder.returnAmountTotal).toFixed(2) : "0.00"}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10vh", fontSize: "1vw" }}>
                    <Col span={8} />
                    <Col span={8}>
                      <Button
                        style={{
                          width: "85%",
                          height: "5.5vh",
                          fontSize: "1.2em",
                          fontWeight: "500",
                          borderColor: "#fff",
                          color: "#0F0718",
                          borderRadius: "7px",
                          border: "1px solid rgba(146, 144, 152, 0.5)",
                          marginLeft: "2.5vw",
                        }}
                        onClick={() => {
                          // setOrderHistoryDetails(ordersCopy);
                          setShowReturnPurchase(!showReturnPurchase);
                          // setIsPurchaseReturn(false);
                          setOrderHistoryDetails(prevHistoryRef.current);
                          // setShowReturnPurchase(false);
                        }}
                        disabled={loader}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col span={1} />
                    <Col span={8}>
                      <Button
                        style={{
                          width: "85%",
                          height: "5.5vh",
                          fontSize: "1.2em",
                          fontWeight: "500",
                          backgroundColor: "#2F3856",
                          color: "#fff",
                          borderRadius: "7px",
                          // borderColor: "#0F0718",
                          marginLeft: "1vw",
                        }}
                        ref={returnProcessRef}
                        onClick={(e) => {
                          const filteredKeys = checkedKeys.filter((key) => key !== null && key !== undefined);
                          if (filteredKeys.length > 0) {
                            setLoading(true);
                            setIsPurchaseReturn(false);
                            setStartRowData({ startRow: "0", endRow: "10" });
                            setIsInputFocused(true);
                            addSelectedReturnProductsToCart(e);
                          }
                        }}
                        className="focusDashboardCard"
                      >
                        Return
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : !syncLoad ? (
        <>
          <div style={mainDivStyles}>
            <Row
              style={{
                padding: "0.8% 0",
                height: "9vh",
                alignItems: "center",
                fontSize: "1vw",
              }}
            >
              <Col
                onClick={() => {
                  setTillLink(true);
                }}
                style={{ cursor: "pointer" }}
                span={1}
              >
                <img src={sideMenu} style={{ paddingLeft: "1rem", height: "2vw" }} />
              </Col>
              {/* 7 */}
              <Col span={5}>
                <img src={themeJSON.logoURL || POSLogo} style={{ height: "2.5vw", paddingLeft: "0.4vw" }} />
              </Col>
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showSaleType === true ? (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#0F0718",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "13px",
                      paddingLeft: "1vw",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (cart.layAway !== "Y") {
                        setIsSaleTypeFlag(true);
                      }
                    }}
                  >
                    {selectedSaleType}
                  </span>
                ) : (
                  ""
                )}
              </Col>
              <Col span={1} />
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1em",
                    color: themeJSON.dateTimeTextColor || "#929098",
                    marginLeft: "7vw",
                  }}
                >
                  {" "}
                  {formattedDateTime}
                </span>
              </Col>
              <Col span={2} />
              <Col
                span={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingleft: "2vw",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: themeJSON.orderNoTextColor || "#0F0718",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1em",
                    paddingLeft: "1vw",
                  }}
                >
                  Order No:{cart?.documentno}
                </span>

                {/* <EditOutlined onClick={()=>{setNotesPopup(true); setIsInputFocused(true)}} style={{lineHeight:"3.5vh",fontSize:"1.5vw",cursor:"pointer"}}/> */}
              </Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  // paddingRight: "1%",
                }}
              >
                <img
                  src={NotesImage}
                  onClick={() => {
                    setNotesPopup(true);
                    if (cart.description && cart.description.trim() !== "") {
                      setNotesValue(cart.description);
                    }
                    setIsInputFocused(true);
                  }}
                  style={{
                    fontSize: "1.5vw",
                    cursor: "pointer",
                    height: "2.4vh",
                    marginRight: "0.7rem",
                  }}
                  alt=""
                />

                <img
                  onClick={() => {
                    setIsLocked(true);
                  }}
                  src={LockIcon}
                  style={{ height: "3vh", cursor: "pointer", marginRight: "0.7rem" }}
                />

                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    padding: "0.5vw",
                    border: "1px solid #3C424B",
                    borderRadius: "50%",
                  }}
                >
                  <img src={userIcon} style={{ height: "2.7vh" }} />
                </div>
                <div
                  style={{
                    marginLeft: "1vw",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: themeJSON.orderNoTextColor || "#0F0718",
                      fontSize: "1em",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    {userData.user}
                  </span>
                  <span
                    style={{
                      color: themeJSON.orderNoTextColor || "#0F0718",
                      fontSize: "0.8em",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                  >
                    Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                  </span>
                </div>
                <div style={{ marginLeft: "3%" }}>
                  <img
                    src={Online}
                    style={{
                      display: !isOnline && "none",
                      height: "2.5vh",
                      cursor: "pointer",
                      paddingBottom: "0.5vh",
                    }}
                  />{" "}
                  <img
                    src={Ofline}
                    style={{
                      display: isOnline && "none",
                      height: "2.5vh",
                      cursor: "pointer",
                      paddingBottom: "0.5vh",
                    }}
                  />{" "}
                </div>
              </Col>
            </Row>

            {barcodeFlag && <BarCodeComponentForRetail onScan={onBarcodeInput} setProductSearchInput={setProductSearchInput} productSearchInput={productSearchInput} />}

            <div style={{ display: "flex", height: "91vh" }}>
              {/* Left side UI */}
              <div style={{ width: "63.9%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "0 0 0 1.7%",
                  }}
                >
                  <div style={{ width: "93%" }}>
                    <Input
                      placeholder="Search for products by code/name"
                      id="sm-product-search"
                      onChange={(e) => {
                        e.target.value === "" ? clearProductSearchResults() : onChangeInput(e);
                      }}
                      className="productSearch-sm"
                      style={{
                        borderRadius: "7px",
                        padding: "0.8vh 0.8vw",
                        borderColor: "#fff",
                      }}
                      value={productSearchInput}
                      onFocus={(e) => {
                        // const productSearchInput = document.getElementById("sm-product-search");
                        // productSearchInput.addEventListener("focus",productSearchInput.select());
                        setKeyboardType({
                          product: true,
                          parkedBill: false,
                          salesHistoryDoc: false,
                          salesHistoryCus: false,
                          searchCus: false,
                          productSearch: false,
                          giftCardSearch: false,
                          addCustomer: false,
                        });
                        setOpen(true);
                      }}
                      onBlur={() => {
                        setIsInputFocused(false);
                      }}
                      // onPressEnter={(e) => {
                      //   !barcodeFlag ? onBarcodeInput(e.target.value, true) : null;
                      // }}
                      suffix={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {productSearchInput.length > 0 ? (
                            // <CloseSquareFilled style={{ fontSize: "20px", color: "#2F3856" }} onClick={() => clearProductSearchResults("")} />
                            <CloseSquareFilled
                              style={{ fontSize: "20px", color: "#2F3856" }}
                              // onClick={() => clearProductSearchResults("")}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (productSearchInputRef?.current) {
                                  productSearchInputRef.current.clearInput();
                                }
                                setProductSearchInput("");
                                // clearProductSearchResults();
                              }}
                            />
                          ) : null}
                        </div>
                      }
                      prefix={
                        <img
                          src={SearchIcon}
                          alt=""
                          onClick={() => {
                            onBarcodeInput(productSearchInput, false);
                          }}
                        />
                      }
                    />
                  </div>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "4.5%",
                      margin: "0 0.2vw 0 0.8vw",
                      borderRadius: "7px",
                      height: "inherit",
                      borderColor: "#fff",
                    }}
                    onClick={() => setIsProductsFilter(true)}
                  >
                    <img src={ScanIcon} style={{ height: "2.7vh" }} />
                  </Button>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "4.5%",
                      margin: "0 1vw 0 0.5vw",
                      borderRadius: "7px",
                      height: "inherit",
                      borderColor: "#fff",
                    }}
                    id="sm-product-drawer"
                    // onClick={() => setIsProductsFilter(true)}
                    onClick={() => {
                      setFilterDrawer(true);
                    }}
                  >
                    <img src={FilterIcon} style={{ height: "2.7vh" }} />
                  </Button>
                </div>
                <div style={{ padding: "1.5%" }}>
                  {cart.layAway === "Y" && posConfig.advanceLayaway === "Y" ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "29vh",
                        left: "56vw",
                      }}
                    >
                      <span style={{ opacity: "0.5" }}>LAYAWAY</span>
                    </div>
                  ) : null}

                  <Table
                    className="sm-cart-table-pos"
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      border: "none",
                      // fontSize: "1vw",
                      borderRadius: "7px",
                      padding: "0px 7px",
                      // border: "1px solid rgba(146, 144, 152, 0.4)",
                      height: "56.5vh",
                      background: themeJSON.productTableBGColor || "#fff",
                    }}
                    pagination={false}
                    dataSource={
                      // Filter the data where weight is greater than 0
                      (parseFloat(localStorage.getItem("cartObj"))?.items ? JSON.parse(localStorage.getItem("cartObj")).items : cart?.items || []).filter(
                        (item) => item.weight !== 0
                      )
                    }
                    sticky
                    id="sm-cart-table"
                    rowClassName={(record) => (selectedProductInCart.lineId === record.lineId ? "tableRowSelectedBlue" : "tableRowNonSelectedWhite")}
                    columns={[
                      {
                        title: "Item Name",
                        dataIndex: "name",
                        key: "name",
                        width: "28%",
                        render: (text, record, index) => {
                          return (
                            <>
                              <div className="offerName" style={{ display: "flex", alignItems: "center" }}>
                                {posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? (
                                  <>
                                    {record.salesRepName ? (
                                      <>
                                        <img src={userTieIcon} style={{ height: "2vh", marginRight: "5px", marginBottom: "2px" }} />
                                        <span>{record.salesRepName}</span>
                                      </>
                                    ) : (
                                      cart.salesRepName && (
                                        <>
                                          <img src={userTieIcon} style={{ height: "2vh", marginRight: "5px", marginBottom: "2px" }} />
                                          <span>{cart.salesRepName}</span>
                                        </>
                                      )
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div
                                onClick={() => {
                                  if (cart.type !== "Layaway") {
                                    selectProductInCart(record);
                                    selectedRowKeys.length > 0 && selectedProductInCart.productId === record.productId ? setShowPaymentMethods(false) : "";
                                  }
                                }}
                                id="sm-product-name"
                                className="tableRow"
                              >
                                {record.name}
                              </div>
                              <div className="offerName">
                                <p style={{ margin: 0 }}>{record.cardNo ? record.cardNo : record.value}</p>
                                {/* {record?.customText ? <p style={{ margin: 0, padding: 0, color: "#FFA500" }}>{record?.customText}</p> : ""} */}
                                {record.newCustomAttributes &&
                                  record.newCustomAttributes.length > 0 &&
                                  Object.entries(record.newCustomAttributes[0]).map(([key, value]) => {
                                    return (
                                      value && (
                                        <p style={{ margin: 0, padding: 0, color: "#8c92ac", fontStyle: "italic" }}>
                                          {key} - {value}
                                        </p>
                                      )
                                    );
                                  })}

                                {record.selectedAddons &&
                                  record.selectedAddons.length > 0 &&
                                  record.selectedAddons.map((item) => {
                                    return item?.name && <p style={{ margin: 0, padding: 0, color: "#8c92ac", fontStyle: "italic" }}>{item.name}</p>;
                                  })}

                                <span
                                  style={{
                                    background: Math.abs(record.discount) > 0 ? "#F8C519" : "",
                                    padding: "0.25vw",
                                    display: record.discountName ? "" : "none",
                                  }}
                                >
                                  {Math.abs(record.discount) > 0 ? record.discountName ? <img src={Offers} alt="" width={12} height={12} style={{ marginTop: "-2px" }} /> : "" : ""}{" "}
                                  {Math.abs(record.discount) > 0 ? record.discountName || "" : ""}{" "}
                                </span>
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        title: "Qty",
                        dataIndex: "weight",
                        key: "weight",
                        width: "11%",
                        render: (text, record, index) => {
                          const wt = parseFloat(text);
                          if (Number.isInteger(wt)) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "1.1vw" }}>{wt.toFixed(record.isQtyDesimal)}</div> */}
                                <div className="tableRow">{wt.toFixed(record.isQtyDesimal)}</div>
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <div>{parseFloat(text).toFixed(record.isQtyDesimal)}</div>
                                <div className="offerName">
                                  {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                                </div>
                              </>
                            );
                          }
                        },
                        align: "center",
                      },
                      {
                        title: "Discount",
                        dataIndex: "discount",
                        key: "discount",
                        width: "11%",
                        render: (text, record) => {
                          return (
                            <div>
                              <span className="tableRow">{parseFloat(text).toFixed(2)}</span>
                            </div>
                          );
                        },
                        align: "center",
                      },
                      {
                        title: "Tax",
                        dataIndex: "taxAmount",
                        key: "taxAmount",
                        width: "11%",
                        render: (text, record) => {
                          return (
                            <div>
                              <span className="tableRow">{text?.toFixed(2).length < 8 ? parseFloat(text)?.toFixed(2) : `${text?.toString().substring(0, 8)}`}</span>
                            </div>
                          );
                        },
                        align: "center",
                      },
                      {
                        title: "Price",
                        dataIndex: "realPrice",
                        key: "realPrice",
                        width: "10%",
                        render: (text, record) => {
                          return (
                            <div>
                              <span className="tableRow">{parseFloat(text).toFixed(2)}</span>
                            </div>
                          );
                        },
                        align: "center",
                      },
                      {
                        title: "Total",
                        dataIndex: "nettotal",
                        key: "nettotal",
                        width: "15%",
                        render: (text, record) => {
                          return (
                            <div style={{ position: "relative" }}>
                              <span className="tableRow" style={{ width: "12%", left: "0" }}>
                                {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                &nbsp;
                                {text.toString().length < 9 ? parseFloat(text).toFixed(2) : `${text.toString().substring(0, 5)}...`}
                                &nbsp;
                                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              </span>
                            </div>
                          );
                        },
                        align: "center",
                      },
                      {
                        title: "",
                        dataIndex: "",
                        key: "",
                        width: "10%",
                        render: (text, record) => {
                          return (
                            <div style={{ position: "relative", display: "flex", justifyContent: "space-evenly" }}>
                              <img
                                src={DeleteProduct}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearSelectedProductInCart();
                                  removeProductModal(record);
                                  setIsInputFocused(false);
                                }}
                                id="sm-product-delete"
                                style={{
                                  height: "2.5vh",
                                  cursor: "pointer",
                                  right: 1,
                                  top: "15%",
                                }}
                                // style={{ height: "18px", width: "18px", cursor: "pointer", right: 1, top: "15%", paddingRight: "3px" }}
                              />
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  clearSelectedProductInCart();
                                  setNotesPopup(true);
                                  setIsInputFocused(true);
                                  setNotesValue(record.notes);
                                  setSelectedProductForNotes(record);
                                  // console.log("notesValue--------=======>.>",selectedProductForNotes)
                                  selectProductInCart(record);
                                  setIsInputFocused(false);
                                }}
                                src={NotesImage}
                                alt=""
                                style={{
                                  height: "14px",
                                  width: "14px",
                                  cursor: "pointer",
                                  paddingleft: "0.8vw",
                                }}
                              />
                              {/* {posConfig.showLineSalesRep === "Y" && (
                                <img src={userIcon} style={{ height: "2.5vh", cursor: "pointer" }} alt="" onClick={() => setOpenSalesRep({ flag: true, level: "line" })} />
                              )} */}
                              {posConfig.showLineSalesRep === "Y" && (
                                <img
                                  // src={userIcon}
                                  src={userAddIcon}
                                  style={{ height: "2.3vh", cursor: "pointer", paddingTop: "1px" }}
                                  alt=""
                                  onClick={() => {
                                    setOpenSalesRep({ flag: true, level: "line" });
                                  }}
                                />
                              )}
                            </div>
                          );
                        },
                        align: "center",
                      },
                    ]}
                    onRow={(record) => ({
                      onClick: () => {
                        // if (cart.type !== "Layaway") {
                        setIsInputFocused(false);
                        setOverPayedAmount(0);
                        setAmount(cart.total - cart.paid);
                        selectProductInCart(record);
                        setQtyNumberFlag(0);
                        setShowPaymentMethods(false);
                        setSelectedProductInCart(record);
                        setStockList([]);
                        // } else {
                        //   message.info("This order cannot be edited!");
                        // }
                      },
                    })}
                  />
                </div>
                <div style={{ padding: "0 1.5% 0 1.5%", display: "flex" }}>
                  <div
                    style={{
                      background: themeJSON.taxAndDiscountSectionBGColor || "#fff",
                      height: "23.5vh",
                      width: "60%",
                      marginRight: "1%",
                      border: "1px solid #fff",
                      borderRadius: "7px",
                      padding: "0.5vh",
                      fontSize: "1vw",
                    }}
                  >
                    <Row style={{ height: "4.5vh" }}>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0.8vw",
                          textAlign: "left",
                          fontWeight: "500",
                          color: "#0F0718",
                        }}
                      >
                        <p style={styles.screenStyles.mainScreen.subTotalText}>Subtotal:</p>
                      </Col>
                      {/* <Col span={12} style={{ display: "flex", alignItems: "center", paddingRight: "0.8vw", textAlign: "right", justifyContent: "end", color: "#0F0718" }}> */}
                      <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        &nbsp;
                        <p
                          style={{
                            textAlign: "right",
                            margin: "0",
                            fontWeight: "500",
                            color: "#0F0718",
                          }}
                        >
                          {(
                            parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.total : cart.total).toFixed(2) -
                            parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.tax : cart.tax).toFixed(2)
                          ).toFixed(2)}
                        </p>
                        <span style={{ marginLeft: "2px", marginTop: 0 }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>

                    <Row style={{ height: "4.4vh" }}>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0.8vw",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        <p style={styles.screenStyles.mainScreen.subTotalText}>Discount:</p>
                      </Col>
                      <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        &nbsp;
                        <p
                          style={{
                            textAlign: "right",
                            margin: "0",
                            fontWeight: "500",
                            color: "#0F0718",
                          }}
                        >
                          {parseFloat(cart.discount ? cart.discount : 0).toFixed(2)}
                        </p>
                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>

                    <Row style={{ height: "4.5vh" }}>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0.8vw",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        <p style={styles.screenStyles.mainScreen.subTotalText}>Tax:</p>
                      </Col>
                      <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        &nbsp;
                        <p
                          style={{
                            textAlign: "right",
                            margin: "0",
                            fontWeight: "500",
                            color: "#0F0718",
                          }}
                        >
                          {parseFloat(cart.tax).toFixed(2)}
                        </p>
                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>

                    <Row style={{ height: "4.5vh" }}>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0.8vw",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        <p style={styles.screenStyles.mainScreen.subTotalText}>Net Value:</p>
                      </Col>
                      <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        &nbsp;
                        <p
                          style={{
                            textAlign: "right",
                            margin: "0",
                            fontWeight: "500",
                            color: "#0F0718",
                          }}
                        >
                          {parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj")).total : cart.total).toFixed(2)}
                        </p>
                        <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                      </Col>
                    </Row>

                    <Row style={{ height: "4.5vh" }}>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "0.8vw",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        <p style={styles.screenStyles.mainScreen.subTotalText}>Total Items / Total Qty:</p>
                      </Col>
                      <Col span={12} style={styles.screenStyles.mainScreen.subTotalCurrency}>
                        <p
                          style={{
                            textAlign: "right",
                            margin: "0",
                            fontWeight: "500",
                            color: "#0F0718",
                          }}
                        >
                          {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ height: "23.7vh", width: "40%", fontSize: "1vw" }}>
                    <div
                      id="sm-cart-total"
                      // onClick={handleCartTotalClick}
                      onClick={verifyStock}
                      style={{
                        height: "18.2vh",
                        position: "relative",
                        background: themeJSON.totalAmountToPaySectionBGColor || "#2F3856",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          padding: "0.7vw 0 0 1vw",
                          color: "#fff",
                          fontSize: "1em",
                          fontWeight: "500",
                          height: "4.5vh",
                        }}
                      >
                        Total Amount To Pay
                      </p>
                      {cart.total !== undefined ? (
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "2.5em",
                            fontWeight: "500",
                            height: "14.4vh",
                            width: "100%",
                          }}
                        >
                          {" "}
                          {parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj")).total : cart.total).toFixed(2)}
                        </p>
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "2.5em",
                            fontWeight: "500",
                            height: "14.4vh",
                            width: "100%",
                          }}
                        >
                          {" "}
                          0.00
                        </p>
                      )}
                    </div>
                    <Row gutter={[8, 0]} style={{ marginTop: "0.8vh" }}>
                      <Col span={12} onClick={openDisplayParkedBillModal}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: "500",
                            textAlign: "left",
                            border: `1px solid ${themeJSON.holdAndCancelButtonBGColor || "rgba(146, 144, 152, 0.5)"}`,
                            height: "4.8vh",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor: themeJSON.holdAndCancelButtonBGColor || "#fff",
                          }}
                        >
                          <img src={Hold} style={{ height: "2.5vh" }} />
                          <span
                            style={{
                              margin: "0",
                              textAlign: "center",
                              color: themeJSON.holdAndCancelButtonTextColor || "#2F3856",
                              paddingLeft: "0.5vw",
                              fontSize: "1em",
                              fontWeight: 500,
                            }}
                          >
                            Hold
                          </span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",

                            fontWeight: "500",
                            justifyContent: "center",
                            border: `1px solid ${themeJSON.holdAndCancelButtonBGColor || "rgba(146, 144, 152, 0.5)"}`,
                            height: "4.8vh",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor: themeJSON.holdAndCancelButtonBGColor || "#fff",
                          }}
                          id="sm-delete-cart"
                          onClick={deleteProductModal}
                        >
                          <img src={Cancel} style={{ height: "2.5vh" }} />
                          <span
                            style={{
                              textAlign: "center",
                              margin: "0",
                              color: themeJSON.holdAndCancelButtonTextColor || "#2F3856",
                              paddingLeft: "0.5vw",
                              fontSize: "1em",
                              fontWeight: 500,
                            }}
                          >
                            Cancel
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              {/* Right side UI */}
              <div
                style={{
                  width: "35.6%",
                  padding: "0% 0% 0 0.4%",
                  borderRadius: "10px",
                  paddingBottom: "0",
                  height: "89vh",
                  marginRight: "1%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId ||
                  tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId ? (
                    <Input
                      className="productSearch-sm"
                      id="sm-customer-search"
                      style={{
                        borderRadius: "0.5vw",
                        height: "4.9vh",
                        borderColor: "#fff",
                      }}
                      placeholder="Enter customer number/name"
                      prefix={<img src={CustomerSearch} alt="" style={{ height: "2.7vh" }} />}
                      onClick={() => {
                        if (cart.isReturn !== true) {
                          setDisplayCustomerSearch(true);
                          setCloseCustomerFlag(true);
                        }
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        padding: "0.7vh 1.3vh",
                        border: "none",
                        background: "#fff",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "5vh",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <img src={CustomerIcon} style={{ height: "3.5vh" }}></img>{" "}
                        <span style={{ fontSize: "1vw" }}>
                          {cart?.customer?.name} - {cart?.customer?.b2cCustomerSegment?.name}
                        </span>
                      </span>
                      <span>
                        <img
                          src={View}
                          style={{
                            height: "3vh",
                            paddingBottom: "0.1em",
                            cursor: "pointer",
                          }}
                          id="sm-customer-view"
                          onClick={() => {
                            if (cart.isReturn !== true) showEditOldCustomerFields(cart?.customer);
                          }}
                        />{" "}
                        &#124;{" "}
                        <EditOutlined
                          style={{ cursor: "pointer" }}
                          id="sm-customer-edit"
                          onClick={() => {
                            if (cart.isReturn !== true) {
                              showEditOldCustomerFields(cart?.customer);
                              setEditFlag(true);
                            }
                          }}
                        />{" "}
                        &#124;{" "}
                        <CloseOutlined
                          style={{ fontSize: "2vh" }}
                          onClick={() => {
                            if (cart.isReturn !== true) {
                              removeCutomer();
                            }
                          }}
                        ></CloseOutlined>
                      </span>
                    </div>
                  )}
                  <img
                    src={AddUser}
                    style={{
                      height: "1.6vw",
                      paddingLeft: "1vw",
                      cursor: "pointer",
                      display: cart.customer.cwrCustomerId === customerId ? "" : "none",
                    }}
                    onClick={() => {
                      if (cart.isReturn !== true) showAddNewCustomerFields(cart.customer);
                    }}
                  />
                </div>
                <div style={{ display: "flex", fontSize: "1vw" }}>
                  <div
                    style={{
                      width: "18%",
                      paddingTop: "3%",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.01rem",
                        display: tillAccessData?.productSync === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-product-sync"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          let parkedBills = JSON.parse(localStorage.getItem("parkedList"));
                          if (cart.items.length > 0) {
                            openDisplayParkedBillModal();
                          } else if (parkedBills?.length > 0) {
                            message.error("Please clear the Parked Bills to Sync Products!");
                          } else {
                            if (isOnline) {
                              handleKeyvalue("4");
                            } else {
                              errorMessage("Sync Failed", "Please check your network connection and try again");
                            }
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            fontSize: "1vw",
                          }}
                        >
                          <img src={SyncIcon} style={{ height: "2.5vh" }} color={"#fff"} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Product Sync</span>
                        </div>
                      </button>
                    </div>
                    <div style={{ width: "100%", display: "none" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-pos"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        // onClick={() => {
                        //   handleKeyvalue("0");
                        //   setTableValue("POS");
                        // }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <POSIcon color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}>
                            Point of Sale
                          </span>
                        </div>
                      </button>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={information} style={{ height: "2.7vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}>
                            Information
                          </span>
                        </div>
                      </button>
                    </div>

                    <div style={{ width: "100%", marginBottom: "0.37rem", display: tillAccessData?.manualDiscount === "Y" ? "block" : "none" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                        onClick={() => {
                          parseInt(cart.total) > 0 && setManualDiscountModalVisible(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={discount} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}>
                            Manual Discount
                          </span>
                        </div>
                      </button>
                    </div>

                    {/* <div style={{ width: "100%", marginBottom: "0.37rem",display:tillAccessData?.manualDiscount==="Y"?"block":"none" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                        onClick={() => {
                          parseInt(cart.total) > 0 && setManualDiscountModalVisible(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={discount} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff",fontSize:"0.8vw" }}>
                            Discount
                          </span>
                        </div>
                      </button>
                    </div>

                    <div style={{ width: "100%", marginBottom: "0.37rem" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                        onClick={() => {
                          offerProductsList?.length > 0 && setDisplayOfferProductSelection(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={discount} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff",fontSize:"0.8vw" }}>
                            Offers
                          </span>
                        </div>
                      </button>
                    </div> */}

                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: "none",
                        fontSize: "1vw",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-return"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          if (cart.items.length > 0) {
                            openDisplayParkedBillModal();
                          } else {
                            handleKeyvalue("3");
                            setReturnFlag(true);
                            setIsPurchaseReturn(true);
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={ReturnIcon} style={{ height: "2.5vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}>
                            Return
                          </span>
                        </div>
                      </button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: tillAccessData?.cashManagement === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-cash-management"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          if (cart.items.length > 0) {
                            openDisplayParkedBillModal();
                          } else {
                            handleKeyvalue("2");
                            setIsCashManagement(true);
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <CashManagementIcon color={"#fff"} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Cash Management</span>
                        </div>
                      </button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: tillAccessData?.giftCard === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-sales-history"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          setGiftCardType("Gift Card");
                          setIsGiftCardFlag(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <SalesHistoryIcon color={"#fff"} />
                          {/* <span className="posIcon" style={{ color: "#fff", fontSize: "0.8vw" }}> */}
                          <span style={styles.screenStyles.mainScreen.posIcon}>Gift Card</span>
                        </div>
                      </button>
                    </div>
                    {/* <div style={{ width: "100%", marginBottom: "0.37rem",display:tillAccessData?.manualDiscount==="Y"?"block":"none" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                        onClick={() => {
                          setGiftCardType("Gift Voucher");
                          setIsGiftCardFlag(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={giftVoucher} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff",fontSize:"0.8vw" }}>
                          Gift Voucher
                          </span>
                        </div>
                      </button>
                    </div> */}
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: posConfig?.showBags === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          setAddToBagFlag(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={giftVoucher} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Bag</span>
                        </div>
                      </button>
                    </div>
                    {/* <div style={{ width: "100%", marginBottom: "0.37rem",display:tillAccessData?.manualDiscount==="Y"?"block":"none" }}>
                      <button
                        className="cartMenuItems"
                        id="sm-discount"
                        style={{ backgroundColor: "#2F3856", border: "1px solid #2F3856", borderRadius: "7px", height: "9.5vh" }}
                        onClick={() => {
                          setGiftCardType("Credit Note");
                          setIsGiftCardFlag(true);
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                          <img src={creditNote} style={{ height: "2.6vh" }} color={"#fff"} />
                          <span className="posIcon" style={{ color: "#fff",fontSize:"0.8vw" }}>
                          Credit Note
                          </span>
                        </div>
                      </button>
                    </div> */}

                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: posConfig?.showOrderSalesRep === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-parked-bill"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        // onClick={() => {
                        //   // handleKeyvalue("parked-bill");
                        //   // setIsSalesRep(true);
                        //   setSalesRepLine(true);
                        // }}
                        onClick={() => {
                          setOpenSalesRep({ flag: true, level: "order" });
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={user_add} style={{ color: "#fff", height: "2.5vh" }} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Sales Representative</span>
                        </div>
                      </button>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: tillAccessData?.parkedBills === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-parked-bill"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          handleKeyvalue("parked-bill");
                          setIsparkedBill(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <ParkedBillIcon color={"#fff"} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Parked Bills</span>
                        </div>
                      </button>
                    </div>
                    {/* <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-parked-bill"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          setIsSaleTypeFlag(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            fontFamily: "Inter"
                          } 
                          }>Select A Sales type</span>
                        </div>
                      </button>
                    </div> */}
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "0.37rem",
                        display: tillAccessData?.salesHistory === "Y" ? "block" : "none",
                      }}
                    >
                      <button
                        className="cartMenuItems"
                        id="sm-sales-history"
                        style={{
                          backgroundColor: themeJSON.cartMenuItemsBGColor || "#2F3856",
                          border: `1px solid ${themeJSON.cartMenuItemsBGColor || "#2F3856"}`,
                          borderRadius: "7px",
                          height: "9.5vh",
                        }}
                        onClick={() => {
                          if (cart.items.length > 0) {
                            clearReturnCartData();
                          } else {
                            handleKeyvalue("3");
                            setDateRange("");
                            setIsPurchaseReturn(true);
                            setReturnFlag(false);
                            setShowReturnPurchase(false);
                            setExpandedRowKeys([]);
                            setSearchhistoryInput("");
                            setOrderHistoryDetails([]);
                            setFiltersFlag(false);
                            let dateValue = [moment(new Date()), moment(new Date())];
                            // setDateRange(dateValue);
                            searchOrderHistory("orderDateSearchKey", dateValue, 0, false, true);
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <SalesHistoryIcon color={"#fff"} />
                          <span style={styles.screenStyles.mainScreen.posIcon}>Sales History</span>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "81.5%",
                      paddingTop: "3%",
                      display: showPaymentMethods && "none",
                    }}
                  >
                    {/* <div
                      style={{
                        background: "#fff",
                        height: "41vh",
                        width: "95%",
                        margin: "0% 1% 2.7% 3.5%",
                        border: "1px solid #fff",
                        borderRadius: "7px",
                        visibility: Object.keys(selectedProductInCart).length > 0 ? "visible" : "hidden",
                      }}
                    >
                      <Row gutter={[16]} style={{ paddingTop: "3vh" }}>
                        <Col span={7} style={{ height: "24vh" }}>
                          <img src="" />
                          <img
                            style={{
                              width: "100%",
                              height: "27vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              paddingTop: "1vh",
                              alignSelf: "center",
                              padding: "1vh 0 1vh 0.8vw",
                            }}
                            src={selectedProductInCart.imageurl || DefaultProductImage}
                            alt=""
                          />
                        </Col>
                        <Col span={17} style={{ height: "24vh" }}>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>
                            {selectedProductInCart["name"]?.toString().substring(0, 27)}...
                          </p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>{selectedProductInCart["productBrandName"]}</p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "2.5em", fontFamily: "Inter" }}>
                            {currenciesList[0].symbolRightSide === "N"
                              ? `${currenciesList[0].currSymbol}`
                              : currenciesList[0].symbolRightSide === "Y"
                              ? `${currenciesList[0].currSymbol}`
                              : ""}{" "}
                            {selectedProductInCart["realPrice"]}
                          </p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>Quantity : {selectedProductInCart["weight"]}</p>
                          <p style={{ margin: 0, fontWeight: "500", color: "#0F0718", fontSize: "1em", fontFamily: "Inter" }}>Current stock : {selectedProductInCart["stock"]}</p>
                          {stockList.length > 0 ? <CloseCircleOutlined onClick={() => setStockList([])} style={{ position: "absolute", top: "25vh", left: "18vw" }} /> : null}
                          <button
                            onClick={getProductNearByStock}
                            style={{backgroundColor: "#046307C2", border: "1px solid #fff", borderRadius: "7px", height: "5vh", width: "7vw", cursor: "pointer"}}
                           >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                color: "#fff",
                                fontSize: "1em",
                                fontWeight: 500,
                                fontFamily: "Inter",
                              }}
                             >
                              view stock
                            </div>
                          </button>
                          {stockList.map((record) => {
                            return (
                              <p style={{margin: 0, fontWeight: "500", fontSize: "1em", color: "#0F0718", fontFamily: "Inter"}}>
                                {record?.name || ""} {""} stock : {record?.warehouse?.storageBin?.totalQty || 0}
                              </p>
                            );
                          })}




                          <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Availability : {selectedProductInCart["stock"]}</p>
                             <p style={{margin:0,fontWeight:"500",fontSize:"2vh"}}>Offers Available : 0</p> 
                            <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Warehouse : {wareHouse.tillAccess.csBunit.mWarehouse.name} </p>




                        </Col>
                      </Row>
                      {stockList.length < 0 ? (
                        <div style={{ padding: "1vh 1vw" }}>
                          <p style={{ margin: 0, textAlign: "left", color: "#0F0718", fontWeight: "500" }}>Description:</p>
                          <p style={{ margin: 0, textAlign: "left", color: "#2F3856", fontSize: "1em", fontWeight: "500" }}>
                            {selectedProductInCart["description"]?.substring(0, 180)?.concat("....")}
                          </p>
                        </div>
                      ) : null}
                      {stockList.length > 0 ? (
                        <Table
                          style={{
                            width: "100%",
                            overflowY: "auto",
                            borderRadius: "10px",
                            padding: "0",
                            height: "14vh",
                            background: "#fff",
                          }}
                          size="small"
                          locale={{ emptyText: "No data" }}
                          pagination={false}
                          dataSource={[...stockList]}
                          className="sm-stock-table"
                          columns={[
                            {
                              title: "Store",
                              dataIndex: "store",
                              key: "store",
                              render: (text, record, index) => <div>{record?.warehouse?.name || ""}</div>,
                            },
                            {
                              title: "In Stock",
                              dataIndex: "stock",
                              key: "stock",
                              render: (text, record, index) => <div>{record?.warehouse?.storageBin?.totalQty || 0}</div>,
                              align: "right",
                            },
                          ]}
                        />

                      ) : null}
                    </div> */}
                    <div
                      style={{
                        background: "#fff",
                        height: "41vh",
                        width: "95%",
                        margin: "0% 1% 2.7% 3.5%",
                        border: "1px solid #fff",
                        borderRadius: "7px",
                        visibility: Object.keys(selectedProductInCart).length > 0 ? "visible" : "hidden",
                      }}
                    >
                      {stockList.length > 0 ? (
                        <div>
                          <img
                            src={Back}
                            onClick={() => setStockList([])}
                            id="sm-parked-bill-back"
                            style={{
                              height: "2vw",
                              paddingBottom: "0.5em",
                              cursor: "pointer",
                              marginLeft: "10px",
                              marginTop: "0.7em",
                            }}
                          />
                          <Table
                            style={{
                              width: "100%",
                              overflowY: "auto",
                              borderRadius: "10px",
                              padding: "0",
                              height: "36vh",
                              background: "#fff",
                              maxHeight: "34vh",
                              display: "block",
                            }}
                            size="small"
                            locale={{ emptyText: "No data" }}
                            pagination={false}
                            dataSource={[...stockList]}
                            className="sm-stock-table"
                            columns={[
                              {
                                title: "Store",
                                dataIndex: "store",
                                key: "store",
                                render: (text, record) => <div>{record?.warehouse?.name || ""}</div>,
                              },
                              {
                                title: "In Stock",
                                dataIndex: "stock",
                                key: "stock",
                                render: (text, record) => <div>{record?.warehouse?.storageBin?.totalQty || 0}</div>,
                                align: "right",
                              },
                            ]}
                          />
                        </div>
                      ) : (
                        <Row gutter={[16]} style={{ paddingTop: "3vh" }}>
                          <Col span={7} style={{ height: "24vh" }}>
                            <img
                              style={{
                                width: "100%",
                                height: "27vh",
                                objectFit: "contain",
                                borderRadius: "5px",
                                paddingTop: "1vh",
                                alignSelf: "center",
                                padding: "1vh 0 1vh 0.8vw",
                              }}
                              src={selectedProductInCart.imageurl || DefaultProductImage}
                              alt=""
                            />
                          </Col>
                          <Col span={17} style={{ height: "24vh", position: "relative" }}>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "1em",
                                fontFamily: "Inter",
                              }}
                            >
                              {selectedProductInCart["name"]?.toString().substring(0, 27)}
                              ...
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "1em",
                                fontFamily: "Inter",
                              }}
                            >
                              {selectedProductInCart["productBrandName"]}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "2.5em",
                                fontFamily: "Inter",
                              }}
                            >
                              {currenciesList[0].symbolRightSide === "N"
                                ? `${currenciesList[0].currSymbol}`
                                : currenciesList[0].symbolRightSide === "Y"
                                ? `${currenciesList[0].currSymbol}`
                                : ""}{" "}
                              {selectedProductInCart["realPrice"]}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "1em",
                                fontFamily: "Inter",
                              }}
                            >
                              Quantity : {selectedProductInCart["weight"]}
                            </p>
                            {/* <p
                              style={{
                                margin: 0,
                                fontWeight: "500",
                                color: "#0F0718",
                                fontSize: "1em",
                                fontFamily: "Inter",
                              }}
                            >
                              Current stock : {selectedProductInCart["stock"]}
                            </p> */}
                            {stockList.length > 0 ? (
                              <CloseCircleOutlined
                                onClick={() => setStockList([])}
                                style={{
                                  position: "absolute",
                                  top: "25vh",
                                  left: "18vw",
                                  cursor: "pointer",
                                }}
                              />
                            ) : null}
                            <button
                              onClick={handleViewStockClick}
                              style={{
                                backgroundColor: "#046307C2",
                                border: "1px solid #fff",
                                borderRadius: "7px",
                                height: "5vh",
                                width: "7vw",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  color: "#fff",
                                  fontSize: "1em",
                                  fontWeight: 500,
                                  fontFamily: "Inter",
                                }}
                              >
                                View Stock
                              </div>
                            </button>
                            {stockList.map((record) => {
                              return (
                                <p
                                  style={{
                                    margin: 0,
                                    fontWeight: "500",
                                    fontSize: "1em",
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {record?.name || ""} {""} stock : {record?.warehouse?.storageBin?.totalQty || 0}
                                </p>
                              );
                            })}

                            {/* <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Availability : {selectedProductInCart["stock"]}</p>
              <p style={{margin:0,fontWeight:"500",fontSize:"2vh"}}>Offers Available : 0</p> 
              <p style={{ margin: 0, fontWeight: "500", fontSize: "2vh" }}>Warehouse : {wareHouse.tillAccess.csBunit.mWarehouse.name} </p> */}
                          </Col>
                        </Row>
                      )}
                      {stockList.length < 0 ? (
                        <div style={{ padding: "1vh 1vw" }}>
                          <p
                            style={{
                              margin: 0,
                              textAlign: "left",
                              color: "#0F0718",
                              fontWeight: "500",
                            }}
                          >
                            Description:
                          </p>
                          <p
                            style={{
                              margin: 0,
                              textAlign: "left",
                              color: "#2F3856",
                              fontSize: "1em",
                              fontWeight: "500",
                            }}
                          >
                            {selectedProductInCart["description"]?.substring(0, 180)?.concat("....")}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <Row style={{ margin: " 2% 0 0 0 " }}>
                      <Input
                        className="productSearch sm-payment-search totalQtyInput"
                        style={{
                          borderRadius: "0.5vw",
                          height: "8.3vh",
                          margin: "0 1% 2.7% 3.5%",
                          borderRadius: "5px",
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "2.5em",
                          borderColor: "#fff",
                          color: "#2F3856",
                          fontFamily: "Inter",
                        }}
                        // prefix={<span style={{ color: "#0F0718", fontWeight: "600" }}>Amount Tendered</span>}
                        value={selectedProductInCart.weight}
                        id="sm-amount-input"
                        // type="number"
                        onChange={(e) => {
                          if (cart.items.length > 0 && Object.keys(selectedProductInCart).length > 0) {
                            onChangeTotalQuantity(e.target.value?.replace(/[^\d.]/g, ""));
                          }
                        }}
                        readOnly={cart.items.length > 0 && Object.keys(selectedProductInCart).length > 0 ? false : true}
                        placeholder="Enter Quantity"
                        onFocus={() => {
                          setIsInputFocused(true);
                        }}
                        onBlur={() => {
                          setIsInputFocused(false);
                        }}
                        // ref={quantityInputRef}
                        onPressEnter={enterTotalQty}
                      />
                    </Row>
                    <Row style={{ padding: "0 1% 0 3.5%" }} gutter={[8, 6]}>
                      <Col span={6}>
                        <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleTotalQty("1")}>
                          1
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleTotalQty("2")}>
                          2
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button3" className="amt-dial-btn-market" onClick={() => handleTotalQty("3")}>
                          3
                        </button>
                      </Col>
                      <Col span={6}>
                        <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleTotalQty("x")}>
                          <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleTotalQty("4")}>
                          4
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleTotalQty("5")}>
                          5
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleTotalQty("6")}>
                          6
                        </button>
                      </Col>
                      <Col span={6}>
                        {/* {!plusMinus?
                            <button id="sm-amount-buttonClear" onClick={()=>{setPlusMinus(true)}} className="amt-dial-btn-market">Quantity</button>: */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            id="sm-amount-addQuantity"
                            onClick={() => {
                              if (Object.keys(selectedProductInCart).length > 0) {
                                decreaseProductQty(selectedProductInCart);
                              }
                            }}
                            className="amt-dial-btn-market-qty"
                          >
                            -
                          </button>
                          <button
                            id="sm-amount-addQuantity"
                            onClick={async () => {
                              if (Object.keys(selectedProductInCart).length > 0) {
                                increaseProductQty(selectedProductInCart);
                                // if(selectedProductInCart?.bundleId !== undefined){
                                //   const productItem = await getProductData(selectedProductInCart.productId);
                                //   productItem.weight = 1;
                                //   productItem.isReturn = false;
                                //   increaseProductQty(productItem);
                                // }else{
                                //   increaseProductQty(selectedProductInCart);
                                // }
                              }
                            }}
                            className="amt-dial-btn-market-qty"
                          >
                            +
                          </button>
                        </div>
                      </Col>
                      <Col span={18}>
                        <Row gutter={[8, 6]}>
                          <Col span={8}>
                            <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleTotalQty("7")}>
                              7
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleTotalQty("8")}>
                              8
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalQty("9")}>
                              9
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleTotalQty("0")}>
                              0
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleTotalQty(".")}>
                              .
                            </button>
                          </Col>
                          <Col span={8}>
                            <button id="sm-amount-button." className="amt-dial-btn-market" onClick={() => handleTotalQty("clear")}>
                              Clear
                            </button>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-buttonEnter" className="amt-dial-btn-market2" onClick={enterTotalQty}>
                          Enter
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div
                    style={{
                      width: "81.5%",
                      paddingTop: "3%",
                      display: !showPaymentMethods && "none",
                    }}
                  >
                    <Row style={{ padding: "0 1% 0 3.5%", height: "18.5vh" }} gutter={5}>
                      {_.sortBy(showPayments, "sequenceNo").map((pm, ind) => {
                        if (
                          (posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty") ||
                          (!cart?.customer?.loyaltyLevel?.cwrLoyaltyLevelId && pm.name === "Loyalty") ||
                          (!cart.customer.iscredit && pm.name === "Credit") ||
                          pm.name.toLowerCase() === "cafe tips"
                        ) {
                          return null;
                        }
                        return (
                          <Col span={8} key={pm.finPaymentmethodId}>
                            <button
                              onClick={() => {
                                if (cart.items.length > 0) {
                                  paymentModalInputRef?.current?.select();
                                  paymentModalInputRef?.current?.focus();
                                  setNumb(0);
                                  let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails")) || {
                                    orderStartTime: "",
                                    orderEndTime: "",
                                    paymentStartTime: "",
                                  };
                                  if (orderTimeDetails.paymentStartTime === "") {
                                    orderTimeDetails = {
                                      ...orderTimeDetails,
                                      paymentStartTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                                    };
                                    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
                                  }
                                  if (pm.name.toLowerCase() === "card" && !pm.integratedPayment && posConfig.enablePaymentData === "Y") {
                                    setIsCardPaymentFlag(true);
                                  }
                                  setAmount((Math.abs(cart.total) - cart.paid).toFixed(precision));
                                  setSelectedPaymentMethod(pm);
                                }
                              }}
                              id={`sm-payment-${pm.name.toLowerCase()}`}
                              ref={ind === 0 ? paymentCardRef : null}
                              style={{
                                backgroundColor: themeJSON.paymentMethodsBGColor || "#046307C2",
                                border: `1px solid ${themeJSON.paymentMethodsBGColor || "#fff"}`,
                                borderRadius: "7px",
                                height: "8.6vh",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              tabIndex={0}
                              onKeyPress={(e) => (e.charCode === 13 ? setSelectedPaymentMethod(pm) : null)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  position: "relative",
                                  fontSize: "1vw",
                                }}
                              >
                                {pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? (
                                  <img
                                    src={ticSelect}
                                    style={{
                                      top: "-2vh",
                                      right: "0",
                                      height: "2.1vh",
                                      position: "absolute",
                                    }}
                                  />
                                ) : null}
                                <span className="payment" style={{ color: "#fff", fontSize: "1em" }}>
                                  {pm.name.toLowerCase() === "cash" ? (
                                    <>
                                      {currenciesList[0] ? currenciesList[0].currSymbol : ""}
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "card" ? (
                                    <>
                                      <img src={cardType} style={{ height: "2.5vh" }} color={"#fff"} />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "upi" ? (
                                    <>
                                      <img src={UPI} style={{ height: "2.5vh" }} color={"#fff"} />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "credit" ? (
                                    <>
                                      <img src={Credit} style={{ height: "2.5vh" }} color={"#fff"} />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "loyalty" ? (
                                    <>
                                      <img
                                        src={Loyality}
                                        style={{
                                          height: "2.5vh",
                                          left: "0.5em",
                                        }}
                                        color={"#fff"}
                                      />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "gift card" ? (
                                    <>
                                      <img
                                        src={Gift}
                                        style={{
                                          height: "2.5vh",
                                          marginBottom: "0.2em",
                                        }}
                                        color={"#fff"}
                                      />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "voucher" ? (
                                    <>
                                      <img
                                        src={giftVoucher}
                                        style={{
                                          height: "2.5vh",
                                          marginBottom: "0.2em",
                                        }}
                                        color={"#fff"}
                                      />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : pm.name.toLowerCase() === "credit note" ? (
                                    <>
                                      <img src={creditNote} style={{ height: "2.5vh" }} color={"#fff"} />
                                      &nbsp;{pm.name}
                                    </>
                                  ) : (
                                    <>{pm.name}</>
                                  )}
                                </span>
                              </div>
                            </button>
                          </Col>
                        );
                      })}
                      {cart.layAway === "Y" && posConfig.advanceLayaway === "Y" ? (
                        <Col
                          span={8}
                          onClick={() => {
                            if (cart?.advancePayment > 0) {
                              if (parseFloat(Math.abs(cart.total)) > parseFloat(cart.paid)) {
                                if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
                                  {
                                    message.warning(`${t("cash_payment_error")}`);
                                  }
                                } else {
                                  requestPayment(selectedPaymentMethod, amount);
                                }
                              } else {
                                processOrder();
                              }
                            } else {
                              processOrder("layaway");
                            }
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "#046307C2",
                              border: "1px solid #fff",
                              borderRadius: "7px",
                              height: "8.6vh",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckOutlined style={{ fontSize: "3vh", color: "#fff" }} />
                            </div>
                          </button>
                        </Col>
                      ) : null}

                      {filteredPaymentMethods.length > 5 && (
                        <Col span={8}>
                          <button
                            onClick={isShowingAll ? showPreviousItems : showMoreItems}
                            style={{
                              backgroundColor: "#046307C2",
                              border: "1px solid #fff",
                              borderRadius: "7px",
                              height: "8.6vh",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <img style={{ height: "2.5vh", left: "0.5em" }} color={"#fff"} />
                              <span className="payment" style={{ color: "#fff", marginLeft: "0.25em" }}>
                                ...
                              </span>
                            </div>
                          </button>
                        </Col>
                      )}
                    </Row>
                    <div
                      style={{
                        background: "#fff",
                        height: "23vh",
                        width: "95%",
                        margin: "0.75% 1% 2.7% 3.5%",
                        border: "1px solid #fff",
                        borderRadius: "7px",
                      }}
                    >
                      <Row
                        style={{
                          height: "3vh",
                          marginTop: "3%",
                          fontSize: "1vw",
                        }}
                      >
                        <Col span={12} style={styles.screenStyles.mainScreen.card}>
                          <p style={{ margin: "0" }}>Amount Tendered</p>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "1vw",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                  &nbsp; */}
                          <p
                            style={{
                              textAlign: "right",
                              margin: "0",
                              fontWeight: "500",
                              color: "#0F0718",
                              fontSize: "1em",
                            }}
                          >
                            {parseFloat(cart.total).toFixed(2)}
                          </p>
                          {/* <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> */}
                        </Col>
                      </Row>

                      <Row style={{ height: "3vh", fontSize: "1vw" }}>
                        <Col span={12} style={styles.screenStyles.mainScreen.card}>
                          <p style={{ margin: "0" }}>Change</p>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "1vw",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                           */}
                          <p
                            style={{
                              textAlign: "right",
                              margin: "0",
                              fontWeight: "500",
                              color: "#0F0718",
                              fontSize: "1em",
                            }}
                          >
                            {cart.paid + parseFloat(amount) - Math.abs(cart.total) >= 0
                              ? (cart.paid + parseFloat(amount) - Math.abs(Math.abs(cart.total) + Math.abs(overPayedAmount))).toFixed(2)
                              : "0.00"}
                            {/* {parseFloat(cart.paid + parseFloat(amount) - cart.isReturn ? Math.abs(cart.total) : cart.total).toFixed(2)} */}
                          </p>
                          {/* <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> */}
                        </Col>
                      </Row>

                      <Row style={{ height: "3vh", fontSize: "1vw" }}>
                        <Col span={12} style={styles.screenStyles.mainScreen.card}>
                          <p style={{ margin: "0" }}>Overpayment</p>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "1vw",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                           */}
                          <p
                            style={{
                              textAlign: "right",
                              margin: "0",
                              fontWeight: "500",
                              color: "#0F0718",
                              fontSize: "1em",
                            }}
                          >
                            {Math.abs(overPayedAmount).toFixed(2)}
                            {/* {parseFloat(cart.paid + parseFloat(amount) - cart.isReturn ? Math.abs(cart.total) : cart.total).toFixed(2)} */}
                          </p>
                          {/* <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> */}
                        </Col>
                      </Row>

                      <Row style={{ height: "3vh", fontSize: "1vw" }}>
                        <Col span={12} style={styles.screenStyles.mainScreen.card}>
                          <p style={{ margin: "0" }}>Remaining Due</p>
                        </Col>
                        <Col
                          span={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "1vw",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                           */}
                          <p
                            style={{
                              textAlign: "right",
                              margin: "0",
                              fontWeight: "500",
                              color: "#0F0718",
                              fontSize: "1em",
                            }}
                          >
                            {parseFloat(Math.abs(cart.total)) >= parseFloat(cart.paid) ? parseFloat(Math.abs(cart.total) - cart.paid).toFixed(2) : 0}
                          </p>
                          {/* <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> */}
                        </Col>
                      </Row>
                      <hr style={{ margin: "1vh 1vw" }} />
                      <Scrollbars style={{ height: "6.3vh" }}>
                        {cart?.advancePayment > 0 &&
                          cart.layAway === "Y" &&
                          cart?.paymentData?.map((item) => {
                            return (
                              <Row style={{ fontSize: "1vw" }}>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "0.8vw",
                                    fontWeight: "450",
                                    textAlign: "left",
                                  }}
                                >
                                  <p style={{ margin: "0" }}>{item.name.toLowerCase() === "card" ? `${item.name} - ${item.authorization}` : item.name}</p>
                                </Col>
                                <Col
                                  span={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: "1vw",
                                    textAlign: "right",
                                    justifyContent: "end",
                                  }}
                                >
                                  {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                          &nbsp; */}
                                  <p
                                    style={{
                                      textAlign: "right",
                                      margin: "0",
                                      fontWeight: "500",
                                      paddingRight: "1.2vw",
                                    }}
                                  >
                                    {" "}
                                    {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                    &nbsp;{parseFloat(item.amount).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                  </p>
                                </Col>
                              </Row>
                            );
                          })}
                        {cart.payments.map((item) => {
                          return (
                            <Row style={{ fontSize: "1vw" }}>
                              <Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "0.8vw",
                                  fontWeight: "450",
                                  textAlign: "left",
                                }}
                              >
                                <p style={{ margin: "0" }}>{item.name.toLowerCase() === "card" ? `${item.name} - ${item.authorization}` : item.name}</p>
                              </Col>
                              <Col
                                span={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingRight: "1vw",
                                  textAlign: "right",
                                  justifyContent: "end",
                                }}
                              >
                                {/* {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                          &nbsp; */}
                                <p
                                  style={{
                                    textAlign: "right",
                                    margin: "0",
                                    fontWeight: "500",
                                    paddingRight: "1.2vw",
                                  }}
                                >
                                  {" "}
                                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                  &nbsp;{parseFloat(item.amount).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                                </p>
                                <img
                                  src={DeleteProduct}
                                  onClick={() => handleDeletePayment(item)}
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    cursor: "pointer",
                                    marginLeft: "1vw",
                                    position: "absolute",
                                    right: 5,
                                    top: "15%",
                                  }}
                                />
                                {/* <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span> */}
                              </Col>
                            </Row>
                          );
                        })}
                      </Scrollbars>
                    </div>
                    <Row style={{ margin: "0 0 0 0 " }}>
                      <Input
                        className="productSearch sm-payment-search totalInput"
                        style={{
                          borderRadius: "0.5vw",
                          height: "7.3vh",
                          margin: "0 1% 2.7% 3.5%",
                          borderRadius: "5px",
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "2.5em",
                          borderColor: "#fff",
                          color: "#2F3856",
                        }}
                        // prefix={<span style={{ color: "#0F0718", fontWeight: "600" }}>Amount Tendered</span>}
                        value={amount}
                        // type="number"
                        id="sm-total-amount-input"
                        onChange={onChangeAmount}
                        placeholder="Enter Amount"
                        ref={paymentModalInputRef}
                        onFocus={() => {
                          setIsInputFocused(true);
                        }}
                        onBlur={() => {
                          setIsInputFocused(false);
                        }}
                        readOnly={cart.items.length > 0 ? false : true}
                        disabled={tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N" ? true : false}
                        onPressEnter={() => {
                          if (parseFloat(Math.abs(cart.total)) > parseFloat(cart.paid)) {
                            if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
                              {
                                message.warning(`${t("cash_payment_error")}`);
                              }
                            } else {
                              requestPayment(selectedPaymentMethod, amount);
                            }
                          } else {
                            processOrder();
                          }
                        }}
                      />
                    </Row>
                    {denaminationsKeyboard && selectedPaymentMethod?.name?.toLowerCase() === "cash" ? (
                      <Row style={{ padding: "0 1% 0 3.5%" }} gutter={6}>
                        <Col span={6}>
                          <button id="sm-amount-button1" className="amt-dial-btn-market" onClick={() => handleCashPayment(500)}>
                            500
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button2" className="amt-dial-btn-market" onClick={() => handleCashPayment(1000)}>
                            1000
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button3" className="amt-dial-btn-market"></button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market" id="sm-amount-buttonRemove" onClick={() => handleAmount("x")}>
                            <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button4" className="amt-dial-btn-market" onClick={() => handleCashPayment(50)}>
                            50
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button5" className="amt-dial-btn-market" onClick={() => handleCashPayment(100)}>
                            100
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button6" className="amt-dial-btn-market" onClick={() => handleCashPayment(200)}>
                            200
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-buttonClear" className="amt-dial-btn-market" onClick={() => setDenaminationsKeyboard(!denaminationsKeyboard)}>
                            1,2,3...
                          </button>
                        </Col>
                        <Col span={18} style={{ marginTop: "0.35rem" }}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button id="sm-amount-button7" className="amt-dial-btn-market" onClick={() => handleCashPayment(5)}>
                                5
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-market" onClick={() => handleCashPayment(10)}>
                                10
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleCashPayment(20)}>
                                20
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" onClick={() => handleCashPayment(0.25)}>
                                0.25
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-market" onClick={() => handleCashPayment(0.5)}>
                                0.5
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button." className="amt-dial-btn-market" onClick={() => handleCashPayment(1)}>
                                1
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6} style={{ marginTop: "0.35rem" }}>
                          <button
                            id="sm-amount-buttonEnter"
                            className="amt-dial-btn-market2"
                            onClick={() => {
                              const isGiftCardOrLoyalty =
                                selectedPaymentMethod?.name?.toLowerCase() !== "loyalty" &&
                                selectedPaymentMethod?.name?.toLowerCase() !== "voucher" &&
                                selectedPaymentMethod?.name?.toLowerCase() !== "gift card";
                              if (isGiftCardOrLoyalty) {
                                if (parseFloat(Math.abs(cart.total)) > parseFloat(Math.abs(cart.paid))) {
                                  if (parseFloat(amount) % 0.25 !== 0) {
                                    message.warning("Payment must be a multiple of 0.25");
                                  } else {
                                    requestPayment(selectedPaymentMethod, amount);
                                    if (parseFloat(Math.abs(cart.total)) > parseFloat(amount)) {
                                      PoleDisplay(cart, "Show due payment", amount);
                                    }
                                  }
                                } else {
                                  processOrder();
                                }
                              }
                            }}
                          >
                            Enter
                          </button>
                        </Col>
                      </Row>
                    ) : (
                      <Row style={{ padding: "0 1% 0 3.5%" }} gutter={[8, 6]}>
                        <Col span={6}>
                          <button id="sm-amount-button1" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("1")}>
                            1
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button2" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("2")}>
                            2
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button3" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("3")}>
                            3
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} id="sm-amount-buttonRemove" onClick={() => handleAmount("x")}>
                            <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button4" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("4")}>
                            4
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button5" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("5")}>
                            5
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button6" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("6")}>
                            6
                          </button>
                        </Col>
                        <Col span={6}>
                          <button
                            id="sm-amount-buttonClear"
                            className="amt-dial-btn-market"
                            style={{ background: themeJSON?.keyBoardColor }}
                            onClick={() => setDenaminationsKeyboard(!denaminationsKeyboard)}
                          >
                            1,2,3...
                          </button>
                        </Col>
                        <Col span={18}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button id="sm-amount-button7" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("7")}>
                                7
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("8")}>
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("9")}>
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("0")}>
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount("00")}>
                                00
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button." className="amt-dial-btn-market" style={{ background: themeJSON?.keyBoardColor }} onClick={() => handleAmount(".")}>
                                .
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <button
                            id="sm-amount-buttonEnter"
                            className="amt-dial-btn-market2"
                            style={{ background: themeJSON?.keyBoardColor }}
                            onClick={() => {
                              const isGiftCardOrLoyalty =
                                selectedPaymentMethod?.name?.toLowerCase() !== "loyalty" &&
                                selectedPaymentMethod?.name?.toLowerCase() !== "voucher" &&
                                selectedPaymentMethod?.name?.toLowerCase() !== "gift card";
                              if (isGiftCardOrLoyalty) {
                                if (parseFloat(Math.abs(cart.total)) > parseFloat(cart?.paid)) {
                                  if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
                                    {
                                      message.warning(`${t("cash_payment_error")}`);
                                    }
                                  } else {
                                    setNumb(0);
                                    // Handle successful entry, e.g., submit the amount
                                    requestPayment(selectedPaymentMethod, amount);
                                  }
                                } else {
                                  processOrder();
                                }
                              }
                            }}
                          >
                            Enter
                          </button>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Drawer
              placement="bottom"
              height="75vh"
              title={null}
              closeIcon={null}
              className="filter"
              headerStyle={{ height: 0 }}
              bodyStyle={{ paddingTop: 0 }}
              visible={isProductsVisible}
              closable={true}
              onClose={closeProductPanel}
              footer={null}
            >
              <Row
                style={{
                  paddingTop: isQtyUpdate ? 0 : "1.7%",
                  width: "100%",
                  paddingLeft: "1vw",
                }}
              >
                <ProductPanelSM
                  checkIsManualWeight={checkIsManualWeight}
                  setIsSearchProducts={setIsSearchProducts}
                  getMoreProducts={getMoreProducts}
                  productItems={productItems}
                  setProductItems={setProductItems}
                  selectProductInCart={selectProductInCart}
                  setProductArray={setProductArray}
                  isProductsFilter={isProductsFilter}
                  getSearchedProducts={getSearchedProducts}
                  productListCardRef={productListCardRef}
                  productsDisplay={productsDisplay}
                  setIsProductsVisible={setIsProductsVisible}
                  pickProduct={pickProduct}
                  filterDrawer={filterDrawer}
                  setFilterDrawer={setFilterDrawer}
                  productSearchInput={productSearchInput}
                  setProductSearchInput={setProductSearchInput}
                  setOpen={setOpen}
                  keyboardType={keyboardType}
                  setKeyboardType={setKeyboardType}
                  productSearchInputRef={productSearchInputRef}
                  clearProductSearchResults={clearProductSearchResults}
                  productsData={productsData}
                  isProductsVisible={isProductsVisible}
                  productSearchRef={productSearchRef}
                  handleSelectProduct={handleSelectProduct}
                  productsList={productsList}
                  setProductsList={setProductsList}
                  setLayoutName={setLayoutName}
                  productPanelSearchRef={productPanelSearchRef}
                />
              </Row>
            </Drawer>
            {isQtyUpdate ? (
              <QuantityUpdate
                selectedProductInCart={selectedProductInCart}
                addProduct={addProduct}
                setIsQtyUpdate={setIsQtyUpdate}
                isQtyUpdate={isQtyUpdate}
                increaseProductQty={increaseProductQty}
                decreaseProductQty={decreaseProductQty}
                removeProductModal={removeProductModal}
                setIsSearchProducts={setIsSearchProducts}
              />
            ) : (
              ""
            )}
            <ProductPanelSM
              checkIsManualWeight={checkIsManualWeight}
              setIsSearchProducts={setIsSearchProducts}
              getMoreProducts={getMoreProducts}
              productItems={productItems}
              setProductItems={setProductItems}
              selectProductInCart={selectProductInCart}
              setProductArray={setProductArray}
              isProductsFilter={isProductsFilter}
              getSearchedProducts={getSearchedProducts}
              productListCardRef={productListCardRef}
              productsDisplay={productsDisplay}
              setIsProductsVisible={setIsProductsVisible}
              pickProduct={pickProduct}
              filterDrawer={filterDrawer}
              setFilterDrawer={setFilterDrawer}
              productSearchInput={productSearchInput}
              setProductSearchInput={setProductSearchInput}
              setOpen={setOpen}
              keyboardType={keyboardType}
              setKeyboardType={setKeyboardType}
              productSearchInputRef={productSearchInputRef}
              clearProductSearchResults={clearProductSearchResults}
              productsData={productsData}
              productSearchRef={productSearchRef}
              handleSelectProduct={handleSelectProduct}
              productsList={productsList}
              setProductsList={setProductsList}
              setLayoutName={setLayoutName}
              productPanelSearchRef={productPanelSearchRef}
            />
            <Drawer
              placement="bottom"
              height="53vh"
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "2vh",
                    fontSize: "1vw",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.5em",
                      marginTop: "3vh",
                      fontWeight: "500",
                      color: "#0F0718",
                      flex: 1,
                      textAlign: "center",
                    }}
                  >
                    {selectedProductForNotes !== null ? "Add Notes for Item" : "Add Notes for Order"}
                  </p>
                  <img
                    src={Close}
                    onClick={() => {
                      setNotesPopup(false);
                      setIsInputFocused(false);
                    }}
                    // style={{ marginLeft: "auto", paddingBottom: "4vh", cursor: "pointer" }}
                    style={{
                      marginLeft: "auto",
                      paddingBottom: "4vh",
                      paddingTop: "2vh",
                      cursor: "pointer",
                      width: "1.5vw",
                    }}
                  />
                </div>
              }
              closeIcon={null}
              className="filter"
              bodyStyle={{ paddingTop: 0 }}
              visible={notesPopup}
              closable={true}
              onClose={() => {
                setNotesPopup(false);
                setIsInputFocused(false);
              }}
              footer={null}
            >
              <Row style={{ padding: "0 5vw", fontSize: "1vw" }}>
                <Col span={11}>
                  <TextArea
                    id="notes-input"
                    ref={notesInputRef}
                    style={{ resize: "none" }}
                    maxLength={200}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => onChangeInputKeyboard(e)}
                    value={notesValue}
                    rows={11}
                  />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "0.8em",
                      color: "#929098",
                      fontWeight: 500,
                    }}
                  >
                    Max Characters : 200
                  </span>
                </Col>
                <Col span={12} offset={1}>
                  <Keyboard
                    theme={"hg-theme-default aprvl"}
                    onChange={(input) => onChange(input)}
                    keyboardRef={(r) => (keyboardRef.current = r)}
                    // onChange={(val)=>{input === 1 ? setInputValue(val) && console.log("val1",val) : setNotes(val) && console.log("val2",val) }}
                    onKeyPress={(button) => {
                      // console.log(button,"============bitton")
                      if (button === "{shift}" || button === "{lock}") handleShift();
                      if (button === "{number}") {
                        setLayoutName("number");
                      }
                      if (button === "{numbers}") {
                        setLayoutName("numbers");
                      }
                      if (button === "{small}") {
                        setLayoutName("shift");
                      }
                      if (button === "{caps}") {
                        setLayoutName("default");
                      }
                      if (button === "{enter}") {
                        onAddNotes();
                      }
                    }}
                    layoutName={layoutName}
                    display={{
                      "{abc}": "ABC",
                      "{smileys}": "\uD83D\uDE03",
                      "{back}": "Enter",
                      "{numbers}": "123",
                      "{number}": "123",
                      "{bksp}": "backspace",
                      "{backspace}": "⌫",
                      "{shift}": "⇧",
                      "{tab}": "tab",
                      "{lock}": "lock",
                      "{enter}": "Enter",
                      "{space}": "Space",
                      "{caps}": "ABC",
                      "{small}": "abc",
                    }}
                    layout={{
                      shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                      numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                      number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                      default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
                    }}
                  />
                </Col>
              </Row>
            </Drawer>
            {/* Parked Bills modal start */}
            {/* <Modal centered visible={isParkedBill} width={"60%"} footer={null} title="Parked Bills" bodyStyle={{ padding: "1% 2%" }} onCancel={() => setIsparkedBill(false)}> */}

            {/* </Modal> */}
            {/* Parked Bills modal end */}

            {/* Sales History modal start */}
            {/* <Modal
        centered
        visible={isPurchaseReturn}
        width={"60%"}
        footer={null}
        title={returnFlag ? "Sales Return" : "Sales History"}
        bodyStyle={{ padding: "1% 2%" }}
        onCancel={() => setIsPurchaseReturn(false)}
      >

      </Modal> */}
            {/* Sales History modal end */}

            {/* Cash Management modal start */}
            {/* <Modal centered visible={isCashMangement} width={"60%"} footer={null} title="Parked Bills" bodyStyle={{ padding: "1% 2%" }} onCancel={() => setIsCashManagement(false)}>
   
      </Modal> */}
            {/* Cash Management modal end */}
            <RetailModals {...props} selectBagRef={selectBagRef} />
          </div>
          <div
            style={{
              backgroundColor: colorVariable ? colorVariable : "#f3f4f9",
              height: "100vh",
              width: "100%",
              display: isLocked ? "block" : "none",
            }}
          >
            {/* <div style={{display:"grid",marginTop:"3rem",position:"relative"}}>
            <div style={{maxWidth:"27rem",margin:"0 auto",background:"#fff",border:'1px solid #e2e2e2'}}>
               <img src={LockScreen} alt="" />
              <Row >
                <Col>
                <label >Username</label>
                <Input
                style={{width:"100%"}}
                  // className="mb-4 w-full h-[40px] sm:h-[40px] rounded px-3 py-1 text-[#101828] placeholder:text-[#98A2B3] border-[0.5px] border-[#DaDaDa] text-xs ff-inter font-normal outline-none"
                  // required
                  type="text"
                  // placeholder="Enter Email"
                  // value={email}
                  id="username"
                  name="username"
                  // onChange={(ev) => { setEmail(ev.target.value) }}
                />
                </Col>
              </Row>
            </div>
          </div> */}
            <Row style={{ height: "9vh", background: "", padding: "0.8% 0" }}>
              <Col span={6}>
                <img
                  src={POSLogo}
                  style={{
                    height: "2.8vw",
                    paddingLeft: "1vw",
                    paddingTop: "1vh",
                  }}
                />
              </Col>
              <Col
                span={18}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  paddingRight: "1%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    padding: "0.5vw",
                    border: "1px solid #3C424B",
                    borderRadius: "50%",
                  }}
                >
                  <img src={userIcon} style={{ height: "2.7vh" }} />
                </div>
                <span
                  style={{
                    color: "#0F0718",
                    fontSize: "1vw",
                    fontFamily: "Inter",
                    padding: "0 2%",
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  Till ID : {JSON.parse(localStorage.getItem("tillValue")).name}
                </span>
                <div style={{ paddingRight: "2%" }}>
                  <img src={isOnline ? Online : Ofline} style={{ height: "3.5vh", cursor: "pointer" }} /> &nbsp;
                </div>
                {/* <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{formattedDateTime}</div>
              <div style={{ fontWeight: 400, fontFamily: "Inter", fontSize: "1.2vw" }}>{clock1}</div> */}
              </Col>
            </Row>
            <Row style={{ paddingTop: "17vh" }}>
              <Col span={8} />
              <Col
                span={8}
                style={{
                  background: "#fff",
                  borderRadius: "5px",
                  height: "45vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 4px 4px 0px #00000033",
                }}
              >
                <img
                  src={LockScreen}
                  style={{
                    marginBottom: "0.2vh",
                    marginTop: "-17vh",
                    width: "25%",
                  }}
                  alt=""
                />
                <span style={{ fontSize: "14px", fontWeight: 400 }}>Screen Locked </span>
                <Form layout="vertical" style={{ width: "80%" }}>
                  <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <label style={{ fontWeight: 500, fontSize: "15px" }}>Username</label>
                    <Form.Item>
                      <Input
                        value={userData.user}
                        style={{
                          width: "100%",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col  style={{ padding: "0 2vw", marginTop: "2.5vh" }}> */}
                  {/* <label style={{fontWeight:500,fontSize:"15px"}}>Password</label>
                    <Form.Item  >
                      <Input value={password} type="password" onPressEnter={() => handleLogin()} onChange={(e)=>{setPassword(e.target.value)}} style={{width:"100%",padding:"10px"}} />
                    </Form.Item>
                  </Col> */}
                  <Col style={{ padding: "0 2vw", marginTop: "2.5vh" }}>
                    <Button
                      style={{
                        background: "#2F3856",
                        fontWeight: 500,
                        fontSize: "18px",
                        color: "#fff",
                        width: "100%",
                        height: "7vh",
                        borderRadius: "5px",
                        border: "none",
                      }}
                      onClick={handleLogin}
                    >
                      Log In
                    </Button>
                  </Col>
                </Form>
              </Col>

              <Col span={8} />
            </Row>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundColor: colorVariable ? colorVariable : "#f3f4f9",
              height: "100vh",
              width: "100%",
            }}
          >
            <Row style={{ height: "9vh", background: "", padding: "0.5% 0" }}>
              <Col span={6}>
                <img
                  src={POSLogo}
                  style={{
                    height: "2.8vw",
                    paddingLeft: "1vw",
                    paddingTop: "1vh",
                  }}
                />
              </Col>
              <Col
                span={18}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  paddingRight: "1vw",
                  fontSize: "1vw",
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontFamily: "Inter",
                    fontSize: "1.2em",
                    color: "#0F0718",
                  }}
                >
                  {formattedDateTime}
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontFamily: "Inter",
                    fontSize: "1.2em",
                    color: "#0F0718",
                  }}
                >
                  {clock1}
                </div>
              </Col>
            </Row>
            <div style={{ display: "flex", height: "90vh" }}>
              <div
                style={{
                  width: "48%",
                  background: "#fff",
                  height: "83vh",
                  margin: "1.5%",
                  marginRight: 0,
                  borderRadius: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "1vw",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "13vw",
                      height: "13vw",
                    }}
                  >
                    <img
                      src={leftLoading}
                      alt="Loading"
                      style={{
                        width: "100%",
                        height: "100%",
                        // transform: `rotate(${rotation}deg)`,
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "1.5em",
                        fontWeight: "500",
                        color: "#0F0718",
                      }}
                    >
                      {percent}
                    </div>
                  </div>
                  <p
                    style={{
                      color: "#0F0718",
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "2.5em",
                      marginBottom: 0,
                    }}
                  >
                    Till loading in process
                  </p>
                  {/* <p style={{color:"#D73A48",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw",marginBottom:0}}>{formatTime(time)}</p>
           <p style={{color:"#0F0718",fontFamily:"Inter",fontWeight:500,fontSize:"1.5vw"}}>Minutes Left</p> */}
                </div>
              </div>
              <div
                style={{
                  width: "48%",
                  height: "83vh",
                  background: "#fff",
                  margin: "1.5%",
                  borderRadius: "10px",
                  color: "#0F0718",
                }}
              >
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    marginTop: "17vh",
                    marginBottom: 0,
                    padding: "0 2.5vw",
                    color: "#0F0718",
                  }}
                >
                  Product Categories
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{
                    padding: "0 2.5vw",
                    fontSize: "1em",
                    color: "#0F0718",
                  }}
                  percent={productCategoryPercentage}
                  status="active"
                />
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    marginTop: "7vh",
                    marginBottom: 0,
                    padding: "0 2.5vw",
                    color: "#0F0718",
                  }}
                >
                  Products
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{
                    padding: "0 2.5vw",
                    fontSize: "1em",
                    color: "#0F0718",
                  }}
                  percent={productPercentage}
                  status="active"
                />
                <p
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    marginTop: "7vh",
                    marginBottom: 0,
                    padding: "0 2.5vw",
                    color: "#0F0718",
                  }}
                >
                  Promotions
                </p>
                <Progress
                  strokeColor={{
                    from: "#2F3856",
                    to: "#2F3856",
                  }}
                  // showInfo={false}
                  style={{
                    padding: "0 2.5vw",
                    fontSize: "1em",
                    color: "#0F0718",
                  }}
                  percent={promotionPercentage}
                  status="active"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {open && showVirtualKeyboard && (
        <Drawer placement="bottom" closable={false} bodyStyle={{ paddingBottom: 0 }} onClose={onClose} height="47vh" visible={open && showVirtualKeyboard} mask={false}>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1vh" }}>
            <img
              src={Close}
              onClick={() => {
                setOpen(false);
                setLayoutName("default");
              }}
              style={{
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
          <div style={{ padding: "0 5vw" }}>
            <Keyboard
              keyboardRef={(r) => {
                keyboard.current = r;
                if (keyboardType.product) {
                  productSearchInputRef.current = r;
                } else if (keyboardType.parkedBill) {
                  parkedBillRef.current = r;
                } else if (keyboardType.salesHistoryCus) {
                  searchCustomersRef.current = r;
                } else if (keyboardType.salesHistoryDoc) {
                  searchDocumentNoREf.current = r;
                } else if (keyboardType.productSearch) {
                  productSearchRef.current = r;
                } else if (keyboardType.giftCardSearch) {
                  giftCardRef.current = r;
                } else if (keyboardType.addCustomer) {
                  const inputElement = document.getElementById(currentInput); // Or access the correct element
                  if (inputElement) {
                    refs[currentInput].current = inputElement;
                  }
                }
              }}
              layoutName={layoutName}
              onChange={(val) => {
                if (keyboard.current) {
                  keyboard.current.setInput(val);
                }
                if (keyboardType.product) {
                  const productSearchInput = document.getElementById("sm-product-search");
                  if (productSearchInput) {
                    setTimeout(() => {
                      productSearchInput.focus();
                      // const cursorPosition = productSearchInput.selectionStart;
                      // productSearchInput.setSelectionRange(cursorPosition, cursorPosition);
                    }, 20);
                  }
                  setProductSearchInput(val);
                } else if (keyboardType.parkedBill) {
                  const parkedBillSearchInput = document.getElementById("sm-parked-bill-customer");
                  if (parkedBillSearchInput) {
                    setTimeout(() => {
                      parkedBillSearchInput.focus();
                      // const cursorPosition = parkedBillSearchInput.selectionStart;
                      // parkedBillSearchInput.setSelectionRange(cursorPosition, cursorPosition);
                    }, 20);
                  }
                  setParkedBillSearchInput(val);
                } else if (keyboardType.salesHistoryCus) {
                  const searchHistoryInput = document.getElementById("sm-salesHistory-customer");
                  if (searchHistoryInput) {
                    setTimeout(() => {
                      searchHistoryInput.focus();
                      // const cursorPosition = searchHistoryInput.selectionStart;
                      // searchHistoryInput.setSelectionRange(cursorPosition, cursorPosition);
                    }, 20);
                  }
                  // setSalesHistoryCustomerSearchInput(val);
                  setSearchhistoryInput(val);
                } else if (keyboardType.salesHistoryDoc) {
                  setSalesHistoryDocumentNoSearchInput(val);
                } else if (keyboardType.productSearch) {
                  if (productPanelSearchRef && productPanelSearchRef.current) {
                    setTimeout(() => {
                      productPanelSearchRef.current.focus();
                    }, 10);
                  }
                  setProductSearchInput(val);
                } else if (keyboardType.searchCus) {
                  if (customerSearchInputRef && customerSearchInputRef.current) {
                    setTimeout(() => {
                      customerSearchInputRef.current.focus();
                    }, 20);
                  }
                  setCustomerSearchInput(val);
                } else if (keyboardType.giftCardSearch) {
                  if (selectBagRef && selectBagRef?.current) {
                    setTimeout(() => {
                      selectBagRef?.current?.focus();
                    }, 20);
                  }
                  setSearchTerm(val);
                } else if (keyboardType.addCustomer) {
                  if (currentInput) {
                    if (refs[currentInput] && refs[currentInput].current) {
                      setTimeout(() => {
                        if (refs[currentInput] && refs[currentInput].current) {
                          refs[currentInput].current.focus();
                        } else {
                          // console.error(`Ref for ${currentInput} is not set correctly.`);
                        }
                      }, 50);
                    }
                    setInputValues((prevValues) => {
                      const updatedValues = { ...prevValues, [currentInput]: val };
                      return updatedValues;
                    });
                  }
                }
              }}
              onKeyPress={onKeyPress}
              display={{
                "{bksp}": "⌫",
                "{numbers}": "123",
                "{shift}": "⇧",
                "{enter}": "Enter",
                "{space}": "Space",
                "{number}": "123",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} @ {space} . {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , _ ! ` {bksp}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , _ ! ` {bksp}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} @ {space} . {enter}"],
              }}
            />
          </div>
        </Drawer>
      )}

      <Drawer
        placement="left"
        width="20%"
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={POSLogo} style={{ height: "2vw" }} />
            {/* <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Add Discount</p> */}
            <img
              src={Close}
              onClick={() => setTillLink(false)}
              // style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }}
              style={{
                marginLeft: "auto",
                // paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        bodyStyle={{ paddingTop: 0 }}
        visible={tillLink}
        onClose={() => setTillLink(false)}
      >
        <Row gutter={[16, 16]} style={{ fontSize: "1vw" }}>
          <Col span={24}>
            <Scrollbars style={{ height: "80vh" }}>
              <Row>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
                <Col
                  onClick={tillUnlink}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                    marginBottom: "2vh",
                  }}
                  span={22}
                >
                  <img src={unlinkTill} style={{ height: "2.5vh" }} alt="" />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 500,
                      fontSize: "1.2em",
                      paddingLeft: "1vw",
                      color: "#0F0718",
                    }}
                  >
                    Unlink Till
                  </span>
                </Col>
                <Col
                  span={1}
                  style={{
                    cursor: "pointer",
                    display: tillData.tillAccess.unlinkTill === "Y" ? "block" : "none",
                  }}
                />
                {enableProduction ? null : (
                  <>
                    <Col span={1} />
                    <Col
                      style={{ cursor: "pointer", marginBottom: "2vh" }}
                      span={22}
                      onClick={() => {
                        if (cart.items.length > 0 && cart.isReturn !== true) {
                          setCouponModalVisible(true);
                          setTillLink(false);
                          setCouponInput("");
                        } else {
                          message.error("Coupon cant be applied for return items");
                        }
                      }}
                    >
                      <img src={couponIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          paddingTop: "0.3vh",
                          color: "#0F0718",
                        }}
                      >
                        Coupon Code
                      </span>
                    </Col>
                    <Col span={1} />
                    <Col span={1} />
                    <Col
                      onClick={async () => {
                        if (cart.items.length > 0) {
                          const workflowRules = await db.POSWorkFlowRules.toArray();
                          const approvers = await db.approvers.toArray();
                          // Check if workflowRules exist
                          if (workflowRules && workflowRules.length > 0) {
                            // If workflowRules exist, iterate over them
                            workflowRules.forEach((res) => {
                              if (res.eventName === "Print Draft bill") {
                                setTillLink(false);
                                dispatchEvent(res.eventName, res.ruleName, res.ruleCondition, approvers, (eventData) => {
                                  if (eventData?.approvalGranted) {
                                    let updatedCart = { ...cart, draftFlag: true };
                                    printController(updatedCart);
                                  }
                                });
                              }
                            });
                          } else {
                            // If workflowRules do not exist, execute some other logic
                            let updatedCart = { ...cart, draftFlag: false };
                            printController(updatedCart);
                          }
                        }
                      }}
                      style={{
                        cursor: cart.items.length > 0 && reconstructedObject?.DraftPrint === "Y" ? "pointer" : "not-allowed",
                        marginBottom: "2vh",
                      }}
                      span={22}
                    >
                      <img src={printerIcon} style={{ height: "2.9vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Print
                      </span>
                    </Col>
                    <Col span={1} />
                    <Col
                      span={1}
                      style={{
                        display: tillData?.tillAccess?.layAway === "Y" && selectedSaleTypeData?.cwrSaletype?.enableLayaway === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      onClick={() => {
                        if (selectedSaleTypeData?.cwrSaletype?.enableLayaway === "Y") {
                          if (cart.items.length > 0 && cart.isReturn !== true) {
                            let flag =
                              (posConfig?.requireCustomerforLayaway || "N") === "Y" ? tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId !== cart.customer.cwrCustomerId : true;

                            if (flag && selectedSaleTypeData?.cwrSaletype?.enableLayaway === "Y") {
                              if (cart.paid === 0 && posConfig.advanceLayaway === "Y") {
                                upsertPOSLog(cart, "LAY");
                                // processOrder("layaway");
                                cart.layAway = "Y";
                                setCart(cart);
                                localStorage.setItem("cartObj", JSON.stringify(cart));
                                let payments =
                                  showPayments.length >= 5
                                    ? _.sortBy(showPayments, "sequenceNo").slice(
                                        0,
                                        tillData?.tillAccess?.csBunit?.b2cCustomer?.cwrCustomerId === cart?.customer?.cwrCustomerId && showPayments.length === 6 ? 4 : 4
                                      )
                                    : showPayments;
                                setStartIndex(payments.length);
                                setShowPayments(payments);
                                setShowPaymentMethods(true);
                                setTillLink(false);
                              } else if (cart.paid === 0 && posConfig.advanceLayaway === "N") {
                                upsertPOSLog(cart, "LAY");
                                processOrder("layaway");
                                setTillLink(false);
                              }
                            } else {
                              message.warning("Customer not selected. Please select the correct customer to proceed.");
                            }
                          } else {
                            message.error("Layaway isn't supported for returns. Please choose a different option.");
                          }
                        } else {
                          message.error("Layaway is not enabled for this sale type");
                        }
                      }}
                      style={{
                        cursor: cart.items.length > 0 && selectedSaleTypeData?.cwrSaletype?.enableLayaway === "Y" ? "pointer" : "not-allowed",
                        display: tillData?.tillAccess?.layAway === "Y" || selectedSaleTypeData?.cwrSaletype?.enableLayaway === "Y" ? "block" : "none",
                        marginBottom: "2vh",
                      }}
                      span={22}
                    >
                      <img src={layAwaySvg} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Advance
                      </span>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        display: tillData?.tillAccess?.layAway === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      span={1}
                      style={{
                        display: posConfig?.enableReceiptlessReturn === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      onClick={() => {
                        if (cart.isReturn !== true && cart.items.length > 0 && cart.payments.length === 0) {
                          Modal.confirm({
                            title: "You are about to process a return without a receipt. Would you like to proceed?",
                            icon: null,
                            cancelText: "No",
                            okText: "Yes",
                            autoFocusButton: null,
                            onOk: async () => {
                              setTillLink(false);
                              let updatedCart = { ...cart };

                              updatedCart.total = -updatedCart.total;
                              updatedCart.tax = -updatedCart.tax;
                              updatedCart.isReturn = true;
                              updatedCart.isBlindReceipt = true;
                              updatedCart.totalQty = -updatedCart.totalQty;

                              updatedCart.items = updatedCart.items.map((item) => {
                                let grossUnit = (Math.abs(item.sunitprice) - Math.abs(item.discount)) / Math.abs(item.weight);
                                let newItem = { ...item };

                                newItem.nettotal = -item.nettotal;
                                newItem.weight = -item.weight;
                                newItem.isReturn = true;
                                newItem.sunitprice = -item.sunitprice;
                                newItem.netList = -item.nettotal;
                                newItem.linetax = Math.abs(newItem.nettotal) > 0 ? -parseFloat(item.taxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0;
                                newItem.linenet =
                                  Math.abs(newItem.nettotal) > 0
                                    ? -parseFloat((item.nettotal - item.taxAmount).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
                                    : 0;
                                newItem.linegross = Math.abs(newItem.nettotal) > 0 ? parseFloat(item.nettotal.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0;
                                newItem.netStd = -item.netStd;
                                newItem.listPrice = Math.abs(newItem.nettotal) > 0 ? -item.listPrice : 0;
                                newItem.grossunit =
                                  Math.abs(newItem.nettotal) > 0
                                    ? item.isReturn
                                      ? -parseFloat(grossUnit.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
                                      : parseFloat(grossUnit.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
                                    : 0;
                                newItem.taxAmount = -item.taxAmount;
                                return newItem;
                              });

                              await setCart(updatedCart);
                              localStorage.setItem("cartObj", JSON.stringify(updatedCart));
                              if (posConfig.showReason === "Y" || posConfig.showReferenceNo === "Y" || posConfig.showRemarks === "Y") {
                                setReasonPopup(true);
                              } else {
                                await handleCartTotalClick();
                              }
                            },
                          });
                        }
                      }}
                      style={{
                        cursor: cart.items.length > 0 && !cart.isReturn ? "pointer" : "not-allowed",
                        display: posConfig?.enableReceiptlessReturn === "Y" ? "block" : "none",
                        marginBottom: "2vh",
                      }}
                      span={22}
                    >
                      <img src={Discard} style={{ height: "2.7vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Return this Receipt
                      </span>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        display: posConfig?.enableReceiptlessReturn === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      span={1}
                      style={{
                        display: posConfig?.enableFullSync === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      span={22}
                      onClick={async () => {
                        if (cart.items.length === 0) {
                          if (parkedList.length === 0) {
                            localStorage.removeItem("lastUpdatedTime");
                            setTillLink(false);
                            setSyncLoad(true);
                            let tillData = JSON.parse(localStorage.getItem("tillData"));
                            const defaultCustomer = tillData.tillAccess.csBunit.b2cCustomer;
                            setCart({ ...cart, customer: defaultCustomer });
                            await processSync(tillData, setGlobalStore, "Full Sync");
                            setSyncLoad(false);
                            setGlobalStore({ percent: "0%" });
                            setGlobalStore({ productCategoryPercentage: "0" });
                            setGlobalStore({ productPercentage: "0" });
                            setGlobalStore({ promotionPercentage: "0" });
                            // window.location.reload();
                          } else {
                            message.error("To perform a Full Sync, please clear all parked bills!");
                          }
                        } else {
                          message.error("To perform a Full Sync, please clear the cart items!");
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        display: posConfig?.enableFullSync === "Y" ? "block" : "none",
                        marginBottom: "2vh",
                      }}
                    >
                      <img src={SyncSide} style={{ height: "2vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Full Sync
                      </span>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        display: posConfig?.enableFullSync === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      span={1}
                      style={{
                        display: tillData?.tillAccess?.salesReport === "Y" ? "block" : "none",
                      }}
                    />
                    <Col
                      onClick={async () => {
                        let ordersData = await db.ordersData.toArray();
                        ordersData = ordersData.filter((data) => data.tillStatus === "open");
                        salesReportPrinter(ordersData);
                      }}
                      style={{
                        cursor: "pointer",
                        display: tillData?.tillAccess?.salesReport === "Y" ? "block" : "none",
                        marginBottom: "2vh",
                      }}
                      span={22}
                    >
                      <img src={salesReportIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Sales Report
                      </span>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        display: tillData?.tillAccess?.salesReport === "Y" ? "block" : "none",
                      }}
                    />
                    <Col span={1} />
                    <Col
                      onClick={() => {
                        openDrawer();
                      }}
                      style={{ cursor: "pointer", marginBottom: "2vh" }}
                      span={22}
                    >
                      <img src={openDrawerIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Open Drawer
                      </span>
                    </Col>
                    <Col span={1} />
                    <Col span={1} />
                    <Col
                      onClick={() => {
                        offerProductsList?.length > 0 && setDisplayOfferProductSelection(true);
                      }}
                      style={{ cursor: "pointer", marginBottom: "2vh" }}
                      span={22}
                    >
                      <img src={openDrawerIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Offers
                      </span>
                    </Col>
                    <Col span={1} />

                    <Col span={1} />
                    <Col
                      onClick={() => posConfig.dataSyncDashboard === "Y" && tillData.tillAccess.dataSyncMonitor === "Y" && history.push("/dashboard2")}
                      style={{ cursor: posConfig.dataSyncDashboard === "Y" && tillData.tillAccess.dataSyncMonitor === "Y" ? "pointer" : "not-allowed", marginBottom: "2vh" }}
                      span={22}
                    >
                      <img src={openDrawerIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Data Sync Monitor
                      </span>
                    </Col>
                    <Col span={1} />

                    <Col span={1} />
                    <Col
                      onClick={() => {
                        parseInt(cart.total) > 0 && setManualDiscountModalVisible(true);
                      }}
                      style={{ cursor: "pointer", marginBottom: "2vh" }}
                      span={22}
                    >
                      <img src={openDrawerIcon} style={{ height: "2.5vh" }} alt="" />
                      <span
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 500,
                          fontSize: "1.2em",
                          paddingLeft: "1vw",
                          color: "#0F0718",
                        }}
                      >
                        Discount
                      </span>
                    </Col>
                    <Col span={1} />

                    {posConfig.enableUAECustomer === "Y" && <Col span={1} />}
                    {posConfig.enableUAECustomer === "Y" && (
                      <Col
                        onClick={() => {
                          setDisplayUAECustomerSearch(true);
                        }}
                        style={{ cursor: "pointer", marginBottom: "2vh" }}
                        span={22}
                      >
                        <img src={userIcon} style={{ height: "2.5vh" }} alt="" />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "1.2em",
                            paddingLeft: "1vw",
                            color: "#0F0718",
                          }}
                        >
                          UAE Customer
                        </span>
                      </Col>
                    )}
                    {posConfig.enableUAECustomer === "Y" && <Col span={1} />}
                    {posConfig.enableProduction === "Y" && <Col span={1} />}
                    {posConfig.enableProduction === "Y" && (
                      <Col onClick={handleProductionClick} style={{ cursor: "pointer", marginBottom: "2vh" }} span={22}>
                        <img src={productionIcon} style={{ height: "2.5vh", marginBottom: "0.2rem" }} alt="" />
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "1.2em",
                            paddingLeft: "1vw",
                            color: "#0F0718",
                          }}
                        >
                          Production Order
                        </span>
                      </Col>
                    )}
                    {posConfig.enableQuotation === "Y" && (
                      <>
                        <Col onClick={handleQuotationClick} style={{ cursor: "pointer", marginBottom: "2vh" }} span={22}>
                          <img src={NoteEditIcon} style={{ height: "2.5vh" }} alt="" />
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "1.2em",
                              paddingLeft: "1vw",
                              color: "#0F0718",
                            }}
                          >
                            Quotation
                          </span>
                        </Col>
                        <Col
                          onClick={() => {
                            setIsQuotation(true), setTillLink(false);
                          }}
                          style={{ cursor: "pointer", marginBottom: "2vh" }}
                          span={22}
                        >
                          <img src={NoteAddIcon} style={{ height: "2.5vh" }} alt="" />
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "1.2em",
                              paddingLeft: "1vw",
                              color: "#0F0718",
                            }}
                          >
                            Open Quotation
                          </span>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Scrollbars>
          </Col>
          {/* ) : (
            ""
          )} */}
          {/* <Col span={24}>
            <Row>
              <Col span={1} />
              <Col style={{ cursor: "pointer" }} span={22}>
                <img src={couponIcon} style={{ height: "2.5vh" }} alt="" />
                <span style={{ fontFamily: "Inter", fontWeight: 500, fontSize: "1.2vw", paddingLeft: "1vw" }}>Coupon Code</span>
              </Col>
            </Row>
          </Col> */}
          <Col
            span={24}
            style={{
              width: "80%",
              borderTop: "1px solid #929098",
              position: "absolute",
              bottom: "2.5vh",
              padding: "1vh 1vw",
            }}
          >
            <Row>
              <Col span={1} />

              <Col
                style={{ cursor: "pointer", paddingTop: "1vh" }}
                onClick={async () => {
                  let parkedBills = JSON.parse(localStorage.getItem("parkedList"));
                  let posConfig = JSON.parse(localStorage.getItem("posConfig"));
                  const orders = await db.orders.where("isSynced").equals(0).toArray();
                  if (parkedBills?.length > 0) {
                    {
                      message.error(`${t("unlink_till_parked_bill_error")}`);
                    }
                  } else if (cart?.items?.length > 0) {
                    {
                      message.error(`${t("unlink_till_cart_error")}`);
                    }
                  }
                  // else if (orders?.length > 0) {
                  // message.error("To unlink the Till please sync pending Orders from Order History");}
                  else {
                    if (posConfigData?.posType === "PRD") {
                      const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
                      for (let i = 0; i < cookiesToDelete.length; i++) {
                        const cookie = cookiesToDelete[i];
                        const eqPos = cookie.indexOf("=");
                        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        const domain = "domain=" + domainURL;
                        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
                      }
                      localStorage.removeItem("tillSession");
                      window.location.assign("/");
                    } else {
                      removeCutomer();
                      openDrawer();
                      upsertPOSLog(cart, "LGO");
                      if (posConfig.shiftClose === "Y") {
                        if (posConfig.showDenominations === "Y") {
                          history.push("/close-till");
                        } else if (posConfig.showDenominations === "N") {
                          history.push("/close-till-all");
                        }
                      } else {
                        history.push("/");
                      }
                    }
                  }
                  handleKeyvalue("10");
                }}
                span={20}
              >
                <img src={Logout} alt="" />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "1.2em",
                    color: "#0F0718",
                  }}
                >
                  Logout
                </span>
              </Col>
              <Col span={24} style={{ position: "absolute", bottom: "5vh", right: "0" }}>
                <p style={{ color: "#0f071840", textAlign: "right", paddingRight: "1vw", fontSize: "0.6rem", fontWeight: 400 }}>v{version_number}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
      <Drawer
        placement="bottom"
        height="53vh"
        maskClosable={false}
        title={
          <div style={{ display: "flex", alignItems: "center", height: "2vh" }}>
            <p
              style={{
                fontSize: "2vw",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              Reason for Return
            </p>
            <img
              src={Close}
              onClick={() => {
                setReasonPopup(false);
                setIsInputFocused(false);
              }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                cursor: "pointer",
              }}
            />
          </div>
        }
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingTop: 0 }}
        visible={reasonPopup}
        onClose={() => {
          setReasonPopup(false);
          setIsInputFocused(false);
        }}
        footer={null}
      >
        <Form layout="vertical" form={reasonForm} onFinish={onFinish}>
          <Row gutter={16}>
            {posConfig.showReferenceNo === "Y" ? (
              <Col span={8} offset={4}>
                <Form.Item label="Reference No" name="referenceNo">
                  <Input />
                </Form.Item>
              </Col>
            ) : null}
            {posConfig.showReason === "Y" ? (
              <Col span={8}>
                <Form.Item label="Reason" name="reason">
                  <Select>
                    {returnReasons?.map((res) => (
                      <Option key={res.returnReasonId} value={res.returnReasonId}>
                        {res.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
            {posConfig.showRemarks === "Y" ? (
              <Col span={8} offset={4}>
                <Form.Item label="Remarks" name="remarks">
                  <TextArea />
                </Form.Item>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col span={24} style={{ display: "flex", float: "left", height: "100%" }}>
              <div style={{ flex: 1 }}></div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#0F0718",
                    color: "#fff",
                    marginTop: "auto",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

      <Drawer
        placement="bottom"
        height="72vh"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "2vh",
              fontSize: "1vw",
            }}
          >
            <p
              style={{
                fontSize: "1.5em",
                marginTop: "3vh",
                fontWeight: "500",
                color: "#0F0718",
                flex: 1,
                textAlign: "center",
              }}
            >
              Reject Quotation
            </p>
            <img
              src={Close}
              onClick={() => {
                setRejectQuotation(false);
                // setIsInputFocused(false);
              }}
              // style={{ marginLeft: "auto", paddingBottom: "4vh", cursor: "pointer" }}
              style={{
                marginLeft: "auto",
                paddingBottom: "4vh",
                paddingTop: "2vh",
                cursor: "pointer",
                width: "1.5vw",
              }}
            />
          </div>
        }
        closeIcon={null}
        className="filter"
        bodyStyle={{ paddingTop: 0 }}
        visible={rejectQuotation}
        closable={true}
        onClose={() => {
          setRejectQuotation(false);
          // setIsInputFocused(false);
        }}
        footer={null}
      >
        <Row style={{ padding: "0 5vw", fontSize: "1vw", paddingTop: "2vh" }}>
          <Col span={11}>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "1.25em",
                color: "#0F0718",
                fontWeight: 500,
              }}
            >
              Reject Notes
            </span>
            <TextArea
              id="notes-input"
              style={{ resize: "none", paddingTop: "2vh" }}
              maxLength={200}
              placeholder="Type Here"
              // onKeyDown={handleKeyDown}
              // onChange={(e) => onChangeInputKeyboard(e)}
              // value={notesValue}
              rows={11}
            />
          </Col>
          <Col span={12} offset={1} style={{ marginTop: "4vh" }}>
            <Keyboard
              theme={"hg-theme-default aprvl"}
              onChange={(input) => onChange(input)}
              keyboardRef={(r) => (keyboardRef.current = r)}
              // onChange={(val)=>{input === 1 ? setInputValue(val) && console.log("val1",val) : setNotes(val) && console.log("val2",val) }}
              onKeyPress={(button) => {
                // console.log(button,"============bitton")
                if (button === "{shift}" || button === "{lock}") handleShift();
                if (button === "{number}") {
                  setLayoutName("number");
                }
                if (button === "{numbers}") {
                  setLayoutName("numbers");
                }
                if (button === "{small}") {
                  setLayoutName("shift");
                }
                if (button === "{caps}") {
                  setLayoutName("default");
                }
                if (button === "{enter}") {
                  onAddNotes();
                }
              }}
              layoutName={layoutName}
              display={{
                "{abc}": "ABC",
                "{smileys}": "\uD83D\uDE03",
                "{back}": "Enter",
                "{numbers}": "123",
                "{number}": "123",
                "{bksp}": "backspace",
                "{backspace}": "⌫",
                "{shift}": "⇧",
                "{tab}": "tab",
                "{lock}": "lock",
                "{enter}": "Enter",
                "{space}": "Space",
                "{caps}": "ABC",
                "{small}": "abc",
              }}
              layout={{
                shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {backspace}", "{number} {space} {enter}"],
                numbers: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{caps} {space} {enter}"],
                number: ["1 2 3 4 5 6 7 8 9 0", "! @ # $ % ^ & * ( )", "- . , _ / ` {backspace}", "{small} {space} {enter}"],
                default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {backspace}", "{numbers} {space} {enter}"],
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2.3vh", fontSize: "1vw" }} gutter={[16]}>
          <Col span={12} />
          <Col span={5} style={{ textAlign: "right", marginLeft: "1.6vw" }}>
            <Button
              style={{ width: "100%", height: "7.5vh", fontSize: "1.5em", fontWeight: "500", color: "#000", borderRadius: "5px" }}
              onClick={() => setShowReturnPurchase(false)}
              disabled={loader}
            >
              Cancel
            </Button>
          </Col>
          <Col span={5} style={{ textAlign: "right" }}>
            <Button
              style={{ width: "100%", height: "7.5vh", fontSize: "1.5em", fontWeight: "500", color: "#fff", background: "#2F3856", borderRadius: "5px" }}
              ref={returnProcessRef}
              disabled={modalEditState || loader}
              className="focusDashboardCard"
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Drawer>
    </Spin>
  );
};

export default PointOfSaleMarket;
