import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { I18nextProvider } from "react-i18next";
import { Modal } from "antd";
import i18n from "./i18n";

const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
sessionStorage.setItem("tabId", tabId);

// Include the base URL to distinguish between different routes or apps on the same domain
const basePath = window.location.pathname; // Adjust if needed (e.g., use `window.location.origin` for subdomains)

// Unique identifier for each instance based on the base path
const instanceId = window.name || `${basePath}-${Date.now()}`;
window.name = instanceId;

const setActiveTab = () => {
  localStorage.setItem("activeTabId", JSON.stringify({ tabId, instanceId, basePath }));
};

const clearActiveTab = () => {
  const activeTabData = JSON.parse(localStorage.getItem("activeTabId") || "{}");
  if (activeTabData.tabId === tabId && activeTabData.instanceId === instanceId && activeTabData.basePath === basePath) {
    localStorage.removeItem("activeTabId");
  }
};

const isAnotherTabOpen = () => {
  const activeTabData = JSON.parse(localStorage.getItem("activeTabId") || "{}");
  return (
    activeTabData.tabId &&
    activeTabData.instanceId &&
    activeTabData.basePath === basePath && // Check if it’s the same basePath
    (activeTabData.tabId !== tabId || activeTabData.instanceId !== instanceId)
  );
};

const closeTab = () => {
  clearActiveTab();
  window.close();
};

const showWarningModal = () => {
  Modal.warning({
    title: "Application Already Open",
    content: "The POS system is already open in another tab. Please switch to that tab.",
    onOk: closeTab,
  });
};

// Initialization
if (!localStorage.getItem("activeTabId")) {
  // First tab: initialize as active
  setActiveTab();
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>,
    document.getElementById("root")
  );
} else if (isAnotherTabOpen()) {
  // Duplicate or conflicting tab detected for the same basePath
  showWarningModal();
} else {
  // No conflict: mark this tab as active
  setActiveTab();
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>,
    document.getElementById("root")
  );
}

// Listen for changes in `activeTabId` to detect other tabs becoming active
window.addEventListener("storage", (event) => {
  if (event.key === "activeTabId" && event.newValue) {
    const activeTabData = JSON.parse(event.newValue || "{}");
    if (
      activeTabData.basePath === basePath && // Only react to changes for the same basePath
      (activeTabData.tabId !== tabId || activeTabData.instanceId !== instanceId)
    ) {
      showWarningModal();
    }
  }
});

// Ensure active tab state is cleared on tab close or refresh
window.addEventListener("unload", clearActiveTab);
